import instance from "../api/axios.api";

const CatalogService = {

    async getReItems(options) {
        const { data } = await instance.post("/book/catalog/get-re-items", {...options});
        return data;
    },

    async getPopularHousesReItems(options) {
        const { data } = await instance.post("/book/catalog/get-popular-houses-re-items", {...options});
        return data;
    },

    async getHouseReItems(options) {
        const { data } = await instance.post("/book/catalog/get-house-re-items", {...options});
        return data;
    },

    async getPopularReItems(options) {
        const { data } = await instance.post("/book/catalog/get-popular-re-items", {...options});
        return data;
    },

    async getBookServiceReItems(options) {
        const { data } = await instance.post("/book/catalog/get-book-service-re-items", {...options});
        return data;
    },

    async getOne(options) {
        const { data } = await instance.post("/book/catalog/get-one", {...options});
        return data;
    },




};
export default CatalogService;

import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import React, {useRef, useState, useEffect} from "react";
import useStyles from "./styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import {BookServiceSignIcon} from "../../assets/svg-icons.js";


const openLink = (url) => {
    url = url.replace(/\s/gi,"");
    window.open((/^https?:\/\//.test(url)?url:"http://"+url), "_blank")
}

export default (props) => {

    const {
        link,
        onClose,
        headerText,
        text,
        setToast,
        reload,
    } = props;

    const [textCopied, setTextCopied] = useState(false);

    const classes = useStyles();

    const inputRef = useRef();


    useEffect(() => {
        setTextCopied(false);
    },[reload]);

    const copyLinkToClipboard = (text) => {

        const fallbackCopyTextToClipboard = () => {

            if(!inputRef.current) return;

            try {
                inputRef.current.focus();
                document.querySelector("#share-link-input").select();

                const copied = document.execCommand("copy");

                inputRef.current.blur();

                setTextCopied(true);

                setToast({open: true, severity: (copied?"success":"error"), closeTime: 5000, text: (copied?"Ссылка скопирована!":"Скопировать ссылку не удалось.")});

            } catch (e) {
                setToast({open: true, severity: "error", closeTime: 5000, text: "Ошибка при копировании: "+e.message});
            }
        };

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }

        navigator.clipboard.writeText(text).then(
            function() {
                setToast({open: true, severity: "success", closeTime: 5000, text: "Ссылка скопирована!"});
                setTextCopied(true);
            },
            function(e) {
                setToast({open: true, severity: "error", closeTime: 5000, text: "Ошибка при копировании: "+e.message});
            }
        );

    };

    return (
        <div className={classes.drawerShareWrapper}>
            <p className={classes.drawerHeader}>{headerText}</p>
            {text && <p>{text}</p> || null}

            <div dir="rtl">
                <TextField
                    className={classes.formShareInput}
                    label="Скопируйте ссылку"
                    value={link}
                    size="small"
                    ref={inputRef}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start">
                                <IconButton onClick={() => copyLinkToClipboard(link)}>{textCopied?<CheckIcon color="success"/>:<ContentCopyIcon/>}</IconButton>
                            </InputAdornment>,
                        endAdornment:
                            <InputAdornment position="end">
                                <BookServiceSignIcon className={classes.shareLogoIcon}/>
                            </InputAdornment>
                    }}
                />
            </div>


            <Button
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => onClose()}
            >
                Закрыть
            </Button>

        </div>
    )

}
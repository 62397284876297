import Skeleton from "@mui/material/Skeleton";
import React from "react";

export default () => {
    return (
        <>
            <div style={{display: "flex", marginTop: 63}}>
                <Skeleton variant="rectangular" style={{width: 320, height: 240, margin: "0 4px 0 15px"}} />
                <Skeleton variant="rectangular" style={{width: "calc(100% - 339px)", height: 240}} />
            </div>

            <div style={{padding: "10px 16px"}}>
                <Skeleton variant="text" style={{fontSize: '1.25rem'}}/>
                <Skeleton variant="text" sx={{ fontSize: '1.25rem', width: "50%" }} />
            </div>

            <div style={{display: "flex", padding: 16}}>
                <Skeleton variant="rectangular" style={{width: 60, height: 60, marginRight: 8}} />
                <div style={{width: "calc(100% - 68px)"}}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
                </div>
            </div>

            <div style={{padding: "10px 15px"}}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </div>
        </>
    )
}
import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    pageErrorWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
            fontSize: 100,
            color: "#dadada",
            marginBottom: 30,
        }
    },
    pageErrorContent: {
        padding: 15,
        textAlign: "center",
    },
    pageErrorTitle: {
        fontSize: 16*onePx+"rem",
        fontWeight: 600,
    },

}));
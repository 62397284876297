import React, {useState, useRef, useCallback, useEffect, useMemo, useContext} from "react";
import useStyles from "./styles";
import clsx from "clsx";
import TextField from "@mui/material/TextField/TextField";
import {FormControlLabel} from "@mui/material";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LoadingButton from "@mui/lab/LoadingButton";
import {Formik} from "formik";
import Grid from "@mui/material/Grid";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";



const defaultHouseData = {
    status: "",
    floorHouse: "",
    type: "",
    tags: [],
    houseClass: "",
    territory: "",
    distanceToTheSea: "",
    ceilingHeight: "",
    gas: false,
    electricity: true,
    heating: "",
    parkingOptions: [],
    paymentOptions: [],
    purchaseOptions: [],
    sewerage: "",
    waterSupplyOptions: [],
}



export default (props) => {

    let {
        activeHouse,
        onSaveHouse,
    } = props;

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const defaultTags = pubConfig.housesOptions.tags;
    const defaultPaymentOptions = pubConfig.housesOptions.paymentOptions;
    const defaultPurchaseOptions = pubConfig.housesOptions.purchaseOptions;
    const defaultParkingOptions = pubConfig.housesOptions.parkingOptions;
    const defaultWaterSupplyOptions = pubConfig.housesOptions.waterSupplyOptions;
    const defaultType = pubConfig.housesOptions.type;
    const defaultStatus = pubConfig.housesOptions.status;
    const defaultHouseClass = pubConfig.housesOptions.houseClass;
    const defaultTerritory = pubConfig.housesOptions.territory;
    const defaultHeating = pubConfig.housesOptions.heating;
    const defaultSewerage = pubConfig.housesOptions.sewerage;

    const classes = useStyles();

    const onChangeChipMultiselect = (fieldName, value, values, setFieldValue) => {
        setFieldValue(fieldName, (values[fieldName].indexOf(value) > -1?values[fieldName].filter(v => v != value):values[fieldName].concat(value)));
    };

    const onChangeChip = (fieldName, value, values, setFieldValue) => {
        setFieldValue(fieldName, (values[fieldName] == value)?[]:value);
    };

    return (
        <div className={classes.editHouseNameWrapper}>
            <Formik
                initialValues={{
                    type: activeHouse && activeHouse.options?.type || defaultHouseData.type || "",
                    status: activeHouse && activeHouse.options?.status || defaultHouseData.status || "",
                    tags: activeHouse && activeHouse.options?.tags || defaultHouseData.tags || [],
                    houseClass: activeHouse && activeHouse.options?.houseClass || defaultHouseData.houseClass || "",
                    parkingOptions: activeHouse && activeHouse.options?.parkingOptions || defaultHouseData.parkingOptions || [],
                    territory: activeHouse && activeHouse.options?.territory || defaultHouseData.territory || "",
                    distanceToTheSea: activeHouse && activeHouse.options?.distanceToTheSea || defaultHouseData.distanceToTheSea || "",
                    ceilingHeight: activeHouse && activeHouse.options?.ceilingHeight || defaultHouseData.ceilingHeight || "",
                    floorHouse: activeHouse && activeHouse.options?.floorHouse || defaultHouseData.floorHouse || "",
                    gas: activeHouse && activeHouse.options?.gas || defaultHouseData.gas || false,
                    electricity: activeHouse && activeHouse.options?.electricity || defaultHouseData.electricity || false,
                    heating: activeHouse && activeHouse.options?.heating || defaultHouseData.heating || "",
                    sewerage: activeHouse && activeHouse.options?.sewerage || defaultHouseData.sewerage || "",
                    waterSupplyOptions: activeHouse && activeHouse.options?.waterSupplyOptions || defaultHouseData.waterSupplyOptions || [],
                    paymentOptions: activeHouse && activeHouse.options?.paymentOptions || defaultHouseData.paymentOptions || [],
                    purchaseOptions: activeHouse && activeHouse.options?.purchaseOptions || defaultHouseData.purchaseOptions || [],

                }}
                validate={values => {
                    const errors = {};


                    return errors;
                }}
                onSubmit={(values, {setSubmitting, setFieldValue}) => onSaveHouse(activeHouse._id, values)}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <p className={clsx(classes.textH2, classes.mb20)}>Основные характеристики:</p>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Тип объекта</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultType.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("type", t.value, values, setFieldValue)} className={(values.type.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Статус недвижимости</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultStatus.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("status", t.value, values, setFieldValue)} className={(values.status.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Теги</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultTags.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChipMultiselect("tags", t.value, values, setFieldValue)} className={(values.tags.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Класс объекта</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultHouseClass.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("houseClass", t.value, values, setFieldValue)} className={(values.houseClass.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <p className={clsx(classes.textH2, classes.mb20)}>Дополнительные параметры:</p>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Паркинг</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultParkingOptions.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChipMultiselect("parkingOptions", t.value, values, setFieldValue)} className={(values.parkingOptions.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Территория</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultTerritory.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("territory", t.value, values, setFieldValue)} className={(values.territory.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <TextField
                                size="small"
                                label="Расстояние до моря"
                                name="distanceToTheSea"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.distanceToTheSea}
                                className={classes.formInput}
                            />

                            <TextField
                                size="small"
                                label="Высота потолков"
                                name="ceilingHeight"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ceilingHeight}
                                className={classes.formInput}
                            />

                            <TextField
                                size="small"
                                label="Этажность"
                                name="floorHouse"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.floorHouse}
                                className={classes.formInput}
                            />

                            <p className={clsx(classes.textH2, classes.mb5)}>Коммуникации:</p>

                            <FormControlLabel
                                className={classes.w100}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!values.gas}
                                        value={values.gas}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="gas"
                                    />
                                }
                                color="primary"
                                label={"Газ"}
                            />

                            <FormControlLabel
                                className={clsx(classes.mb5, classes.w100)}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!values.electricity}
                                        value={values.electricity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="electricity"
                                    />
                                }
                                color="primary"
                                label={"Электричество"}
                            />

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Отопление</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultHeating.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("heating", t.value, values, setFieldValue)} className={(values.heating.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Канализация</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultSewerage.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChip("sewerage", t.value, values, setFieldValue)} className={(values.sewerage.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Водоснабжение</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultWaterSupplyOptions.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChipMultiselect("waterSupplyOptions", t.value, values, setFieldValue)} className={(values.waterSupplyOptions.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <p className={clsx(classes.textH2, classes.mb20)}>Варианты оплаты и оформление:</p>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Варианты оплаты</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultPaymentOptions.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChipMultiselect("paymentOptions", t.value, values, setFieldValue)} className={(values.paymentOptions.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={clsx(classes.formChipGroup)}>
                                <p>Оформление</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    {defaultPurchaseOptions.map(t => (
                                        <Chip key={t.value} onClick={() => onChangeChipMultiselect("purchaseOptions", t.value, values, setFieldValue)} className={(values.purchaseOptions.indexOf(t.value) > -1?classes.formChipActive:null)} label={t.title} />
                                    ))}
                                </Stack>
                            </div>

                            <div className={classes.formSubmitWrapper}>
                                <div className={classes.drawerBtnsWrapper}>

                                    <LoadingButton
                                        loading={isSubmitting}
                                        type="button"
                                        variant="contained"
                                        disabled={isSubmitting || Object.keys(errors).length > 0}
                                        fullWidth
                                        onClick={handleSubmit}
                                    >
                                        Сохранить
                                    </LoadingButton>
                                </div>
                            </div>

                        </Grid>
                    </form>
                )}

            </Formik>
        </div>
    )

}
import React, { useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import UserService from "../services/user.service";
import {GlobalStateContext} from "./GlobalStateProvider";

//получает данные пользователя и сохраняет их в state UserContext
const AuthProvider = ({ children }) => {

    const { setUser, user, loggingIn, setLoggingIn } = useContext(UserContext);

    //глобальный state
    const {
        toast, setToast,
    } = useContext(GlobalStateContext);

    //проверяем только при загрузке
    useEffect(() => {

        if(user) return;

        UserService.getProfile().then((res, err) => {

            const error = (err || !(res && res.status && res.user)) && (res?.error || "Неизвестная ошибка получения профайла пользователя!");

            if(error) {

                setLoggingIn(false);

                //console.log(res.error);
                //setToast({open: true, severity: "error", text: res.error || "Неизвестная ошибка!"});
                return;
                //return toast.error(errorCatch(res.error));
            }

            //если ошибок авторизации нет - сохраняем пользователя
            if( !(user && user._id && user._id == res.user._id)){
                setUser(res.user);
            }

            setLoggingIn(false);
        });


    },[user, loggingIn]);

  return (
      <>{children}</>
  );
};

export default AuthProvider;

import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import useStyles from "./styles.js";
import {RightArrow2Icon} from "../../assets/svg-icons.js";
import {useNavigate} from "react-router";

const BreadCrumbs = (props) => {

    const {stack, style} = props;

    const classes = useStyles();

    const nav = useNavigate();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (
       <div className={classes.breadCrumbsWrapper} style={style}>

           <div className={classes.breadCrumbs__item}>
               <div className={classes.breadCrumbs__itemLabel} style={{maxWidth: "100%"}}>Главная</div>
           </div>

           {
               stack.map(item => (
                   <div key={item.name} className={classes.breadCrumbs__item}>
                       <RightArrow2Icon/>
                       <div className={classes.breadCrumbs__itemLabel}>{item.name}</div>
                   </div>
               ))
           }

       </div>
    );
};

export default BreadCrumbs;

import React, {useContext, useState} from "react";
import Rating from "@mui/material/Rating/Rating";
import {Area2Icon, HomeIcon} from "../../assets/svg-icons.js";
import InfiniteScrollH from "./InfiniteScrollH.jsx";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import RowTpl from "./RowReItemMicro.jsx";
import clsx from "clsx";
import Fade from "@mui/material/Fade/Fade";
import useStyles from "./styles";
import {priceToTriads} from "../../utils/functions";
import Button from "@mui/material/Button";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";



const HouseImageWithProgressWithMemo = React.memo(({src, classes}) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className={classes.catalogHouseItemImageWrapper}>
            <img src={src} onLoad={() => {
                setLoading(false)
            }} style={{display: (loading ? "none" : "block")}}></img>
            <Fade
                in={loading}
                style={{transitionDelay: loading ? '800ms' : '0ms'}}
                unmountOnExit
            >
                <CircularProgress/>
            </Fade>
        </div>
    );
},(b, a) => {
    return true;
});

export default (props) => {

    const {
        onViewRe,
        onViewHouse,
        house,
        fetchMoreDataH,
        filter,
        from,
        dateRequest,
        backUrl,
    } = props;

    const {
        _id,
        houseName,
        houseCover,
        countRe,
        minArea,
        maxArea,
        minPrice,
        maxPrice,
        re,
        views,
        rating,
    } = house;

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const classes = useStyles();

    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState(re || []);

    const cover = houseCover?.images?.m?.url || null;

    return (
        <div className={classes.catalogHouseItemWrapper}>

            <div className={clsx(classes.catalogHouseItemHeader, (!cover && classes.catalogHouseItemHeaderNoCover))} onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}>

                <div className={classes.catalogHouseItemName}>
                    <span>{houseName}</span>
                </div>

                {!!(rating && rating > 1) &&
                <div className={classes.collectionHouseItemRating}>
                    <Rating value={+rating} readOnly size="small" precision={0.5} max={1}/>
                    <span className={classes.collectionHouseItemRatingVal}>{rating}</span>
                </div>
                }

            </div>

            {!!cover &&
            <div className={classes.catalogHouseItemCover} onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}>
                <HouseImageWithProgressWithMemo classes={classes} src={pubConfig.image_url + "/" + houseCover.images.m?.url}/>
            </div>
            }

            <InfiniteScrollH
                next={() => fetchMoreDataH({hasMore, setHasMore, data, setData, houseId: _id, limit: 5})}
                hasMore={hasMore}
                //onScroll={() => {console.log("scroll")}}
                loader={<div className={classes.catalogMiniItemLoading}><CircularProgress/></div>}
                endMessage={<div className={classes.catalogMiniItemLoading}><span>Все объекты загружены</span></div>}
            >
                {data && data.map((item, index) => (
                    <RowTpl
                        backUrl={backUrl}
                        classes={classes}
                        key={item._id}
                        user={props.user}
                        onViewRe={onViewRe}
                        re={item}
                        from={from}
                        pubConfig={pubConfig}
                    />
                ))}
            </InfiniteScrollH>


            <div className={clsx(classes.catalogReItemshouseCardStatusWrapper)}>

                <div className={classes.mapMarkerHouseStatus}>
                    <HomeIcon sx={{ fontSize: 10, color: "#4CAF50" }} />
                    <span style={{fontWeight: 600}}>{countRe || 0}</span>
                </div>

                <div className={classes.mapMarkerHouseStatus}>
                    <Area2Icon sx={{ fontSize: 10, color: "#4CAF50" }}  />
                    <span style={{fontWeight: 600}}>{(minArea != maxArea)?minArea+" - "+maxArea:minArea}</span><span style={{color: "#657780"}}>м²</span>
                </div>

                <div className={classes.mapMarkerHouseStatus}>
                    {/*<AttachMoneyIcon sx={{ fontSize: 14, color: "#4CAF50" }} />*/}
                    <span style={{fontWeight: 600}}>{(minPrice != maxPrice)?priceToTriads(minPrice)+" - "+priceToTriads(maxPrice):priceToTriads(minPrice)}</span><span style={{color: "#657780"}}>₽</span>
                </div>

            </div>

            <div style={{padding: "0 15px 15px 15px"}}>
                <Button fullWidth className={classes.greenOutlinedBtn} variant="outlined" size="small" onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}>Открыть комплекс</Button>
            </div>

        </div>
    )

}
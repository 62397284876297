import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useState} from "react";
import useStyles from "./styles";
import CustomSnackbar from "../../components/CustomSnackbar/CustomSnackbar.jsx";
import HouseRowTpl from "./HouseRowTpl.jsx";
import moment from 'moment';
import CatalogService from "../../services/catalog.service.js";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

let pageIndex = 0;
const NUM_ROWS = 10;
let fetchMoreLoading = false;
let dateRequest;

export default (props) => {

    const {
        id,
        user,
        //callback на удаление объекта из подборки (нужен для обновления коллекции в списке)
        onViewRe,
        onViewHouse,
        reload,
        data,
        setData,
        filter,
        backUrl,
    } = props;

    console.log("re-render PopularHouses backUrl=", backUrl);

    const classes = useStyles();

    const [toast, setToast] = useState({open: false, severity: "error", "text": ""});
    const [hasMore, setHasMore] = useState(true);

    const stopfetchData = () => {
        setHasMore(false);
        fetchMoreLoading = false;
    };

    const fetchMoreData = async (reload, filter, options) => {

        let hasMoreTmp = hasMore;
        let skip;

        if(reload){
            setHasMore(true);
            hasMoreTmp = true;
            setData([]);
            pageIndex = 0;
            skip = 0;
            dateRequest = new Date();
        }else{
            skip = data.length;
        }

        //если необходимо загрузить другую data
        if(options?.data) setData(options.data);

        if (fetchMoreLoading || !hasMoreTmp) return;

        fetchMoreLoading = true;

        console.log("PopularHouses fetchMoreData dateRequest", moment(dateRequest).format("DD.MM.YY HH:mm:ss"));

        const result = await CatalogService.getPopularHousesReItems({skip, limit: (options && options.limit || NUM_ROWS), ...filter, dateRequest});

        if (!(result && result.status) ) {
            console.log("Ошибка загрузки данных");
            stopfetchData();
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка загрузки данных!"});
            return;
        }

        if( !(result.houses && result.houses.length) ){
            stopfetchData();
            return;
        }

        if(reload){
            setData(result.houses);
            if(!(options && options.scrollTop) ) {
                //если не надо загрузить pages и применить scrollTop, то обнуляем scrollTop (тк перезагрузка контента)
                //setFilterState({... filter, scrollTop: 0});
            }else{
                //если идет загрузка pages и надо применить scrollTop
                document.querySelector("#reListScrollableDiv").scrollTop = options.scrollTop
            }
        }else{
            //если необходимо использовать другую data
            setData( (options?.data || data).concat(result.houses) );
        }

        fetchMoreLoading = false;

    }

    const fetchMoreDataH = async ({hasMore, setHasMore, data, setData, reload, houseId, limit}) => {

        let hasMoreTmp = hasMore;
        let skip;

        if(reload){
            setHasMore(true);
            hasMoreTmp = true;
            setData([]);
            skip = 0;
        }else{
            skip = data.length;
        }

        //console.log("fetchMoreDataH Start! skip",skip);

        if (!hasMoreTmp) return false;

        //console.log("fetchMoreDataH houseId: "+houseId+" загружаю элементы skip="+skip);
        console.log("fetchMoreDataH dateRequest", moment(dateRequest).format("DD.MM.YY HH:mm:ss"));

        //добавляем глобальный фильтр
        //сортировка обязательно в конце, чтобы фильтр не затирал ее
        //dateRequest - на основании fetchMoreData (при загрузке страницы)
        //const result = await useMethod("methods.runPubAPIMethod", "catalog/get-house-re-items", user, {houseId, skip, limit: (limit || NUM_ROWS), ...filter, sort: "publishedAt", dateRequest});
        //TODO - требует проверки
        const result = await CatalogService.getHouseReItems({houseId, skip, limit: (limit || NUM_ROWS), ...filter, sort: "publishedAt", dateRequest});

        if (!(result && result.status) ) {
            //console.log("fetchMoreDataH Ошибка загрузки данных");
            setHasMore(false);
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка загрузки данных!"});
            return true;
        }

        if( !(result.re && result.re.length) ){
            setHasMore(false);
            return true;
        }

        if(reload){
            setData(result.re);
        }else{
            //если необходимо использовать другую data
            setData( data.concat(result.re) );
        }

        return true;
    }

    useEffect(() => {

        fetchMoreData(true, filter);

    },[reload, filter]);


    return (
        <>

            <div id="reListScrollableDiv" className={classes.scrollBoxCatalogReItems}>
                <InfiniteScroll
                    dataLength={data.length}
                    scrollThreshold="700px"
                    next={() => fetchMoreData(false, filter)}
                    hasMore={hasMore}
                    //onScroll={() => onScroll()}
                    loader={<Typography className={classes.infiniteScrollLoading} variant="body1">Загрузка...</Typography>}
                    scrollableTarget="reListScrollableDiv"
                    endMessage={<Typography className={classes.infiniteScrollLoading} variant="body1">Все данные загружены</Typography>}
                >

                    <BreadCrumbs stack={[{name: "Популярные комплексы"}]} style={{padding: "0 15px 0 15px", marginTop: -5, marginBottom: 20}}/>

                    {data.map((item, index) => (
                        <HouseRowTpl
                            key={item._id}
                            user={props.user}
                            onViewRe={onViewRe}
                            onViewHouse={onViewHouse}
                            house={item}
                            fetchMoreDataH={fetchMoreDataH}
                            from="catalog"
                            //из каталога isAgent всегда true
                            isAgent={true}
                            filter={filter}
                            dateRequest={dateRequest}
                            backUrl={backUrl}
                        />
                    ))}
                </InfiniteScroll>
            </div>

            <CustomSnackbar toast={toast} setToast={setToast} classes={classes}/>

        </>
    )

}
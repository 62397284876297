import React, {Component, Fragment, useState, useEffect, useRef, useCallback, useContext} from 'react';
import _ from "lodash";
import {getReHeader, replObjInArr, timeout, getTextDays, updateDataParamsBookingDays} from "../../utils/functions";
import moment from 'moment';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar/AppBar";
import useStyles from "./styles";
import clsx from "clsx";
import queue from "queue";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Drawer from "@mui/material/Drawer/Drawer";
import IosShareIcon from '@mui/icons-material/IosShare';
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {MapIcon, FilterIcon} from "../../assets/svg-icons.js";
import useGStyles from "../../assets/global-styles";
//компоненты
import DrawerFilter from "./DrawerFilter.jsx";
import CalendarBusyView from "../../components/Calendar/CalendarBusyView.jsx";
import ViewReCard from "./ViewReCard.jsx";
import ViewHouseCard from "../Catalog/HouseCard.jsx";
import CatalogList from "./CatalogList.jsx";
import DrawerShareLink from "../../components/DrawerShareLink/DrawerShareLink.jsx";
import SkeletonViewReCard from "../../components/CustomSkeletons/SkeletonViewReCard.jsx";
import HouseInfo from "./HouseInfo.jsx";
import HouseOptionsForm from "./HouseOptionsForm.jsx";
import PageErrorTpl from "../../components/PageErrorTpl/PageErrorTpl.jsx";
import PopularHouses from "./PopularHouses.jsx";
import Ymaps from "../../components/YandexMaps/YandexMap.jsx";
import YmapsViewPoint from "../../components/YandexMaps/YandexMapViewPoint.jsx";
//сервисы
import CatalogService from "../../services/catalog.service.js"
import UserService from "../../services/user.service";
import HouseNamesService from "../../services/house-names.service";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import { useParams, useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import {UserContext} from "../../providers/UserProvider";
import DrawerDistricts from "../Main/DrawerDistricts";
import Main from "../Main/Main.jsx"
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import DrawerDatesFilter from "./DrawerDatesFilter.jsx";
import SearchPanelMini from "./SearchPanelMini.jsx";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DrawerChangeGuestsCount from "./DrawerChangeGuestsCount.jsx";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";


let searchText = "";
const qSearch = new queue({
    results: [],
    autostart: true,
    concurrency: 1,
});

let mapDefaultLocationData = null;

const withoutFilter = {
    category: "rent",
    searchText: "",
    priceFrom: "",
    priceTo: "",
    areaFrom: "",
    areaTo: "",
    floorFrom: "",
    floorTo: "",
    furnish: [],
    paymentOptions: [],
    view: [],
    contract: [],
    rooms: [],
    bookServiceApartaments: false,
    dates: {startDate: null, endDate: null, guestsCount: 2, districts: []},
};


const CalendarWithMemo = React.memo((props) => {
    return (
        <CalendarBusyView {...props} />
    );
},(b, a) => {

    if(b.bookingData != a.bookingData ||
        b.bookingDays != a.bookingDays ||
        b.calendarStatus != a.calendarStatus ||
        b.selectedBookingData != a.selectedBookingData ||
        b.selectedBookingDays != a.selectedBookingDays ||
        b.dayPrices != a.dayPrices) return false;

    return true;
});


const checkFilterKeys = {
    arrays: ["furnish", "paymentOptions", "contract", "rooms", "view"],
    values: ["priceFrom", "priceTo", "areaFrom", "areaTo", "floorFrom", "floorTo", "bookServiceApartaments"],
}

const YMapWithMemo = React.memo((props) => {
    return (
        <Ymaps {...props}/>
    );
},(a, b) => {
    if(a.filter != b.filter) return false;
    return true;
});



const HouseSearchRowTpl = (props) => {
    const {
        house,
        classes,
        onViewHouse,
        setSearchBoxShow,
        setSearchTextState,
        setSearchData,
        user,
        filter,
    } = props;

    const onViewHouseClick = async (houseId) => {
        onViewHouse(houseId, filter, new Date());
        setSearchData([]);
        setSearchBoxShow(false);
        setSearchTextState("");

        //await useMethod("methods.runAPIMethod", "user/push-data", user, {param: "catalog-search-houses", data: {id: house.value,  ...house, date: new Date(), max: 10}, uniq: true});
        //TODO - требуется проверка
        await UserService.pushData({param: "catalog-search-houses", data: {id: house.value,  ...house, date: new Date(), max: 10}, uniq: true});

    }

    return (
        <div className={classes.houseSearchItemWrapper} onClick={() => onViewHouseClick(house.value)}>
            {house.title}
        </div>
    )
}

const checkFilter = (filterState) => {
    //если массив содержит элементы - добавляем к count +1
    let count = checkFilterKeys.arrays.reduce((accumulator, currentKey) => (!!filterState[currentKey].length?accumulator + 1:accumulator), 0);
    //если поле заполнено - добавляем к count +1
    count+= checkFilterKeys.values.reduce((accumulator, currentKey) => (!!filterState[currentKey]?accumulator + 1:accumulator), 0);

    //отдельно считаем фильтр districts
    count+= filterState.dates.districts.length;

    return count;
}

//счетчик + иконка фильтра в меню поиска
const FilterIconWithMemo = React.memo((props) => {
    const gClasses = useGStyles();
    const {filter} = props;
    const count = checkFilter(filter);
    return (
        <Badge className={gClasses.filterBadge} badgeContent={count}>
            <FilterIcon className={(!!count?gClasses.cActive:null)} />
        </Badge>
    )
},(b, a) => {
    if(a.filter != b.filter) return false;
    return true;
});

const Catalog = (props) => {

    //глобальный state
    const {
        pubConfig,
        backdrop, setBackdrop,
        toast, setToast,
        lightbox, setLightbox,
    } = useContext(GlobalStateContext);

    let {
        reNumberId,
        houseNumberId,
        indexCategory,
    } = useParams();

    //console.log("indexCategory="+indexCategory+", reNumberId="+reNumberId+", houseNumberId="+houseNumberId);

    //определяем вид отображения страницы
    let pageViewTmp;
    if(indexCategory && indexCategory == "map"){
        pageViewTmp = "map";
    }else if(indexCategory && indexCategory == "list"){
        pageViewTmp = "list";
    }else if(indexCategory && indexCategory == "popular-houses"){
        pageViewTmp = "popular-houses";
    }else if(reNumberId){
        pageViewTmp = "re_loading";
    }else if(houseNumberId){
        pageViewTmp = "house_loading";
    }else{
        pageViewTmp = "main";
    }

    //console.log("!!!!!!!!!!! pageViewTmp="+pageViewTmp);

    const {user, setUser} = useContext(UserContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    //получаем get-параметры
    //const urlPageView = searchParams.get('view');
    const guestsCount = searchParams.get('guestscount');
    let startDate = searchParams.get('checkin');
    let endDate = searchParams.get('checkout');

    let activeDistrictsCodes = searchParams.getAll('districts[]');
    //получаем все районы из конфига и добавляем checked в зависимости от того какие районы отмечены сейчас
    let districts = pubConfig.districts.sochi.map(d => ({...d, checked: (activeDistrictsCodes.indexOf(d.code) > -1) })).filter(d => d.checked);

    //console.log("districts=", districts);

    const dates = {
        startDate: startDate && moment(startDate, "DD-MM-YY").toDate() || null,
        endDate: endDate && moment(endDate, "DD-MM-YY").toDate() || null,
        guestsCount: guestsCount && +guestsCount || 2,
        districts,
    };

    const nav = useNavigate();

    const classes = useStyles();
    const gClasses = useGStyles();

    const searchRef = useRef();
    const mapRef = useRef();

    //const isAgent = !!_.intersection(["admin", "manager", "user"], (props && props.user && props.user.roles || []) ).length;
    const isAgent = false;

    const [pageView, setPageView] = React.useState(pageViewTmp);

    const [activeHouse, setActiveHouse] = React.useState(null);

    const [filterState, setFilterState] = React.useState({
        ...withoutFilter,
        dates,
    });

    const [calendarStatus, setCalendarStatus] = useState("view-booking");

    useEffect(() => {

        //console.log("Catalog useEffect change location pageViewTmp="+pageViewTmp);

        //закрываем все drawers
        setDrawer({...drawer, show: false});
        setDrawer2Level({...drawer2Level, show: false});
        setDrawerFS({...drawerFS, show: false});
        setDrawerFS2({...drawerFS2, show: false});
        setDrawerNotSw({...drawerNotSw, show: false});


        switch (pageViewTmp) {
            case "main":
                break;
            case "list":
                break;
            case "map":
                break;
            case "house_loading":

                HouseNamesService.getOne({numberId: +houseNumberId, filter: filterState}).then((res, err) => {
                    const error = (err || !(res && res.status && res.house) && (res?.error || "Неизвестная ошибка!"));

                    if(error){
                        setPageError(res.error || error);
                        return;
                    }

                    setActiveHouse(res.house);
                    setPageView("view_house");

                    //если нужно загрузить вид
                    if(res.userData["catalog-view"]){
                        setView(res.userData["catalog-view"]);
                    }

                    //загрузка завершена
                    //setPageLoading(false);

                });

                break;

            case "re_loading":

                CatalogService.getOne({numberId: +reNumberId}).then((res, err) => {
                    const error = (err || !(res && res.status && res.re) && (res?.error || "Неизвестная ошибка!"));

                    if(error){
                        setPageError(res.error || error);
                        return;
                    }

                    setActiveRe(res.re);

                    //обновление/пересчет значений pastBooking, futureBooking, color - тк в зависимости от даты эти значения могут изменяться
                    setBookingDays(updateDataParamsBookingDays(res.re.bookingDays || {}, [], pubConfig.bookingCalendar.colors));

                    //преобразуем массив dayPrices в объект
                    let dayPricesObj = {};
                    if(res.re.dayPrices?.length){
                        res.re.dayPrices.map(day => dayPricesObj[day.dateId] = {date: day.date, price: day.price, customDate: day.customDate});
                    }
                    //и сохраняем
                    setDayPrices(dayPricesObj);

                    setPageView("view_one_re");

                    //меняем категорию фильтра
                    setFilterState({...filterState});

                    //если нужно загрузить вид
                    if(res.userData && res.userData["catalog-view"]){
                        setView(res.userData["catalog-view"]);
                    }

                    //загрузка завершена
                    //setPageLoading(false);


                });

                break;
        }

    }, [location]);

    //console.log("re-render Catalog urlPageView="+urlPageView+", pageViewTmp="+pageViewTmp+", pageView="+pageView);

    //вид отображения в ЖК (никак не связан с pageView)
    const [view, setView] = useState("tile");

    //группировка в ЖК
    const [groupBy, setGroupBy] = useState("");

    //данные активного re
    const [activeRe, setActiveRe] = useState([]);
    const [bookingDays, setBookingDays] = useState({});
    const [dayPrices, setDayPrices] = useState({});

    //отмеченные для обработки клиенты
    const [checkedRe, setCheckedRe] = useState([]);

    const [checkReMode, setCheckReMode] = useState(false);

    //данные ЖК
    //const [dataReHouse, setDataReHouse] = useState([]);

    //данные списка коллекций для добавления в них комплекса
    const [dataCollectionsForHouse, setDataCollectionsForHouse] = useState([]);

    //данные списка объектов re-list
    const [data, setData] = useState([]);

    //для обновления открытой в drawer коллекций - для ЖК
    const [reloadDrawerHouseCollections, setReloadDrawerHouseCollections] = useState(0);

    //для обновления фильтра районов
    const [reloadDistrictFilter, setReloadDistrictFilter] = useState(0);

    //для обновления открытой в drawer формы редактирования ЖК
    const [reloadHouseOptionsForm, setReloadHouseOptionsForm] = useState(0);

    const [reloadShareLinkDrawer, setReloadShareLinkDrawer] = useState(1);

    //для обновления открытого ЖК
    const [reloadDrawerHouseCard, setReloadDrawerHouseCard] = useState(0);

    const [searchData, setSearchData] = useState([]);
    const [searchBoxShow, setSearchBoxShow] = useState(false);
    const [searchTextState, setSearchTextState] = useState("");

    //если загрузка страницы не удалась
    const [pageError, setPageError] = useState(null);

    //Drawer
    const [drawer, setDrawer] = useState({
        show: false,
        height: gClasses.drawerSt,
        type: null,
        zIndex: 1200,
    });

    //Drawer 2 уровня
    const [drawer2Level, setDrawer2Level] = useState({
        show: false,
        height: gClasses.drawerSt,
        type: null,
        zIndex: 1207,
    });

    //Drawer FS
    const [drawerFS, setDrawerFS] = useState({
        show: false,
        type: null,
        zIndex: 1200,
    });

    //Drawer FS 2 Level
    const [drawerFS2, setDrawerFS2] = useState({
        show: false,
        type: null,
        zIndex: 1201,
    });

    //Drawer FS3 (третий дровер для календаря)
    const [drawerFS3, setDrawerFS3] = useState({
        show: false,
        type: null,
        zIndex: 1210,
    });


    //Drawer NotSw
    const [drawerNotSw, setDrawerNotSw] = useState({
        show: false,
        height: gClasses.drawerSt,
        type: null,
        zIndex: 1205,
    });

    //обновляет url текущей страницы
    const generateUrl = (filter, pageView) => {

        //кол-во гостей
        let params = {
            //view: pageView,
            guestscount: filter.dates.guestsCount,
        };

        //даты
        if(filter.dates?.startDate && filter.dates?.endDate){
            params = {
                ...params,
                checkin: moment(filter.dates.startDate).format("DD-MM-YY"),
                checkout: moment(filter.dates.endDate).format("DD-MM-YY"),
            };
        }

        //районы
        const urlParams = new URLSearchParams(params);

        //добавляем в urlParams массив районов
        //кодируем в формат:
        //districts[]=1&districts[]=2&districts[]=3
        filter.dates.districts.map(d => d.code).forEach(item => {
            urlParams.append('districts[]', item);
        });

        const viewPath = pageView == "main"?"/":"/"+pageView+"/";

        const url = viewPath+"?"+urlParams.toString();

        //console.log("generateUrl url=", url);

        return url;

    }

    //обновляет url текущей страницы
    const updateUrl = (filter, pageView) => {
        const url =  generateUrl(filter, pageView);
        window.history.pushState({ path: url}, '', url);
    }

    /*
    const onChangeView = () => {
        switch (pageView) {
            //карта
            case "map":
                setPageView("list");
                updateUrl(filterState, "list");
                break;
            //список
            case "list":
                setPageView("map");
                updateUrl(filterState, "map");
                break;

        }
    }
     */

    const go = async (link, filter) => {
        if(filter) await UserService.setData({param: "re-filter", data: filter});
        nav(link);
    };

    const onViewHouse = (id, filter, dateRequest, backUrl) => {

        setCheckedRe([]);
        setCheckReMode(false);

        const tmpDrawer = {...drawerFS, type: "view_house_loading", show: true, zIndex: 1201};
        setDrawerFS(tmpDrawer);

        HouseNamesService.getOne({id: id, filter, dateRequest}).then((res, err) => {
            const error = (err || !(res && res.status && res.house) && (res?.error || "Неизвестная ошибка!"));

            if(error){
                setToast({open: true, severity: "error", text: res.error || "Неизвестная ошибка!"});
                return;
            }

            setActiveHouse(res.house);
            setDrawerFS({...tmpDrawer, type: "view_house", backUrl});

            window.history.pushState({ path: "/h/"+res.house.numberId}, '', "/h/"+res.house.numberId);

        });

    }


    const searchHandleKeyDown = async () => {

        //осущесивляеи поиск раз в 1 секунду
        await timeout(500);
        if(searchText == searchRef.current.value) {
            return;
        }

        searchText = searchRef.current.value;

        if(!searchText) {
            setSearchData([]);
            return;
        }

        const result = await HouseNamesService.find({searchText: searchText, limit: 20});

        const data = (!(searchText && result && result.status && result.houseNames ) ) ? [] : result.houseNames;

        setSearchData(data);

    }

    const onSearchClear = () => {
        setSearchTextState("");
        setSearchData([]);
        searchText = "";
    }

    const onStartSearch = async (e) => {

        if(e.target.nodeName != "INPUT") return;

        if(searchBoxShow) return;

        setSearchBoxShow(true);

        const result = await UserService.getArrayData({param: "catalog-search-houses", sort: {"data.date": -1}, limit: 10});

        if (!(result && result.status && result.data && result.data.status && result.data.data) ) {
            return;
        }

        setSearchData(result.data.data || []);

    }

    const onEditHouseInfo = () => {
        setReloadHouseOptionsForm(reloadHouseOptionsForm+1);
        setDrawer2Level({...drawer2Level, type: "edit_house", show: true, zIndex: 1207})
    }

    //callback после обновления состава коллекций (добавление/удаление активного объекта в/из коллекций)
    const afterUpdate = ({collections, added, removed, reItems, houseReItems}, options) => {

        console.log("start afterUpdate!");

        //activeRe - заменяем коллекции в активном объекте (для пересчета счетчика)
        if(options.activeRe && reItems.length && reItems[0] && activeRe && reItems[0]._id == activeRe._id){
            //обновление активного элемента
            console.log("afterUpdate: обновляю activeRe");
            setActiveRe({...activeRe, collections: reItems[0].collections});
        }

        setDrawer({...drawer, show: false});

    };

    const onViewRe = (reId, numberId, reUserId, backUrl) => {

        const tmpDrawer = {...drawerFS2, type: "view_re_loading", show: true, zIndex: 1201};
        setDrawerFS2(tmpDrawer);

        CatalogService.getOne({reId, numberId, filter: filterState}).then((res, err) => {
            const error = (err || !(res && res.status && res.re) && (res?.error || "Неизвестная ошибка!"));

            if(error){
                setToast({open: true, severity: "error", text: res.error || error});
                return;
            }

            setActiveRe(res.re);

            //обновление/пересчет значений pastBooking, futureBooking, color - тк в зависимости от даты эти значения могут изменяться
            setBookingDays(updateDataParamsBookingDays(res.re.bookingDays || {}, [], pubConfig.bookingCalendar.colors));

            //преобразуем массив dayPrices в объект
            let dayPricesObj = {};
            if(res.re.dayPrices?.length){
                res.re.dayPrices.map(day => dayPricesObj[day.dateId] = {date: day.date, price: day.price, customDate: day.customDate});
            }
            //и сохраняем
            setDayPrices(dayPricesObj);

            setDrawerFS2({...tmpDrawer, type: "view_re", backUrl});

            window.history.pushState({ path: "/re/"+numberId }, '', "/re/"+numberId);

        });

    }


    const onOpenDateFilterClick = () => {
        setDrawerNotSw({...drawerNotSw, type: "dates_filter", show: true, height: gClasses.drawerSt});
    }

    const onOpenDistrictFilterClick = () => {
        setReloadDistrictFilter(reloadDistrictFilter+1);
        setDrawerNotSw({...drawerNotSw, type: "district_filter", show: true, height: gClasses.drawerSt});
    }

    const onOpenDistrictFilterClick2Level = (districts, setFieldValue) => {
        setReloadDistrictFilter(reloadDistrictFilter+1);
        setDrawer2Level({...drawer2Level, type: "district_filter", show: true, zIndex: 1207, data: {districts, setFieldValue}})
    }

    const onOpenGuestsCountClick = () => {
        setDrawer({...drawer, type: "change_guests_count", show: true, height: gClasses.drawerH30});

    }

    const onViewHouseInfo = () => {
        setDrawer({...drawer, type: "view_house_info", height: gClasses.drawerSt, show: true, zIndex: 1202});
    }

    const addHouseToCollection = () => {
        setReloadDrawerHouseCollections(reloadDrawerHouseCollections+1);
        setDrawer({...drawer, type: "add_house_to_collection", height: gClasses.drawerSt, show: true, zIndex: 1202});
    }

    const onSaveHouse = (houseId, options) => {

        HouseNamesService.save({houseId, ...options}).then((res, err) => {
            const error = (err || !(res && res.status && res.house) && (res?.error || "Неизвестная ошибка!"));

            if(error){
                setToast({open: true, severity: "error", text: res.error || error});
                return;
            }

            setActiveHouse(res.house);
            setDrawer2Level({...drawer2Level, show: false});

        });

    }

    const onViewImageGallery = (uid) => {

        const imgIndex = activeRe.files?.filter(f => f.images).map(f => f.uid).indexOf(uid);

        const images = activeRe.files.filter(f => f.images).map(thumb => ({
            src: pubConfig.image_url+"/"+thumb.url,
            thumb: pubConfig.image_url+"/"+(thumb && thumb.images && thumb.images.thumb && thumb.images.thumb.url)
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex,
        });
    };

    const onResetFilter = () => {
        //обнуление фильтра
        let filter = {
            ...withoutFilter,
            scrollTop: 0,
            //сохраняем данные, которые не нужно сбрасывать:
            dates: {
                ...filterState.dates,
                districts: [],
            },
        };

        setFilterState(filter);

        updateUrl(filter, pageView);

        //если открыт ЖК и меняется фильтр
        if(drawerFS.show && drawerFS.type == "view_house"){
            //обновляем drawer ViewHouseCard
            setReloadDrawerHouseCard(reloadDrawerHouseCard+1);
        }

        setDrawer({...drawer, show: false});
    }

    const onSaveDatesFilter = (dates) => {

        console.log(dates);

        let filter = {
            ...filterState,
            dates: {
                //сохраняем кол-во гостей
                ...filterState.dates,
                ...dates
            }
        };
        setFilterState(filter);

        updateUrl(filter, pageView);

        //если открыт ЖК и меняется фильтр
        if(drawerFS.show && drawerFS.type == "view_house"){
            //обновляем drawer ViewHouseCard
            setReloadDrawerHouseCard(reloadDrawerHouseCard+1);
        }

        setDrawerNotSw({...drawerNotSw, show: false});
        setDrawer2Level({...drawer2Level, show: false});

    }

    const onResetDateFilter = () => {

        //обнуление фильтра дат
        let filter = {
            ...filterState,
            dates: {
                ...filterState.dates,
                startDate: null,
                endDate: null,
            },
        };

        setFilterState(filter);

        updateUrl(filter, pageView);

        //если открыт ЖК и меняется фильтр
        if(drawerFS.show && drawerFS.type == "view_house"){
            //обновляем drawer ViewHouseCard
            setReloadDrawerHouseCard(reloadDrawerHouseCard+1);
        }

        setDrawerNotSw({...drawerNotSw, show: false});
        setDrawer2Level({...drawer2Level, show: false});

    }

    const onResetDistrictsFilter = () => {

        //обнуление фильтра дат
        let filter = {
            ...filterState,
            dates: {
                ...filterState.dates,
                districts: []
            },
        };

        setFilterState(filter);

        updateUrl(filter, pageView);

        //если открыт ЖК и меняется фильтр
        if(drawerFS.show && drawerFS.type == "view_house"){
            //обновляем drawer ViewHouseCard
            setReloadDrawerHouseCard(reloadDrawerHouseCard+1);
        }

        setDrawerNotSw({...drawerNotSw, show: false, type: null});
        setDrawer2Level({...drawer2Level, show: false, type: null});

    }

    const onViewPopularHouses = () => {

        setPageView("popular-houses");
        updateUrl(filterState, "popular-houses");

    }

    const onFilter = (values) => {
        const districtsTmp = [...values.districts];
        delete values.districts;
        delete values.save;
        const filter = {... filterState, ...values, scrollTop: 0, dates: {...filterState.dates, districts: districtsTmp}};
        setFilterState(filter);

        updateUrl(filter, pageView);

        //если открыт ЖК и меняется фильтр
        if(drawerFS.show && drawerFS.type == "view_house"){
            //обновляем drawer ViewHouseCard
            setReloadDrawerHouseCard(reloadDrawerHouseCard+1);
        }

        setDrawer({...drawer, show: false});
    }

    const onSearchRe = () => {

        //на всякий случай снимаем отметку по объектам сервиса бронирую!
        const filter = {...filterState, bookServiceApartaments: false};
        setFilterState(filter);

        setPageView("map");
        updateUrl(filterState, "map");
    }

    const onViewBookServiceRe = () => {

        const filter = {...filterState, bookServiceApartaments: true};
        setFilterState(filter);

        setPageView("map");
        updateUrl(filterState, "map");
    }

    const onOpenMain = () => {
        setPageView("main");
        updateUrl(filterState, "main");
    }

    const onSaveGuestsCount = (guestsCount) => {
        const filter = {...filterState, dates: {...filterState.dates, guestsCount}};
        setFilterState(filter);
        updateUrl(filter, pageView);
        setDrawer({...drawer, show: false});
    };

    const onSaveDistricts = (districts) => {

        const filter = {...filterState, dates: {...filterState.dates, districts}};
        setFilterState(filter);

        updateUrl(filter, pageView);

        setDrawerNotSw({...drawerNotSw, show: false, type: null});
        setDrawer2Level({...drawer2Level, show: false, type: null});

    };

    const incGuestsCount = (inc) => {
        if (inc < 0 && filterState.dates.guestsCount > 1 || inc > 0) {
            const filter = {...filterState, dates: {...filterState.dates, guestsCount: filterState.dates.guestsCount + inc}};
            setFilterState(filter);
            updateUrl(filter, pageView);
        }
    };


    const DrawerEditCollectionHouseOptions = (props) => {

        const {onSave} = props;

        //если нет
        const [includedReOptions, setIncludedReOptions] = useState(props.includedReOptions);

        return (
            <div className={classes.drawerIncludedReOptions} style={{height: "100%"}}>

                <p className={classes.drawerIncludedReText}>Вы можете указать какие объекты будут отображаться в данном комплексе для текущей подборки:</p>

                <FormControl>
                    <RadioGroup
                        row
                        onChange={(e) => setIncludedReOptions(e.target.value)}
                        value={includedReOptions}
                    >
                        <FormControlLabel value="" control={<Radio />} label="Только добавленные мной объекты" />
                        <p className={classes.drawerIncludedReOptionsRadioDesc}>Будут отображаться только те объекты, которые вы добавите вручную.</p>
                        <FormControlLabel value="my" control={<Radio />} label="Только мои объекты" />
                        <p className={classes.drawerIncludedReOptionsRadioDesc}>Будут выводиться все ваши опубликованные объекты в данном комплекса</p>
                        <FormControlLabel value="all" control={<Radio />} label="Все объекты комплекса" />
                        <p className={classes.drawerIncludedReOptionsRadioDesc}>Будут выводиться все опубликованные объекты в данном комплексе (ваши и других агентов).</p>
                    </RadioGroup>
                </FormControl>

                <div className={gClasses.bottomDrawerBtnsWrapper}>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={() => onSave(includedReOptions)}
                    >
                        Готово
                    </Button>
                </div>

            </div>
        )

    }

    const renderDrawerNotSwSwitch = () => {
        switch (drawerNotSw.type) {

            case "loading":
                return <div className={gClasses.circularWrapper}><CircularProgress/></div>;

            case "view-house-in-map":

                if(!(activeRe && activeRe.houseName?.title && activeRe.houseName?.geometry)){
                    setToast({open: true, severity: "error", text: "Не указан адрес комплекса!"});
                }

                return (
                    <>
                        <div className={clsx(gClasses.bottomDrawerHeader)}>
                            <p>Локация объекта</p>
                            <IconButton size="small" onClick={() => {setDrawerNotSw({...drawerNotSw, show: false});}} className={classes.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                        <div className={gClasses.bottomDrawerBody}>
                            <YmapsViewPoint
                                classes={classes}
                                mapRef={mapRef}
                                house={{
                                    name: getReHeader(activeRe, true),
                                    geometry: activeRe.lettersGeometry || activeRe.houseName?.geometry || null,
                                    cover: activeRe.files && activeRe.files[0] || null,
                                }}
                                zoom={16}
                            />
                        </div>

                    </>

                );

                break;

            case "house-view-house-in-map":

                if(!(activeHouse && activeHouse?.geometry)){
                    setToast({open: true, severity: "error", text: "Не указан адрес комплекса!"});
                }

                return (
                    <>
                        <div className={clsx(gClasses.bottomDrawerHeader)}>
                            <p>Локация комплекса</p>
                            <IconButton size="small" onClick={() => {setDrawerNotSw({...drawerNotSw, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                        <div className={gClasses.bottomDrawerBody}>
                            <YmapsViewPoint
                                classes={classes}
                                mapRef={mapRef}
                                house={{
                                    housing: activeHouse.housing,
                                    name: activeHouse.name,
                                    geometry: activeHouse.geometry || null,
                                    cover: activeHouse.files && activeHouse.files[0] || activeHouse.houseCover || null,
                                }}
                                zoom={16}
                            />
                        </div>

                    </>

                );

            case "dates_filter": {

                return (
                    <DrawerDatesFilter
                        onSaveDatesFilter={onSaveDatesFilter}
                        onResetDateFilter={onResetDateFilter}
                        drawer={drawerNotSw}
                        setDrawer={setDrawerNotSw}
                        filter={filterState}
                    />
                )
            }

            case "district_filter": {

                return (
                    <DrawerDistricts
                        reload={reloadDistrictFilter}
                        defaultDistricts={filterState?.dates?.districts || []}
                        pubConfig={pubConfig}
                        onResetDistrictsFilter={onResetDistrictsFilter}
                        onCloseDistrictsFilter={() => {
                            setDrawerNotSw({...drawerNotSw, show: false, type: null});
                        }}
                        onSaveDistricts={onSaveDistricts}
                    />
                )

            }

            default:
        }
    }

    const renderDrawerSwitch = () => {
        switch (drawer.type) {

            case "loading":
                return <div className={gClasses.circularWrapper}><CircularProgress/></div>;

            case "share_re":
                return <DrawerShareLink
                    link={pubConfig.app_url+"/re/"+activeRe?.numberId}
                    onClose={() => {setDrawer({...drawer, show: false})}}
                    setToast={setToast}
                    headerText="Ссылка на объект каталога:"
                    text="Вы можете скопировать ссылку ниже:"
                    reload={reloadShareLinkDrawer}
                />;

            case "share_house":
                return <DrawerShareLink
                    link={pubConfig.app_url+"/h/"+activeHouse?.numberId}
                    onClose={() => {setDrawer({...drawer, show: false})}}
                    setToast={setToast}
                    headerText={"Ссылка на комплекс "+activeHouse.name}
                    text="Вы можете скопировать ссылку ниже:"
                    reload={reloadShareLinkDrawer}
                />;

            case "view_house_info":

                return (
                    <>

                        <div className={clsx(gClasses.bottomDrawerHeader)}>
                            <p>О комплексе</p>
                            <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>

                        </div>

                        <div className={gClasses.bottomDrawerBody}>

                            <HouseInfo
                                user={user}
                                activeHouse={activeHouse}
                                onEditHouseInfo={onEditHouseInfo}
                            />

                        </div>

                    </>
                );

            case "filter":
                const filterForbidden = {type: true, status: true, furnish: true, paymentOptions: true, sort: true, contract: true, viewRemoved: true, platform: true, houseName: true, publishedInCatalog: true}
                return (
                    <>
                        <div className={clsx(gClasses.bottomDrawerHeader)}>

                            <Button variant="text" className={gClasses.bottomDrawerRemoveBtn} onClick={onResetFilter}>Сбросить</Button>

                            <p>Фильтр</p>

                            <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                        <DrawerFilterWithCallback
                            forbidden={filterForbidden}
                            filterState={filterState}
                            onFilter={onFilter}
                            onOpenDistrictFilterClick={(districts, setFieldValue) => onOpenDistrictFilterClick2Level(districts, setFieldValue)}
                        />
                    </>
                );

            case "change_guests_count":

                return (
                    <DrawerChangeGuestsCount
                        filter={filterState}
                        drawer={drawer}
                        setDrawer={setDrawer}
                        onSaveGuestsCount={onSaveGuestsCount}
                    />
                )

                break;

            default:
        }
    }

    const HouseOptionsFormWithCallback = React.useCallback((props) => (<HouseOptionsForm {...props}/>),[activeHouse, reloadHouseOptionsForm]);

    const renderDrawer2LevelSwitch = () => {
        switch (drawer2Level.type) {
            case "loading":
                return <p style={{padding: 20}}>Загрузка...</p>;
                break;

            case "edit_house":
                return (
                    <>

                        <div className={clsx(gClasses.bottomDrawerHeader)}>
                            <p>Редактирование комплекса</p>
                            <IconButton size="small" onClick={() => {setDrawer2Level({...drawer2Level, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>

                        </div>

                        <div className={gClasses.bottomDrawerBody}>
                            <HouseOptionsFormWithCallback
                                activeHouse={activeHouse}
                                onSaveHouse={onSaveHouse}
                            />
                        </div>

                    </>
                )
                break;

            //корректировка опций includedReOptions при добавлении жк в подборки - изменения не должны отправляться на сервере, а должны сохранять локально
            case "on_edit_collection_house_options":

                const includedReOptions = drawer2Level.data?.id && dataCollectionsForHouse?.find(c => c._id == drawer2Level.data?.id)?.includedReOptions || "";

                return (
                    <>

                        <div className={clsx(gClasses.bottomDrawerHeader)}>
                            <p>Настройка отображения</p>
                            <IconButton size="small" onClick={() => {setDrawer2Level({...drawer2Level, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>

                        </div>

                        <div className={gClasses.bottomDrawerBody}>

                            <DrawerEditCollectionHouseOptions
                                user={user}
                                includedReOptions={includedReOptions || ""}
                                onSave={(includedReOptions) => {

                                    //index коллекции, где вносятся изменения в includedReOptions в списке комплексов подборки
                                    const collectionIndex = dataCollectionsForHouse.findIndex(c => c._id === drawer2Level.data.id);
                                    //заменяем опции в локальных данных  dataCollectionsForHouse, далее при сохранении подборок данные будут отправлены на сервер и сохранены. Также устанавливаем флаг, что объект добавлен в подборку (для последующего обновления на сервере)
                                    if(collectionIndex > -1) setDataCollectionsForHouse(replObjInArr(dataCollectionsForHouse, collectionIndex, {includedReOptions: includedReOptions, newCheckedStatus: "checked"}));

                                    setDrawer2Level({...drawer2Level, show: false, data: null})
                                    //dataCollectionsForHouse
                                    //setDataCollectionsForHouse
                                    //onSaveHouseCollectionOptions(drawer2Level.data.id, activeHouse._id, includedReOptions);
                                }}

                            />

                        </div>

                    </>
                );
                break;

            //фильтр районов - открытие 2 дровером из фильтра
            case "district_filter": {

                return (
                    <DrawerDistricts
                        reload={reloadDistrictFilter}
                        defaultDistricts={drawer2Level?.data?.districts || []}
                        pubConfig={pubConfig}
                        onResetDistrictsFilter={() => {
                            const setFieldValue = drawer2Level.data.setFieldValue;
                            setFieldValue("districts", []);
                            setDrawer2Level({...drawer2Level, show: false, type: null});
                        }}
                        onCloseDistrictsFilter={() => {
                            setDrawer2Level({...drawer2Level, show: false, type: null});
                        }}
                        onSaveDistricts={(districts) => {
                            //сохраняем районы только для DrawerFilter
                            const setFieldValue = drawer2Level?.data?.setFieldValue;
                            setFieldValue("districts", districts);
                            setDrawer2Level({...drawer2Level, show: false, type: null});
                        }}
                    />
                )

            }

        }
    }

    const renderFSDrawerSwitch = () => {
        switch (drawerFS.type) {

            case "loading":
                return <div className={gClasses.circularWrapper}><CircularProgress/></div>;

            case "view_house_loading":
                return <SkeletonViewReCard/>;

            case "view_house":
                return (
                    <>
                        <div className={gClasses.drawerCatalogReCardHeader}>

                            <IconButton color="inherit" aria-label="menu" onClick={() => {
                                setDrawerFS({...drawerFS, show: false});
                                window.history.pushState({ path: drawerFS.backUrl || "/"}, '', drawerFS.backUrl || "/" );

                            }}>
                                <ArrowBackIcon />
                            </IconButton>

                            <div className={gClasses.drawerActionButtons}>

                                <IconButton size="small" onClick={() => {
                                    setReloadShareLinkDrawer(reloadShareLinkDrawer+1);
                                    setDrawer({...drawer, type: "share_house", height: null, show: true, zIndex: 1205});
                                }}>
                                    <IosShareIcon fontSize="small"/>
                                </IconButton>

                            </div>

                        </div>

                        <div className={gClasses.drawerFSContentWrapper}>
                            <ViewHouseCard
                                user={user}
                                activeHouse={activeHouse}
                                onViewRe={onViewRe}
                                addHouseToCollection={addHouseToCollection}
                                //addToCollection={addToCollection}
                                checkedRe={checkedRe}
                                setCheckedRe={setCheckedRe}
                                checkReMode={checkReMode}
                                setCheckReMode={setCheckReMode}
                                onViewHouseInfo={onViewHouseInfo}
                                reload={reloadDrawerHouseCard}
                                isAgent={isAgent}
                                //
                                //data={dataReHouse}
                                //setData={setDataReHouse}
                                filterState={filterState}
                                viewHouseInMap={() => setDrawerNotSw({...drawerNotSw, type: "house-view-house-in-map", height: gClasses.drawerH75, show: true, zIndex: 1205})}
                                //параметры группировки
                                groupBy={groupBy}
                                setGroupBy={setGroupBy}
                                //вид отображения (для аренды - всегда вид плитка)
                                //view={filterState.category != "rent"?view:"tile"}
                                view={view}
                                setView={setView}
                                showBreadCrumbs={false}
                            />
                        </div>


                    </>
                );

        }
    }

    const renderFS2DrawerSwitch = () => {
        switch (drawerFS2.type) {

            case "loading":
                return <div className={gClasses.circularWrapper}><CircularProgress/></div>;

            case "view_re_loading":
                return <SkeletonViewReCard/>;

            case "view_re":
                return (
                    <>
                        <div className={gClasses.drawerCatalogReCardHeader}>

                            <IconButton onClick={() => {
                                window.history.pushState({ path: drawerFS2.backUrl || "/"}, '', drawerFS2.backUrl || "/" );
                                setDrawerFS2({...drawerFS2, show: false, backUrl: null});
                            }}>
                                <ArrowBackIcon />
                            </IconButton>

                            <div className={gClasses.drawerActionButtons}>
                                <IconButton size="small" onClick={() => {
                                    setReloadShareLinkDrawer(reloadShareLinkDrawer+1);
                                    setDrawer({...drawer, type: "share_re", height: null, show: true, zIndex: 1205});
                                }}>
                                    <IosShareIcon fontSize="small"/>
                                </IconButton>

                            </div>
                        </div>

                        <div className={gClasses.drawerFSContentWrapper}>
                            <ViewReCard
                                user={user}
                                activeRe={activeRe}
                                //showEditReBtn={false}
                                viewHouseInMap={() => setDrawerNotSw({...drawerNotSw, type: "view-house-in-map", height: gClasses.drawerH75, show: true, zIndex: 1205})}
                                onViewImageGallery={onViewImageGallery}
                                //onOpenCalculator={}
                                filterState={filterState}
                                showBreadCrumbs={false}
                            />
                        </div>

                        <div className={classes.reCardBottomPanel}>
                            <Button startIcon={<CalendarMonthIcon/>} variant="contained" onClick={() => setDrawerFS3({...drawerFS3, show: true, zIndex: 1210, type: "view_booking_calendar"})}>Свободные даты</Button>
                        </div>


                    </>
                );

            default:
                return null;
        }
    }

    const renderFS3DrawerSwitch = () => {

        switch (drawerFS3.type) {

            case "loading":
                return <div className={gClasses.circularWrapper}><CircularProgress/></div>;

            case "view_booking_calendar":
                return (
                    <>
                        <div className={gClasses.drawerCatalogReCardHeader}>

                            <IconButton onClick={() => {
                                setDrawerFS3({...drawerFS3, show: false});
                            }}>
                                <ArrowBackIcon />
                            </IconButton>

                            <div></div>

                        </div>

                        <div className={gClasses.bottomDrawerBody}>

                            <CalendarWithMemo
                                bookingData={[]}
                                bookingDays={bookingDays}
                                dayPrices={dayPrices}
                                beforeMonths={0}
                                afterMonths={12}
                                //нужен для обновления компонента при смене calendarStatus
                                calendarStatus={calendarStatus}
                                focusActiveMonth={true}
                                clearSelectedBookingDays={() => {}}
                                selectedBookingDays={{}}
                                onCellClick={() => {}}
                            />

                        </div>

                    </>
                )

        }
    }

    const DrawerFilterWithCallback = React.useCallback((props) => (<DrawerFilter {...props}/>),[filterState]);

    const onOpenFilterClick = () => {
        setDrawer({...drawer, type: "filter", show: true, height: gClasses.drawerSt});
    };

    const renderCatalogContent = React.useCallback((props) =>  {

        //console.log("!!!!!!!!!!!!!! re-render RenderContentWithMemo pageView="+pageView);

        switch (pageView) {

            case "house_loading":

            case "re_loading":

            case "loading":
                return (
                    <div className={gClasses.circularWrapper}>
                        <CircularProgress/>
                    </div>
                );

            case "main":

                return (
                    <Main
                        filterState={filterState}
                        onOpenDateFilterClick={onOpenDateFilterClick}
                        onOpenDistrictFilterClick={onOpenDistrictFilterClick}
                        onViewHouse={onViewHouse}
                        onViewRe={onViewRe}
                        onSearchRe={onSearchRe}
                        onViewBookServiceRe={onViewBookServiceRe}
                        onViewPopularHouses={onViewPopularHouses}
                        incGuestsCount={incGuestsCount}
                        backUrl={generateUrl(filterState, pageView)}

                    />
                );

                break;

            case "view_one_re":
                return (
                    <div className={gClasses.contentWrapper}>

                        <ViewReCard
                            user={user}
                            activeRe={activeRe}
                            //showEditReBtn={false}
                            viewHouseInMap={() => setDrawerNotSw({...drawerNotSw, type: "view-house-in-map", height: gClasses.drawerH75, show: true, zIndex: 1205})}
                            onViewImageGallery={onViewImageGallery}
                            filterState={filterState}
                            showBreadCrumbs={true}
                        />

                        <div className={classes.reCardBottomPanel}>
                            <Button startIcon={<CalendarMonthIcon/>} variant="contained" onClick={() => setDrawerFS3({...drawerFS3, show: true, zIndex: 1210, type: "view_booking_calendar"})}>Свободные даты</Button>
                        </div>

                    </div>
                );

            case "view_house":
                return (
                    <div className={gClasses.contentWrapper}>

                          <ViewHouseCard
                              user={user}
                              activeHouse={activeHouse}
                              //onEditHousePhoto={onEditHousePhoto}
                              onViewRe={onViewRe}
                              addHouseToCollection={addHouseToCollection}
                              //addToCollection={addToCollection}
                              checkedRe={checkedRe}
                              setCheckedRe={setCheckedRe}
                              checkReMode={checkReMode}
                              setCheckReMode={setCheckReMode}
                              onViewHouseInfo={onViewHouseInfo}
                              //
                              //data={dataReHouse}
                              //setData={setDataReHouse}
                              filterState={filterState}
                              viewHouseInMap={() => setDrawerNotSw({...drawerNotSw, type: "house-view-house-in-map", height: gClasses.drawerH75, show: true, zIndex: 1205})}
                              //параметры группировки
                              groupBy={groupBy}
                              setGroupBy={setGroupBy}
                              //вид отображения (для аренды - всегда вид плитка)
                              //view={filterState.category != "rent"?view:"tile"}
                              view={view}
                              setView={setView}
                              showBreadCrumbs={true}
                          />

                    </div>
                );

            case "map":

                return (
                    <div className={gClasses.contentWrapper}>

                        <SearchPanelMini
                            filter={filterState}
                            onOpenDateFilterClick={onOpenDateFilterClick}
                            //onOpenDistrictFilterClick={onOpenDistrictFilterClick}
                            onOpenGuestsCountClick={onOpenGuestsCountClick}
                            onOpenFilterClick={onOpenFilterClick}
                            FilterIconWithMemo={FilterIconWithMemo}
                        />

                        <YMapWithMemo
                            styleProps={{ width: "100%", height: "100%" }}
                            user={user}
                            onViewHouse={onViewHouse}
                            filter={filterState}
                            defaultLocationData={mapDefaultLocationData}
                            onMapUpdate={(data) => mapDefaultLocationData = {...data}}
                            //backUrl={location.pathname + location.search}
                            backUrl={generateUrl(filterState, pageView)}
                        />
                    </div>
                );

            case "popular-houses":
                return (
                    <div className={gClasses.contentWrapper}>

                        <PopularHouses
                            user={user}
                            data={data}
                            setData={setData}
                            onViewRe={onViewRe}
                            onViewHouse={onViewHouse}
                            afterUpdate={afterUpdate}
                            filter={filterState}
                            backUrl={generateUrl(filterState, pageView)}
                        />
                    </div>
                );

            default:
        }
    }, [pageView, filterState, data]);


    //если при загрузке страницы произошла ошибка
    if(pageError){
        return (
            <PageErrorTpl error="Ошибка страницы" description={pageError}/>
        )
    }

    return (
        <>

            <AppBar position="static" className={clsx(gClasses.appBar)}>

                <Toolbar className={gClasses.mainToolbarWrapper}>

                    <div className={gClasses.mainToolbar}>

                        <IconButton
                            style={{display: "none"}}
                            className={gClasses.toolbarSearchLogo}
                            size="large"
                            onClick={() => {}}
                        >
                            <SearchIcon/>
                        </IconButton>


                        <div className={gClasses.toolbarLogo}>
                            <img src="/svg/logo.svg" onClick={onOpenMain}/>
                        </div>

                        <IconButton
                            className={clsx(gClasses.toolbarAccountLogo, (user?gClasses.toolbarAccountLogoActive:null))}
                            size="large"
                            onClick={() => {nav("/profile")}}
                        >
                            {user?<AccountCircleIcon />:<ExitToAppIcon />}
                        </IconButton>


                    </div>

                </Toolbar>

            </AppBar>


            {renderCatalogContent()}

            <Drawer
                anchor="right"
                open={drawerFS.show}
                onClose={() => {setDrawerFS({...drawerFS, show: false});}}
                className={gClasses.fullPageDrawer}
            >
                <div className={gClasses.drawerFrame}>
                    {renderFSDrawerSwitch()}
                </div>
            </Drawer>

            <Drawer
                anchor="bottom"
                open={drawerNotSw.show}
                onClose={() => {setDrawerNotSw({...drawerNotSw, show: false});}}
                style={{zIndex: drawerNotSw.zIndex}}
                className={clsx(gClasses.drawerNotSw, drawerNotSw.height)}
            >
                <div className={gClasses.drawerWrapper}>
                    <div className={clsx(gClasses.drawerFrame)}>
                        {renderDrawerNotSwSwitch()}
                    </div>
                </div>

            </Drawer>

            <Drawer
                anchor="right"
                open={drawerFS2.show}
                onClose={() => {setDrawerFS2({...drawerFS2, show: false});}}
                style={{zIndex: drawerFS2.zIndex}}
                className={gClasses.fullPageDrawer}
            >
                <div className={gClasses.drawerFrame}>
                    {renderFS2DrawerSwitch()}
                </div>
            </Drawer>

            {!!searchBoxShow &&
            <div className={classes.catalogSearchBox}>
                {!!(searchData && searchData.length) &&
                searchData.map(house => (
                        <HouseSearchRowTpl
                            setSearchBoxShow={setSearchBoxShow}
                            setSearchTextState={setSearchTextState}
                            key={house.value}
                            house={house}
                            classes={classes}
                            onViewHouse={onViewHouse}
                            setSearchData={setSearchData}
                            user={user}
                            filter={filterState}
                        />
                    )
                )
                }
            </div>
            }

            <SwipeableDrawer
                anchor="bottom"
                open={drawer.show}
                onClose={() => {setDrawer({...drawer, show: false});}}
                onOpen={() => {}}
                style={{zIndex: drawer.zIndex}}
                className={clsx(gClasses.drawer, drawer.height, (drawer2Level.show?gClasses.drawerUnderActive2Level:null))}
            >
                <div className={gClasses.drawerWrapper}>
                    <div className={gClasses.drawerFrame}>
                        {renderDrawerSwitch()}
                    </div>
                </div>

            </SwipeableDrawer>

            <SwipeableDrawer
                anchor="bottom"
                open={drawer2Level.show}
                onClose={() => (setDrawer2Level({...drawer2Level, show: false}))}
                style={{zIndex: drawer2Level.zIndex}}
                onOpen={() => {}}
                className={clsx(gClasses.drawer, gClasses.drawerSt2Level)}
            >
                <div className={gClasses.drawerWrapper}>
                    <div className={gClasses.drawerFrame}>
                        {renderDrawer2LevelSwitch()}
                    </div>
                </div>

            </SwipeableDrawer>

            <Drawer
                anchor="right"
                open={drawerFS3.show}
                onClose={() => setDrawerFS3({...drawerFS3, show: false})}
                className={gClasses.fullPageDrawer}
                style={{zIndex: drawerFS3.zIndex}}
            >
                <div className={gClasses.drawerFrame}>
                    {renderFS3DrawerSwitch()}
                </div>
            </Drawer>
        </>
    )

}

export default Catalog;
import React, {useContext, useState, useEffect, useRef} from "react";
import Box from '@mui/material/Box';
import useStyles from "./styles.js";
import clsx from "clsx";
import moment from 'moment';
import _ from "lodash";
import {randomStr} from "../../utils/functions.js";

let scrollTop = 0;

export const CalendarBuffer = (props) => {

    const classes = useStyles();

    const dataCount = [];

    for (let i = 0; i < props.buffer; i++) {
        dataCount.push(i);
    }

    return (
        dataCount.map(index => (
            <div className={classes.calendarBufferWrapperNotBorder} key={index}></div>
        ))
    )

}

export const CalendarItem = (props) => {

    const classes = useStyles();

    const {day, activeDay, disabledDay, bookingDay, isLastMonthDay, onCellClick, cellId, isStartDay, isEndDay, selectedOnlyStartDate} = props;

    const selectedFirst = bookingDay.selectedFirst;
    const selectedLast = bookingDay.selectedLast;
    const selected = bookingDay.selected || false;

    let styleTextDay = {};
    if(isStartDay || isEndDay){
        styleTextDay =  {color: "#fff"};
    }

    return (

        <div
            className={
                clsx(
                    "calendarItem",
                    classes.calendarItem__not_border,
                    (selected?classes.calendarItem__range_selected:null),
                    (isStartDay?classes.calendarItem__range_start_day:null),
                    (isEndDay?classes.calendarItem__range_end_day:null),
                    classes.calendarItem,
                    (disabledDay?classes.calendarItem__disabled:null),
                    (isLastMonthDay?classes.calendarItem__lastMonthDay:null),
                )}
            onClick={(e) => onCellClick(e, cellId, bookingDay)}

        >

            <span className="calendarItemTextDay" style={styleTextDay}>
                {day}
            </span>


            {
                selected?
                    <span
                        className={
                            clsx(
                                "calendarItemBookingSelectedLayer",
                                (selectedFirst?"calendarItemBookingSelectedLayer__selectedFirst":null),
                                (selectedLast?"calendarItemBookingSelectedLayer__selectedLast":null),
                                (selectedOnlyStartDate?"calendarItemBookingSelectedLayer__not_bg":null)
                            )
                        }
                    />
                    :
                    null
            }

            {
                activeDay?
                    <span className="calendarItemLayerActiveDay"/>
                    :
                    null
            }

        </div>

    )

}

export const MonthCalendar = (props) => {

    //console.log("re-render MonthCalendar");

    const classes = useStyles();

    const {
        month,
        days,
        year,
        buffer,
        bufferAfter,
        activeMonth,
        onCellClick,
        selectedDays,
        selectedData,
        top,
        monthId,
        calendarHeight,
        noPastSates,
    } = props;


    const daysArr = [];

    for (let i = 0; i < days; i++) {
        daysArr.push(i+1);
    }

    let monthName;
    switch (+month) {
        case 1:
            monthName = "январь";
            break;
        case 2:
            monthName = "февраль";
            break;
        case 3:
            monthName = "март";
            break;
        case 4:
            monthName = "апрель";
            break;
        case 5:
            monthName = "май";
            break;
        case 6:
            monthName = "июнь";
            break;
        case 7:
            monthName = "июль";
            break;
        case 8:
            monthName = "август";
            break;
        case 9:
            monthName = "сентябрь";
            break;
        case 10:
            monthName = "октябрь";
            break;
        case 11:
            monthName = "ноябрь";
            break;
        case 12:
            monthName = "декабрь";
            break;
    }

    const activeDay = activeMonth && +moment().format("DD");
    const startOfDay = moment().startOf("day");

    return (

        <Box id={"monthId_"+monthId} className={classes.calendarMonthWrapper} style={{top: top}}>
            {
                activeMonth?
                    <div id="activeMonth" className={classes.calendarActiveMonth} />
                    :
                    null
            }

            <Box className={classes.calendarMonth}>
                <span>{monthName+" "+props.year+" г."}</span>
            </Box>

            <Box className={clsx(classes.calendarHeader, classes.calendarLiteHeader)}>
                {
                    ["П", "В", "С", "Ч", "П", "С", "В"].map(day=>{
                        return (
                            <div key={randomStr(8)}>{day}</div>
                        )
                    })
                }
            </Box>

            <Box className={classes.calendarDaysWrapper} style={{height: calendarHeight}}>

                <CalendarBuffer buffer={buffer}/>

                {
                    daysArr.map(day => {

                        const isActiveDay = activeDay && activeDay == day;
                        const cellId = day+"-"+month+"-"+year;
                        const bookingDay = {...selectedDays[cellId] || null};


                        return (
                            <CalendarItem
                                onCellClick={onCellClick}
                                bookingDay={bookingDay}
                                disabledDay={noPastSates && moment(cellId, "D-MM-YYYY").isBefore(startOfDay)}
                                activeDay={isActiveDay}
                                key={cellId+"_"+randomStr(4)}
                                cellId={cellId}
                                day={day}
                                isLastMonthDay={(daysArr.length == day)?true:false}
                                selectedOnlyStartDate={selectedData.startDate && !selectedData.endDate}
                                isStartDay={selectedData.startDate && moment(selectedData.startDate).format("D-MM-YYYY") == cellId}
                                isEndDay={selectedData.endDate && moment(selectedData.endDate).format("D-MM-YYYY") == cellId}
                            />
                        )
                    })
                }

                <CalendarBuffer buffer={bufferAfter}/>

            </Box>

        </Box>

    )

}

const generateMonths = (afterMonths, scrollToDate) => {

    let startCalendarDate = moment().startOf('month');

    let monthsTmp = [];
    let heightPosition = 0;
    const clientWidth = document.documentElement.clientWidth > 568?568:document.documentElement.clientWidth;
    const dayWidth = clientWidth / 7;

    //до какого месяца нужно проскроллить
    const scrollToMonthId = moment(scrollToDate).subtract(1, "month").format("MM.YY");

    // первоначальное заполнение массива месяцев календаря

    //console.log("generateMonths: [beforeMonths, afterMonths]", [beforeMonths, afterMonths]);

    //console.log("заполнение массива месяцев от >", moment(startCalendarDate).format("MM.YY"));
    //console.log("заполнение массива месяцев до >", moment(startCalendarDate).add((beforeMonths+afterMonths),'month').format("MM.YY"));

    //обнуляем scrollTop
    scrollTop = 0;

    for (let i = 0; i < (afterMonths+1); i++) {

        const date = moment(startCalendarDate).add(i,'month');
        const monthId = moment(date).format("MM.YY");
        const month = moment(date).format("MM");
        const year = moment(date).format("YYYY");

        let buffer = moment(date).isoWeekday();
        buffer--;

        const days = moment(date).daysInMonth();
        const cells = Math.ceil((buffer+days)/7)*7;
        const calendarHeight = (cells / 7) * dayWidth;
        const top = heightPosition;
        //101px - корректировка высоты заголовка
        const bottom = heightPosition + calendarHeight + 101;
        const rowsCount = cells / 7;
        //корректировка для скроллинга активного месяца
        // +50 - высота с учетом высоты месяцев
        if(monthId == scrollToMonthId) scrollTop = bottom + 0;

        const visible = (scrollTop < top)?true:false;

        monthsTmp.push({
            monthId: monthId,
            date: date.toDate(),
            month: month,
            year: year,
            top: top,
            bottom: bottom,
            calendarHeight: calendarHeight,
            visible: visible,
            buffer: buffer,
            cells: cells,
            rowsCount: rowsCount,
            bufferAfter: cells-(buffer+days),
            days: days,
        });

        heightPosition = bottom;

    }

    return monthsTmp;

}

const getMonthsInView = (months, scroll) => {

    const scrollBottom = scroll + document.documentElement.clientHeight;
    return  months.filter(m => m.bottom > (scroll - 400) && m.top < (scrollBottom + 400) );

}

//генерация выделенных дней
const generateSelectedDays = ({startDate, endDate, guestsCount}) => {

    let selectedCount;
    if(!startDate && !endDate){
        selectedCount = 0;
    }else if(!endDate){
        selectedCount = 1;
    }else{
        selectedCount = moment(endDate).diff(startDate, 'days') + 1;
    }

    let selectedDaysTmp = {};

    //добавление слоя выделения
    for (let j = 0; j < selectedCount; j++) {

        const selectedDateId = moment(startDate).add(j, "days").format("D-MM-YYYY");

        if(!selectedDaysTmp[selectedDateId]) selectedDaysTmp[selectedDateId] = {};
        selectedDaysTmp[selectedDateId].selected = true;

        //формируем окончания периода (если разрешено)
        if(selectedCount != 1){
            if(j==0) selectedDaysTmp[selectedDateId].selectedFirst = true;
            if(j+1 == selectedCount) selectedDaysTmp[selectedDateId].selectedLast = true;
        }

    }

    return selectedDaysTmp;

}

export default (props) => {

    const classes = useStyles();

    const {
        afterMonths,
        afterChange,
        selectedData,
        noPastSates,
        calendarClassName,
    } = props;

    let nowMonthId = moment().format("MM.YY");

    //console.log("re-render RangeCalendar selectedData=", selectedData);

    //генерируем активный слой (если он есть)
    const [selectedDays, setSelectedDays] = useState(generateSelectedDays(selectedData));
    const [months, setMonths] = useState([]);
    const [monthsInView, setMonthsInView] = useState([]);
    const [loading, setLoading] = useState(true);
    const rangeCalendarRef = useRef();

    //выполняется при загрузке страницы 1 рвз потом удаляется
    useEffect(() => {
        const monthsTmp = generateMonths(afterMonths, moment((selectedData?.startDate || new Date())));
        setMonths(monthsTmp);
        setMonthsInView(getMonthsInView(monthsTmp, 0));
        setLoading(false);
    }, []);

    //скролл до активного месяца
    useEffect(() => {
        //console.log("скролл до ", scrollTop);
        if(rangeCalendarRef?.current) rangeCalendarRef.current.scrollTop = scrollTop;
    },[loading]);

    const onScrollRangeCalendarBox = (event) => {

        const st = event.currentTarget.scrollTop;
        const scroll = Math.round((st || 0)/300)*300;

        if(scroll != scrollTop){
            scrollTop = scroll;
            setMonthsInView(getMonthsInView(months, scroll));
        }

    }

    const onCellClick = (e, cellId) => {

        const cellDate = moment(cellId, "D-MM-YYYY").toDate();

        let startDate;
        let endDate;
        let bookingSelectedStatus;

        if(noPastSates && moment().startOf("day").isAfter(cellDate)){
            //выход если дата выбрана в прошлом
            return;
        }

        if(selectedData && selectedData.startDate && selectedData.endDate) bookingSelectedStatus = 2;
        if(selectedData && selectedData.startDate && !selectedData.endDate) bookingSelectedStatus = 1;
        if(selectedData && !selectedData.startDate && !selectedData.endDate ) bookingSelectedStatus = 0;

        switch (bookingSelectedStatus) {
            case 0:
            case 2:
                startDate = cellDate;
                endDate = null;
                break;
            case 1:
                if(moment(selectedData.startDate).isBefore(cellDate)){
                    startDate = selectedData.startDate;
                    endDate = cellDate;
                }else if(moment(cellDate).isBefore(selectedData.startDate)){
                    startDate = cellDate;
                    endDate = selectedData.startDate;
                }
                break;
        }

        //сохранение selectedDays
        setSelectedDays(generateSelectedDays({startDate, endDate}));

        afterChange({...selectedData, startDate: startDate, endDate: endDate});

    };

    if(loading) return null;

    return (
        <div
            id="booking"
            ref={rangeCalendarRef}
            className={calendarClassName}
            onScroll={onScrollRangeCalendarBox}
        >

            <Box className={clsx(classes.calendar, classes.overflowY)} style={{height: months[months.length-1].bottom}}>
                {
                    monthsInView.map(month => {
                        return (
                            <MonthCalendar
                                id={month.monthId}
                                monthId={month.monthId}
                                calendarHeight={month.calendarHeight}
                                top={month.top}
                                onCellClick={onCellClick}
                                selectedDays={selectedDays}
                                selectedData={selectedData}
                                activeMonth={(nowMonthId == month.monthId?true:false)}
                                key={month.monthId}
                                rowsCount={month.rowsCount}
                                month={month.month}
                                buffer={month.buffer}
                                bufferAfter={month.bufferAfter}
                                days={month.days}
                                year={month.year}
                                noPastSates={noPastSates}
                            />
                        )
                    })
                }
            </Box>
        </div>
    )

}
import clsx from "clsx";
import {AOFavoriteBorderIcon, AOFavoriteIcon, NoPhotoIcon, WarningIcon, PinIcon, BookServiceSignIcon} from "../../assets/svg-icons.js";
import moment from 'moment';
import {priceToTriads, getReHeader, getTextDate} from "../../utils/functions";
import React from "react";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import IconButton from "@mui/material/IconButton";

const afterDate = moment().subtract(30, "days").toDate();

export default (props) => {

    const {
        classes,
        onViewRe,
        re,
        includedReOptions,
        collectionFavorites,
        isMyCollection,
        addReToCollectionFavorites,
        houseId,
        from,
        pubConfig,
        backUrl,
    } = props;

    const {
        cover,
        price,
        //minPrice,
        houseName,
        _id,
        numberId,
        name,
        //publishedInCatalog,
        changedPrice,
        highlightedCollections,
        highlightedCollFavorites,
        userId,
        //
        pricePeriod,
        //pricePeriodAv,
        pricePeriodCount,
        companyId,
    } = re;

    //const publishedInCatalogStatus = publishedInCatalog && moment(publishedInCatalog).isAfter(afterDate) || false;

    //показывает подсвечивать ли объект
    const showHighlightedCollections = highlightedCollections && includedReOptions && includedReOptions.options && ["my", "all"].indexOf(includedReOptions.options) > -1 || false;

    return (
        <div className={classes.catalogMediumItemWrapper}>

            <div className={clsx(classes.catalogMediumItemImage, (!cover && classes.catalogItemNotImage || null))} >

                {!!(companyId && companyId == pubConfig.bookServiceCompanyId) &&
                    <div className={classes.catalogItemBookmark}>
                        <BookServiceSignIcon sx={{ fontSize: 17 }}/>
                    </div>
                }

                {!!(highlightedCollFavorites && collectionFavorites) &&
                <div className={classes.catalogItemBookmark}>
                    <AOFavoriteIcon sx={{ fontSize: 14 }}/>
                </div>
                }

                <div className={classes.catalogItemImageBtnWrapper}>
                    {!!(!isMyCollection && from && from == "collections") &&
                    <div className={clsx(classes.squareBtn, classes.reCardToFavoriteBtnWrapper, (highlightedCollFavorites?classes.catalogItemImageToFavoriteBtnWrapperChecked:null))}>
                        <IconButton size="small" color="primary" component="label" onClick={() => addReToCollectionFavorites(houseId, re._id, !highlightedCollFavorites)}>{highlightedCollFavorites?<AOFavoriteIcon style={{color: "#EB3333"}}/>:<AOFavoriteBorderIcon/>}</IconButton>
                    </div>
                    }
                </div>

                {cover ? <img src={pubConfig.image_url + "/" + (cover?.images && (cover.images.thumbXS?.url || cover.images.xs?.url))} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/> : <NoPhotoIcon className={classes.noPhotoIcon} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/>}

                <div className={clsx(classes.catalogMediumItemImage__housename, "catalogPublishedAt")}><PinIcon/><span>{houseName}</span></div>

            </div>

            <div className={classes.catalogMediumItemContent} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>

                <p className={classes.catalogMediumItemContentHeader}>{getReHeader(re, false)}</p>

                {!!( price && !pricePeriod) &&
                    <div className={classes.catalogMediumItemContentPrice}>
                        <span>от <b>{priceToTriads(price)} ₽</b> за сутки</span>
                        {!!changedPrice && <WarningIcon style={{fontSize: "0.8rem", marginLeft: 5, color: "#FFA10A"}}/>}
                    </div>
                }

                {!!pricePeriod &&
                <>
                    <div className={classes.catalogMediumItemContentPrice}>
                        <span><b>{priceToTriads(pricePeriod)} ₽</b> за {pricePeriodCount} дн.</span>
                        {!!changedPrice && <WarningIcon style={{fontSize: "0.8rem", marginLeft: 5, color: "#FFA10A"}}/>}
                    </div>
                    {/*
                    <div className={classes.catalogMediumItemContentAddPrice}>
                        <span>от <b>{priceToTriads( Math.round(pricePeriodAv))} ₽</b> за сутки</span>
                    </div>
                    */}

                </>
                }

            </div>

        </div>
    )

}
import React, {useCallback, useContext, useEffect, useState} from "react";
import useStyles from "./styles";
import Chip from '@mui/material/Chip';
import {GlobalStateContext} from "../../providers/GlobalStateProvider";


const getTitleByValue = (values, value) => {
    return values.find(v => v.value == value)?.title || "?";
}

export default (props) => {

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const defaultTags = pubConfig.housesOptions.tags;
    const defaultPaymentOptions = pubConfig.housesOptions.paymentOptions;
    const defaultPurchaseOptions = pubConfig.housesOptions.purchaseOptions;
    const defaultParkingOptions = pubConfig.housesOptions.parkingOptions;
    const defaultWaterSupplyOptions = pubConfig.housesOptions.waterSupplyOptions;
    const defaultType = pubConfig.housesOptions.type;
    const defaultStatus = pubConfig.housesOptions.status;
    const defaultHouseClass = pubConfig.housesOptions.houseClass;
    const defaultTerritory = pubConfig.housesOptions.territory;
    const defaultHeating = pubConfig.housesOptions.heating;
    const defaultSewerage = pubConfig.housesOptions.sewerage;

    const {activeHouse, onEditHouseInfo, user} = props;
    const {options} = activeHouse;

    const classes = useStyles();

    const mainOptionsExists = (
        options &&
        (!!(options && options.text) ||
            !!(options && options.tags?.length))
    );

    const characteristicsExists = (
        options &&
        (!!options.houseClass ||
            !!options.type ||
            !!(options.parkingOptions && options.parkingOptions.length) ||
            !!options.territory ||
            !!options.distanceToTheSea ||
            !!options.ceilingHeight ||
            !!options.floorHouse)
    );

    const payExists = (
        options &&
        (!!options.status ||
            !!(options.paymentOptions && options.paymentOptions.length) ||
            !!(options.purchaseOptions && options.purchaseOptions.length))
    );

    return (
        <div className={classes.houseInfoWrapper}>

            <div className={classes.houseCardInfo}>
                <p className={classes.houseCardInfoHeader}>{activeHouse.name}</p>
            </div>

            {
                !!options &&
                <div className={classes.houseInfoContent}>

                    {!!mainOptionsExists && <p className={classes.h3}>Общая информация</p>}

                    {!!(options && options.text) && <p className={classes.houseInfoText}>{options.text}</p>}

                    {!!(options && options.tags?.length) &&
                    <div className={classes.houseInfoTags}>
                        {options.tags.map(t => (<Chip className={classes.houseInfoTag} key={t} label={getTitleByValue(defaultTags, t)} variant="outlined" />))}
                    </div>
                    }

                    {!!characteristicsExists && <p className={classes.h3}>Основные характеристики:</p>}

                    <div className={classes.houseInfoOptions}>

                        {!!options.houseClass &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Класс дома</span></div>
                            <div>{getTitleByValue(defaultHouseClass, options.houseClass)}</div>
                        </div>
                        }

                        {!!options.type &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Тип дома</span></div>
                            <div>{getTitleByValue(defaultType, options.type)}</div>
                        </div>
                        }

                        {!!(options.parkingOptions && options.parkingOptions.length) &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Паркинг</span></div>
                            <div>{options.parkingOptions.map(i => getTitleByValue(defaultParkingOptions, i)).join(", ")}</div>
                        </div>
                        }

                        {!!options.territory &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Территория</span></div>
                            <div>{getTitleByValue(defaultTerritory, options.territory)}</div>
                        </div>
                        }

                        {!!options.distanceToTheSea &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Расстояние до моря</span></div>
                            <div>{options.distanceToTheSea} м</div>
                        </div>
                        }

                        {!!options.ceilingHeight &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Высота потолков</span></div>
                            <div>{options.ceilingHeight.toFixed(1)} м</div>
                        </div>
                        }

                        {!!options.floorHouse &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Этажность</span></div>
                            <div>{options.floorHouse} этажей</div>
                        </div>
                        }

                    </div>

                    <p className={classes.h3}>Коммуникации:</p>

                    <div className={classes.houseInfoOptions}>

                        <div className={classes.houseInfoOptions}>
                            <div className={classes.houseInfoOptionsRow}>
                                <div><span>Газ</span></div>
                                <div>{options.gas?"Есть":"Нет"}</div>
                            </div>
                        </div>

                        {!!options.heating &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Отопление</span></div>
                            <div>{getTitleByValue(defaultHeating, options.heating)}</div>
                        </div>
                        }

                        {!!options.sewerage &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Канализация</span></div>
                            <div>{getTitleByValue(defaultSewerage, options.sewerage)}</div>
                        </div>
                        }

                        {!!(options.waterSupplyOptions && options.waterSupplyOptions.length) &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Водоснабжение</span></div>
                            <div>{options.waterSupplyOptions.map(i => getTitleByValue(defaultWaterSupplyOptions, i)).join(", ")}</div>
                        </div>
                        }

                    </div>

                    {!! payExists && <p className={classes.h3}>Варианты оплаты и оформление:</p>}

                    <div className={classes.houseInfoOptions}>

                        {!!options.status &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Статус</span></div>
                            <div>{getTitleByValue(defaultStatus, options.status)}</div>
                        </div>
                        }

                        {!!(options.paymentOptions && options.paymentOptions.length) &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Варианты оплаты</span></div>
                            <div>{options.paymentOptions.map(i => getTitleByValue(defaultPaymentOptions, i)).join(", ")}</div>
                        </div>
                        }

                        {!!(options.purchaseOptions && options.purchaseOptions.length) &&
                        <div className={classes.houseInfoOptionsRow}>
                            <div><span>Оформление</span></div>
                            <div>{options.purchaseOptions.map(i => getTitleByValue(defaultPurchaseOptions, i)).join(", ")}</div>
                        </div>
                        }

                    </div>

                </div>
            }

        </div>
    )
}

import React, {useRef, useState} from "react";
import useGStyles from "../../assets/global-styles";
import Slider from "react-slick";

const MainTopBanner = (props) => {

    const sliderRef = useRef();

    const gClasses = useGStyles();

    const [infoSliderIndex, setInfoSliderIndex] = useState(0);

    const onChangeSlider = (newAlignment) => {
        setInfoSliderIndex(newAlignment);
    };

    return (
        <section className={gClasses.mainTopBanner}>
            <Slider
                autoplay={true}
                dots={true}
                infinite={true}
                speed={1000}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                touchThreshold={15}
                ref={sliderRef}
                className={gClasses.mainTopBanner__slider}
                afterChange={(newAlignment) => onChangeSlider(newAlignment)}
            >
                <div>
                    <div className={gClasses.mainTopBanner__slide}>
                        <h1>Сервис по <br/>бронированию <br/>жилья в Сочи</h1>
                    </div>
                </div>

                <div>
                    <div className={gClasses.mainTopBanner__slide}>
                        <h2>Снять квартиру <br/>или апартаменты <br/>в Сочи</h2>
                    </div>
                </div>

                <div>
                    <div className={gClasses.mainTopBanner__slide}>
                        <h2>Краткосрочная <br/>аренда жилья<br/>в Сочи</h2>
                    </div>
                </div>


            </Slider>
        </section>
    )


}

export default React.memo((props) => {
    return (
        <MainTopBanner {...props} />
    );
},(b, a) => {
    return true;
});
import instance from "../api/axios.api";

const YandexMapService = {

    async getFeatures(options) {
        const { data } = await instance.post("/book/map/get-features", {...options});
        return data;
    },

};
export default YandexMapService;

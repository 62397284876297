import React, {useCallback, useContext, useEffect, useState} from "react";
import useStyles from "./styles";
import {priceToTriads, getTextDate, getReSquareHeader} from "../../utils/functions";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {HomeIcon, NoPhotoIcon, Area2Icon, WarningIcon, CameraIcon, BookServiceSignIcon} from "../../assets/svg-icons.js";
import clsx from "clsx";
import Fade from '@mui/material/Fade';
import Rating from '@mui/material/Rating';
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer/Drawer";
import CloseIcon from "@mui/material/SvgIcon/SvgIcon";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from 'moment';
import _ from "lodash";
import useGStyles from "../../assets/global-styles";
import CatalogService from "../../services/catalog.service.js";
import UserService from "../../services/user.service";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

let pageIndex = 0;
const NUM_ROWS = 10;
let fetchMoreLoading = false;
let dateRequest;

//console.log("RE-LOAD HOUSE CARD dateRequest="+dateRequest);

const HouseImageWithProgressWithMemo = React.memo(({src, classes}) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className={classes.imageReCardWithSpinnerWrapper}>
            <img src={src} onLoad={() => {
                setLoading(false)
            }} style={{display: (loading ? "none" : "block")}}></img>
            <Fade
                in={loading}
                style={{transitionDelay: loading ? '800ms' : '0ms'}}
                unmountOnExit
            >
                <CircularProgress/>
            </Fade>
        </div>
    );
},(b, a) => {
    return true;
});


const ImageWithProgressWithMemo = React.memo(({src, classes, uid}) => {
    const [loading, setLoading] = useState(true);
    //console.log("re-render ImageWithProgressWithMemo");
    return (
        <div className={clsx(classes.catalogItemBigImageWithSpinnerWrapper, "catalogItemBigImageWithSpinnerWrapper")}>
            <img
                src={src}
                onLoad={() => {setLoading(false)}}
                style={{display: (loading ? "none" : "block")}}
            />
            <Fade
                in={loading}
                style={{transitionDelay: loading ? '800ms' : '0ms'}}
                unmountOnExit
            >
                <CircularProgress/>
            </Fade>
        </div>
    );
},(b, a) => {return true;});


const getReGroupItemHeader = (r) => {

    const header = [];

    switch (r.rooms || null) {
        case "fa_reoptions_1":
            header.push("Студия");
            break;
        case "fa_reoptions_2":
            header.push("1-к");
            break;
        case "fa_reoptions_4":
            header.push("2-к");
            break;
        case "fa_reoptions_5":
            header.push("3-к");
            break;
        case "fa_reoptions_6":
            header.push("4-к");
            break;
        case "flda_reoptions_2":
            header.push("Дом");
            break;
        default:
            break
    }

    if(r.area) header.push(r.area+"м²");

    if(r.floor) header.push(r.floor+(r.floorHouse?"/"+r.floorHouse:"")+"э");

    if(!(header && header.length)) return "Объект";

    return header.join(", ");

}

const RowTpl = (props) => {

    const {
        classes,
        onViewRe,
        onChangeRe,
        re,
        onViewActionSheetWithRe,
        checkReMode,
        isAgent,
        pubConfig,
        backUrl,
    } = props;

    const {
        cover,
        price,
        minPrice,
        commission,
        _id,
        name,
        numberId,
        userId,
        checked,
        publishedInCatalog,
        //
        pricePeriod,
        //pricePeriodAv,
        pricePeriodCount,
        companyId,
    } = re;

    const pricePeriodAv = Math.ceil(pricePeriod/pricePeriodCount);

    return (
        <div className={clsx(classes.catalogItemWrapper, classes.houseCatalogItemWrapper)}>

            {!!(companyId && companyId == pubConfig.bookServiceCompanyId) &&
                <div className={classes.catalogItemBookmark} style={{left: 28}}>
                    <BookServiceSignIcon sx={{ fontSize: 17 }}/>
                </div>
            }

            {checkReMode && isAgent &&
            <div className={clsx(classes.squareBtn, classes.catalogItemImageBtnWrapper)}>
                <Checkbox size="small" data-name="checkbox" checked={!!checked} onChange={(e) => onChangeRe(_id)} onTransitionEnd={this.stop} className={clsx(classes.catalogItemCheckbox, (checked?classes.catalogItemCheckboxChecked:classes.catalogItemCheckboxNotChecked))}  />
            </div>
            }

            {!checkReMode && isAgent &&
            <div className={clsx(classes.squareBtn, classes.catalogItemImageBtnWrapper)}>
                <IconButton size="small" color="primary" component="label" onClick={() => onViewActionSheetWithRe(_id)} style={{color: "rgba(0, 32, 51, 1)"}}><MoreVertIcon /></IconButton>
            </div>
            }

            <div className={clsx(classes.catalogItemImage, (!cover && classes.catalogItemNotImage || null))} >
                {cover ? <img src={pubConfig.image_url + "/" + (cover?.images && (cover.images.thumbXS?.url || cover.images.xs?.url))} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/> : <NoPhotoIcon className={classes.noPhotoIcon} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/>}
                <div className="catalogPublishedAt"><span>{getTextDate(publishedInCatalog, 7)}</span></div>
            </div>

            <div className={clsx(classes.catalogItemContent, classes.catalogItemContentWithOutContact)} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>
                <p className={classes.catalogItemContentHeader}>{name || getReGroupItemHeader(re)}</p>

                {!!( (price || minPrice) && !pricePeriod) && <p className={classes.catalogItemContentPrice}><span>{minPrice?"от":""} {priceToTriads(minPrice || price)} ₽</span></p>}

                {!!pricePeriod &&
                <>
                    <div className={classes.catalogItemContentPrice}><span>{priceToTriads(pricePeriod)} ₽ за {pricePeriodCount} дн.</span></div>
                    <div className={classes.catalogItemContentAddPrice}><span>{priceToTriads( pricePeriodAv)} ₽ / сутки</span></div>
                </>
                }

                {!!commission && <p className={classes.catalogItemContentCommission}>({priceToTriads(commission)}) ₽</p>}
            </div>

        </div>
    )

}

const RowWithImagesTpl = (props) => {

    const {
        classes,
        onViewRe,
        onChangeRe,
        re,
        onViewActionSheetWithRe,
        checkReMode,
        isAgent,
        pubConfig,
        backUrl,
    } = props;

    const {
        cover,
        price,
        //minPrice,
        commission,
        _id,
        name,
        numberId,
        userId,
        checked,
        publishedInCatalog,
        //
        pricePeriod,
        //pricePeriodAv,
        pricePeriodCount,
        companyId,
        files,
    } = re;

    const pricePeriodAv = Math.ceil(pricePeriod/pricePeriodCount);

    return (
        <div className={clsx(classes.catalogItemBigWrapper)}>

            {!!(companyId && companyId == pubConfig.bookServiceCompanyId) &&
            <div className={classes.catalogItemBookmark} style={{left: 28}}>
                <BookServiceSignIcon sx={{ fontSize: 17 }}/>
            </div>
            }

            <div className={clsx(classes.catalogItemBigImagesWrapper, (files && files.length == 1?classes.imageReCardOnePhoto:null))} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>
                {
                    (files && files.length) ?
                        files.map(thumb => (<ImageWithProgressWithMemo classes={classes} uid={thumb.uid} key={thumb.uid} src={pubConfig.image_url+"/"+(thumb?.images && (thumb.images.thumbXS?.url || thumb.images.xs?.url))}/>))
                        :
                        <div className={classes.noReCardPhoto}><NoPhotoIcon className={classes.noReCardPhotoIcon}/></div>
                }
            </div>

            <div className={classes.catalogBigItemContent} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>
                <div className={classes.catalogBigItemContentHeader}>{name || getReGroupItemHeader(re)}</div>

                {!!( price  && !pricePeriod) && <div className={classes.catalogBigItemContentPrice}><span>от {priceToTriads(price)} ₽ / сутки</span></div>}

                {!!pricePeriod &&
                <>
                    <div className={classes.catalogBigItemContentPrice}><span>{priceToTriads(pricePeriod)} ₽ за {pricePeriodCount} дн.</span></div>
                    <div className={classes.catalogBigItemContentAddPrice}><span>{priceToTriads(pricePeriodAv)} ₽ / сутки</span></div>
                </>
                }
            </div>

        </div>
    )

}

const RowSquareTpl = (props) => {

    const {
        id,
        classes,
        onViewRe,
        re,
        backUrl,
    } = props;

    const {
        cover,
        _id,
        numberId,
        userId,
        furnish,
        price,
        pricePeriod,
        category,
    } = re;

    return (
        <div key={re._id} className={clsx(classes.catalogItemSquareWrapper)} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>
            <div className={clsx(classes.catalogItemSquareContent)}>

                <div className={classes.catalogItemSquareHeader}>
                    {getReSquareHeader(re)}
                </div>

                {!!(price && !pricePeriod) && <div className={classes.catalogItemSquarePrice}>{price.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1\.')}</div>}

                {!!pricePeriod &&
                <div className={classes.catalogItemSquarePrice}>
                    {pricePeriod.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1\.')}
                </div>
                }

                <div className={classes.catalogItemSquareOptions}>

                    {!!cover && <CameraIcon sx={{ fontSize: 14 }}/>}

                    {!!(furnish && category && category != "rent") && <span className={classes.catalogItemSquareOptionsTextIcon}>Р</span>}

                </div>

            </div>
        </div>
    )

}

export default (props) => {

    const {
        activeHouse,
        user,
        onViewRe,
        onViewHouseInfo,
        onEditHousePhoto,
        addToCollection,
        addHouseToCollection,
        reload,
        checkedRe,
        setCheckedRe,
        checkReMode,
        setCheckReMode,
        //data,
        //setData,
        filterState,
        viewHouseInMap,
        isAgent,
        //параметр группировки
        groupBy,
        setGroupBy,
        //вид отображения
        view,
        setView,
        showBreadCrumbs,
    } = props;

    //глобальный state
    const {
        pubConfig,
        toast, setToast,
    } = useContext(GlobalStateContext);

    //данные ЖК
    const [data, setData] = useState([]);

    const [hasMore, setHasMore] = useState(true);

    const [activeRe, setActiveRe] = useState([]);

    //ActionSheet
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [actionSheetType, setActionSheetType] = useState(null);

    const classes = useStyles();
    const gClasses = useGStyles();

    if(!activeHouse) return <p>Комплекс не найден!</p>;

    const {
        files,
        countRe,
        minArea,
        maxArea,
        minPrice,
        maxPrice,
        rating,
        address,
    } = activeHouse;

    const groupValues = [
        {id:  "letters", inSelect: true, title: "Разбить по корпусам", getGroupName: (value) => (value && value != "null"?"Корпус "+activeHouse?.housing?.find(h => h.id == value)?.name || "?":"Корпус не указан")},
        {id:  "floor", inSelect: true, title: "Разбить по этажам", getGroupName: (value) => (value && value != "null"?"Этаж "+value:"Этаж не указан")},
        {id:  "rooms", inSelect: true, title: "Разбить по планировкам", getGroupName: (value) => value?pubConfig.adRooms.find(r => r.code == value)?.name || "Планировка не указана":"Планировка не указана"},
        {id:  "furnish", inSelect: true, title: "Разбить по ремонту", getGroupName: (value) => (value && value != "null"?"С ремонтом":"Без ремонта")},
        //
        {id:  "no_grouping", inSelect: false, title: "", getGroupName: () => "Объекты комплекса"},
    ];

    //const addressName = activeHouse.addressData.address.name;

    const stopfetchData = () => {
        setHasMore(false);
        fetchMoreLoading = false;
    }

    //обрабатывает отметку/снятие объекта
    const onChangeRe = (id) => {
        const reItems = data.map(item => (item._id == id?{...item, checked: !item.checked}:item));
        setCheckedRe(reItems.filter(r => r.checked));
        setData(reItems);
        return false;
    }

    const fetchMoreData = async (reload, filter, options) => {

        if(!activeHouse) return;

        let hasMoreTmp = hasMore;
        let skip;

        if(reload){
            setHasMore(true);
            hasMoreTmp = true;
            setData([]);
            pageIndex = 0;
            skip = 0;
            dateRequest = new Date();
        }else{
            skip = data.length;
        }

        //если необходимо загрузить другую data
        if(options?.data) setData(options.data);

        if (fetchMoreLoading || !hasMoreTmp) return;

        fetchMoreLoading = true;

        console.log("House-card-tpl fetchMoreData dateRequest", moment(dateRequest).format("DD.MM.YY HH:mm:ss"));

        //группировка
        const groupByTmp = options && options.groupBy && {groupBy: options.groupBy} || {};

        //сортировка обязательно в конце, чтобы фильтр не затирал ее
        const result = await CatalogService.getHouseReItems({param: "catalog-search-houses", houseId: activeHouse._id, skip, limit: (options && options.limit || NUM_ROWS), ...filter, sort: "price", dateRequest, ...groupByTmp});

        if (!(result && result.status) ) {
            console.log("Ошибка загрузки данных");
            stopfetchData();
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка загрузки данных!"});
            return;
        }

        if( !(result.re && result.re.length) ){
            stopfetchData();
            return;
        }

        //корреткировка пустых полей (для группировки)
        const tmpData = result.re.map(r => ({...r, letters: r.letters || null, floor: r.floor || null, rooms: r.rooms || null, furnish: r.furnish || null}));

        if(reload){
            setData(tmpData);
            if(!(options && options.scrollTop) ) {
                //если не надо загрузить pages и применить scrollTop, то обнуляем scrollTop (тк перезагрузка контента)
                //setFilterState({... filter, scrollTop: 0});
            }else{
                //если идет загрузка pages и надо применить scrollTop
                document.querySelector("#reScrollableDiv").scrollTop = options.scrollTop
            }
        }else{
            //если необходимо использовать другую data
            setData( (options?.data || data).concat(tmpData) );
        }

        fetchMoreLoading = false;

    }

    useEffect(() => {
        console.log("re-render house-card-tpl useEffect");
        fetchMoreData(true, filterState, {groupBy});
    },[activeHouse, reload]);

    const onReAction = (re) => {
        setActiveRe(re);
        setActionSheetType("action_with_re");
        setShowActionSheet(true);
    }

    //открывает ActionSheetWithRe оставляя отмеченным только выбранный элемент
    const onViewActionSheetWithRe = (reId) => {

        //оставляем отмеченным только выбранный элемент
        const reItems = data.map(item => (item._id == reId?{...item, checked: true}:{...item, checked: false}));
        setCheckedRe(reItems.filter(r => r.checked));
        setData(reItems);

        setActionSheetType("action_with_re");
        setShowActionSheet(true);
    };



    const renderActionSheetSwitch = () => {
        switch (actionSheetType) {
            case "action_with_re":
                return (
                    <ButtonGroup
                        variant="text"
                        orientation="vertical"
                    >
                        {/*<Button size="large">В избранное</Button>*/}
                        <Button
                            onClick={() => {
                                setCheckReMode(true);
                                setShowActionSheet(false);
                            }}
                            size="large"
                        >
                            Добавить в подборку
                        </Button>
                    </ButtonGroup>
                );
            case "action_add_house_to_collection":
                return (
                    <ButtonGroup
                        variant="text"
                        orientation="vertical"
                    >
                        <Button
                            onClick={() => {
                                setShowActionSheet(false);
                                addHouseToCollection("all");
                            }}
                            size="large"
                        >
                            Все объекты комплекса
                        </Button>

                        <Button
                            onClick={() => {
                                setShowActionSheet(false);
                                addHouseToCollection("only-my");
                            }}
                            size="large"
                        >
                            Только мои объекты комплекса
                        </Button>

                    </ButtonGroup>
                );
            default:
                return null;
        }
    }

    const onCloseActionSheet = () => {
        setShowActionSheet(false);
    }

    const onCloseCheckRe = () => {
        setData(data.map(item => (item.checked?{...item, checked: false}:item)));
        setCheckedRe([]);
        setCheckReMode(false);
    };

    const renderRowTpl = (props) => {
        switch (view) {
            case "tile": return <RowWithImagesTpl key={props.key} {...props} pubConfig={pubConfig}/>;
            case "checkerboard": return <RowSquareTpl {...props}/>;
        }
    }

    const onGroupItems = (e) => {
        let value = e.target.value;
        //корректировка пустого значения
        if(value && value == "no_grouping") value = "";
        setGroupBy(value);
        fetchMoreData(true, filterState, {groupBy: value});
    }

    const onChangeView = (e) => {
        setView(e.target.value);

        //сохраняем вид
        //useMethod("methods.runAPIMethod", "user/set-data", user, {param: "catalog-view", data: e.target.value});

        //TODO - требуется проверка
        UserService.setData({param: "catalog-view", data: e.target.value});


        fetchMoreData(true, filterState, {groupBy});
    }

    //определяем параметр группировки
    const groupByParam = !!groupBy && groupBy || "no_grouping";
    //группируем
    const gData = _.groupBy(data, groupByParam);
    //получаем настройки
    const gOptions = groupValues.find(item => item.id == groupByParam);
    let gKeys = Object.keys(gData);
    //перемещаем пустые элементы в конец
    gKeys = gKeys.filter(k => !/(null|undefined|false|^$)/.test(k)).concat(gKeys.filter(k => /(null|undefined|false|^$)/.test(k)) || []);

    //console.log("gKeys", gKeys);
    //console.log("view", view);
    //console.log("gData", gData);

    return (
        <div style={{height: "100%"}}>

            <div id="reScrollableDiv" className={classes.scrollBoxHouseCard} >
                <InfiniteScroll
                    dataLength={data.length}
                    scrollThreshold="700px"
                    next={() => fetchMoreData(false, filterState, {groupBy})}
                    hasMore={hasMore}
                    //onScroll={onScroll}
                    loader={<Typography className={gClasses.infiniteScrollLoading} variant="body1">Загрузка...</Typography>}
                    scrollableTarget="reScrollableDiv"
                    endMessage={<Typography className={gClasses.infiniteScrollLoading} variant="body1">Все данные загружены</Typography>}
                >
                    {
                        showBreadCrumbs && <BreadCrumbs stack={[{name: activeHouse?.name}]} style={{marginBottom: 5}}/>
                    }

                    <div className={classes.houseCardInfo}>
                        <p className={classes.houseCardInfoHeader}>{activeHouse.name}</p>

                        {!!(rating && rating > 1) &&
                        <div className={classes.houseCardInfoRating}>
                            <span className={classes.houseCardInfoRatingVal}>{rating.toFixed(1)}</span>
                            <Rating value={+rating} readOnly size="small" precision={0.5} max={10}/>
                        </div>
                        }

                    </div>

                    <div className={classes.houseCardImagesWrapper}>

                        <div className={classes.houseCardImages}>
                            {
                                (files && files.length) ?
                                    files.map(thumb => (<HouseImageWithProgressWithMemo classes={classes} key={thumb.uid} src={pubConfig.image_url+"/"+(thumb?.images?.thumbXS?.url || null)}/>))
                                    :
                                    <div className={classes.noHouseCardPhoto}><NoPhotoIcon className={classes.noReCardPhotoIcon}/></div>
                            }
                        </div>
                    </div>

                    <div className={classes.houseCardBottomInfo}>

                        <div className={classes.houseCardAddressWrapper}>

                            {!!address &&
                            <div className={clsx(classes.catalogReCardHouseNameAddress)} onClick={() => viewHouseInMap()}>
                                <LocationOnIcon fontSize="small"/>
                                <p>{address}</p>
                            </div>
                            }
                        </div>

                        <Button fullWidth className={gClasses.greenOutlinedBtn} variant="outlined" size="small" style={{marginBottom: 15}} onClick={onViewHouseInfo}>Подробнее о комплексе</Button>

                    </div>

                    {
                        gKeys.map((groupedBy, index) => {
                            const activeClass = !/(null|undefined|false|^$)/.test(groupedBy);
                            console.log("groupedBy "+groupedBy+", count=",gData[groupedBy].length);
                            return (
                                <div key={groupedBy.toString()} className={clsx(classes.houseCardReWrapper, (index == 0?classes.mt0:null), (view == "checkerboard"?classes.houseCardReWrapperForSquare:null))} >
                                    <p style={{marginBottom: 15}} className={clsx(classes.houseCardReHeader, (activeClass?classes.houseCardReHeaderAcive:null))}>{gOptions?.getGroupName(groupedBy)}</p>
                                    {
                                        gData[groupedBy].map((item, index) => (
                                            renderRowTpl({
                                                //id коллекции
                                                id: activeHouse._id,
                                                backUrl: "/h/"+activeHouse.numberId,
                                                history: props.history,
                                                classes,
                                                key: item._id,
                                                user: props.user,
                                                onViewRe,
                                                onReAction,
                                                onChangeRe,
                                                onViewActionSheetWithRe,
                                                checkReMode,
                                                isAgent,
                                                re: item,
                                            })

                                        ))
                                    }
                                </div>
                            );
                        })
                    }


                </InfiniteScroll>
            </div>

            {
                !!checkReMode &&
                <div className={classes.processReWrapper}>
                    {!!checkedRe.length && <p>Отмечено {checkedRe.length} объектов</p>}
                    {!checkedRe.length && <p>Отметьте объекты</p>}

                    <div className={classes.processReBtns}>
                        <Button style={{width: "44%"}} color="error" startIcon={<CloseIcon/>} variant="text" onClick={() => onCloseCheckRe()}>Отмена</Button>
                        <Button style={{width: "56%"}} disabled={!checkedRe.length} variant="contained" onClick={addToCollection}>В подборку</Button>
                    </div>
                </div>
            }

            <Drawer
                anchor="bottom"
                open={showActionSheet}
                onClose={() => onCloseActionSheet()}
                className={gClasses.actionSheet}
            >
                <div className={gClasses.actionSheetWrapper}>
                    <div className={gClasses.drawerFrame}>
                        {renderActionSheetSwitch()}
                        <Button className={gClasses.actionSheetWrapperCancelBtn} variant="text" size="large" onClick={() => setShowActionSheet(false)}>Отмена</Button>
                    </div>
                </div>

            </Drawer>

        </div>
    )

}
import React, {Component, Fragment, useState, useEffect, useRef, useCallback} from 'react';
import clsx from "clsx";
import useGStyles from "../../assets/global-styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {GuestsIcon} from "../../assets/svg-icons";

export default (props) => {

    const {
        filter,
        drawer,
        setDrawer,
        onSaveGuestsCount,
    } = props;

    //фильтр по датам для аренды
    const [datesFilterData, setDatesFilterData] = React.useState(filter.dates);

    const incGuestsCount = (inc) => {
        if (inc < 0 && datesFilterData.guestsCount > 1 || inc > 0) {
            const filter = {...datesFilterData, guestsCount: datesFilterData.guestsCount + inc};
            setDatesFilterData(filter);
        }
    };

    const gClasses = useGStyles();

    return (
        <>

            <div className={clsx(gClasses.bottomDrawerHeader)}>

                <p>Количество гостей</p>

                <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                    <CloseIcon fontSize="inherit"/>
                </IconButton>

            </div>


            <div className={gClasses.changeGuestsCount}>

                <div className={gClasses.changeGuestsCount__label}>
                    <GuestsIcon/><span>Количество гостей</span>
                </div>

                <div className={gClasses.changeGuestsCount__guests}>
                    <IconButton size="small" onClick={() => incGuestsCount(-1)}><RemoveIcon/></IconButton>
                    <div className={gClasses.changeGuestsCount__guestsCount}>{datesFilterData.guestsCount}</div>
                    <IconButton size="small" onClick={() => incGuestsCount(1)}><AddIcon/></IconButton>
                </div>
            </div>

            <div className={gClasses.rangeCalendarDatesFilterBottomPanel} style={{border: "none"}}>
                <div>
                    <Button
                        className={gClasses.largeBtn}
                        fullWidth
                        onClick={() => onSaveGuestsCount(datesFilterData.guestsCount)}
                        variant="contained"
                        color="blue"
                    >
                        Применить
                    </Button>
                </div>
            </div>

        </>
    )

}
import useStyles from "./styles";
import React, {useState} from "react";

let tmpLoading = false;

export default (props) => {

    const {
        next,
        hasMore,
        onScroll,
        loader,
        endMessage,
        scrollBoxProps = {},
        wrapperClass,
    } = props;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleScroll = (event) => {

        if(onScroll) onScroll(event);

        const wrapperWidth = event.currentTarget.offsetWidth;
        const width = event.currentTarget.children[0].scrollWidth;
        const left = event.currentTarget.scrollLeft;
        const right = width - left - wrapperWidth + 10;

        //console.log("hasMore ",hasMore);

        if(right < 200 && hasMore && !tmpLoading){

            tmpLoading = true;
            setLoading(true);

            //console.log("Загружаю элементы");

            next().then(result => {

                if(result){
                    //console.log("Данные загружены hasMore="+hasMore);
                    tmpLoading = false;
                    setLoading(false);
                }else{
                    //console.log("Идет загрузка, пропускаю hasMore="+hasMore);
                }

            })


        }

        //console.log("handleScroll right=",right);
    }

    return (
        <div className={wrapperClass || classes.catalogHouseReItemsWrapper} onScroll={handleScroll} {...scrollBoxProps}>
            <div style={{display: "flex"}}>

                {props.children}

                {loading && loader}

                {!hasMore && endMessage}

            </div>
        </div>
    )
}
import React from 'react';
import {makeStyles} from "@mui/styles";
import {
    orange,
    deepOrange,
    lightGreen,
    lightBlue,
    grey,
    red,
    yellow,
    deepPurple,
    green,
    blue,
    teal,
} from '@mui/material/colors';

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";


export default makeStyles((theme) => ({

    //colors
    deepPurple500: {
        color: deepPurple[500],
    },
    colorOrange500: {
        color: orange[500],
    },
    colorOrange700: {
        color: orange[700],
    },
    colorRed500: {
        color: red[500],
    },
    lightGreen500: {
        color: lightGreen[500],
    },
    green500: {
        color: green[500],
    },
    lightBlue500: {
        color: lightBlue[500],
    },
    yellow500: {
        color: yellow[500],
    },
    blue200: {
        color: blue[200]
    },
    blue300: {
        color: blue[300]
    },
    blue400: {
        color: blue[400]
    },
    grey300: {
        color: grey[300],
    },
    grey400: {
        color: grey[400],
    },
    grey500: {
        color: grey[500],
    },
    lightGreen200: {
        color: lightGreen[200]
    },
    lightGreen300: {
        color: lightGreen[300]
    },
    lightGreen400: {
        color: lightGreen[400]
    },
    teal500: {
        color: teal[500],
    },
    cActive: {
        color: "#00B2FF!important",
    },

    //font-w
    fw400: {
        fontWeight: 400,
    },
    fw500: {
        fontWeight: 500,
    },
    fw600: {
        fontWeight: 600,
    },
    fw700: {
        fontWeight: 700,
    },
    m0: {
        margin: "0!important"
    },
    mt0: {
        marginTop: "0!important",
    },
    mb0: {
        marginBottom: "0!important",
    },
    mb5: {
        marginBottom: "5px!important",
    },
    mb10: {
        marginBottom: "10px!important",
    },
    mb16: {
        marginBottom: "16px!important",
    },
    mb20: {
        marginBottom: "20px!important",
    },
    mt10: {
        marginBottom: "10px!important",
    },
    mt15: {
        marginTop: "15px!important",
    },
    mt20: {
        marginTop: "20px!important",
    },
    ml0: {
        marginLeft: "0!important",
    },
    ml5: {
        marginLeft: "5px!important",
    },
    mr0: {
        marginRight: "0!important",
    },
    mr10: {
        marginRight: "10px!important",
    },
    mr16: {
        marginRight: "16px!important",
    },
    w90: {
        width: "90%",
    },
    w100: {
        width: "100%",
    },
    h100: {
        height: "100%",
    },
    p10: {
        padding: "10px!important",
    },
    p16: {
        padding: "16px!important",
    },
    p20: {
        padding: "20px!important",
    },
    pb70: {
        paddingBottom: 70,
    },
    pl16: {
        paddingLeft: 16,
    },
    noBg: {
        background: "none!important",
    },
    textH2: {
        fontWeight: 700,
    },
    h2: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 18*onePx+"rem",
        lineHeight: "1.2em",
        letterSpacing: "0.35px",
        color: "#2F2E36",
        margin: 0,
        textTransform: "uppercase",
    },
    h2WithIcon: {
      display: "flex",
      alignItems: "center",
    },
    h3: {
        fontWeight: 600,
        fontSize: 16*onePx+"rem",
        lineHeight: "21px",
        color: "#2F2E36",
        marginBottom: 10,
    },
    height100: {
        height: "100%",
    },
    overflowYAuto: {
        overflowY: "auto",
    },
    overflowXHidden: {
        overflowX: "hidden",
    },
    defaultLink: {
        "color": "#00a151",
        "textDecoration": "underline",
        "cursor": "pointer",
    },
    mainWrapper: {
        background: "#fff",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxWidth: 700,
        margin: "auto",
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    wrapper: {
        background: "#fff",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    pageContent: {
        width: "100%",
        height: "100%",
        maxWidth: 500,
        margin: "auto",
        overflowY: "auto",
        position: "relative",
        "& .MuiAlert-message": {
            wordBreak: "break-word",
        },
    },

    pageContent__withAppBar: {
        height: "calc(100% - 48px)",
        marginTop: 48,
    },

    inputsSearchModule: {
        height: 527,
        background: "url('/svg/main_fs_bg.svg') no-repeat -47px -132px / 837px 861px, #BDF7FF",
        paddingTop: 209,
    },

    inputsSearchModule__form: {
        width: "calc(100% - 30px)",
        margin: "auto",
        borderRadius: 20,
        boxShadow: "0px 2px 8px 0px #00203329",
        padding: 25,
        background: "#fff",
    },

    districtsPseudoSelect: {
        width: "100%",
        height: 44,
        borderRadius: 8,
        border: "1px solid #3F556A47",
        display: "flex",
        padding: "0 10px",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 14,
    },

    districtsPseudoSelect__checked: {
        //fontWeight: 600,
        color: "#002033!important"
    },

    districtsPseudoSelect__status: {
        width: "calc(100% - 35px)",
        textAlign: "left",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: 15*onePx+"rem",
        padding: "0 15px",
        color: "#10202F59",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },

    inputsSearchModule__pseudoInputDates: {
        width: "100%",
        height: 44,
        borderRadius: 8,
        border: "1px solid #3F556A47",
        display: "flex",
        padding: "0 10px",
        //justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 14,
    },

    inputsSearchModule__pseudoInputDatesCheckIn: {
        width: "calc(50% - 19px)",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: 15,
        "& span": {
            color: "#10202F59",
            marginRight: 5,
        }
    },

    inputsSearchModule__pseudoInputDatesCheckOut: {
        width: "calc(50% - 19px)",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: 15,
        "& span": {
            color: "#10202F59",
            marginRight: 5,
        }
    },

    inputsSearchModule__guestsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 10,
        marginBottom: 14,
    },

    inputsSearchModule__inputs: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 16,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 10px 0px",
        padding: "10px 20px",
        borderRadius: 50,
        border: "1px solid #ebebeb",
    },


    inputsSearchModule__dates: {
        fontSize: 14*onePx+"rem",
        color: "rgb(106, 106, 106)",
    },

    inputsSearchModule__guests: {
        display: "flex",
        alignItems: "center",
        "& .MuiButtonBase-root": {
            color: "#2F2E36",
            background: '#31435312',
            borderRadius: 8,
            width: 40,
            height: 40,
        }
    },

    inputsSearchModule__guestsLabel: {
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            marginRight: 9,
        }
    },

    inputsSearchModule__guestsCount: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: 17*onePx+"rem",
        color: "#2F2E36",
        width: 60,
        textAlign: "center",
    },

    ///

    appBar: {
        width: "100%",
        height: 48,
        background: "#fff",
        //alignItems: "center",
        //justifyContent: "center",
        boxShadow: "0px 2px 8px 0px #00203329",
        position: "relative",
        //position: "fixed",
        //top: 0,
        //left: 0,
        //right: 0,
        zIndex: 10,
    },

    toolbar: {
        height: 48,
        flexDirection: "column",
    },

    toolbarBtn: {
      position: "absolute",
      top: 8,
      right: 16,
    },

    toolbarAccountLogo: {
        position: "absolute",
        top: 0,
        right: 2,
    },

    toolbarAccountLogoActive: {
        color: "#00B2FF",
    },

    toolbarSearchLogo: {
        position: "absolute",
        top: 0,
        left: 2,
    },

    mainToolbarWrapper: {
        flexDirection: "column",
        height: 48,
        minHeight: 48,
    },
    mainToolbar: {
        display: "flex",
        minHeight: 48,
        height: 48,
        width: "100%",
        padding: 0,
        //justifyContent: "space-between",
        justifyContent: "center",
        maxWidth: 700,
        margin: "auto",
        position: "relative",
    },

    toolbarLogo: {
        width: 133,
        display: "flex",
        alignItems: "center",
        "& img": {
            width: "100%",
        }
    },

    appBarWithCatalog: {
        height: 95,
    },

    toolbarWithCatalog: {
        minHeight: 100,
        height: 100,
    },

    ///


    wrapperWithAppBar: {
        background: "#fff",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 48,
        left: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },

    centerFlexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    loadingWrapper: {
        position: "fixed",
        width: "100%!important",
        height: "100%!important",
        left: 0,
        top: 0,
        display: "flex!important",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },



    appContentwrapper: {
        overflow: "auto",
        width: "100%",
        flex: 1,
        height: "calc(100% - 50px)",
    },

    appContent: {
        maxWidth: 720,
        margin: "auto",
        background: "#fff",
        height: "calc(100% - 48px)",
        //minHeight: "100%",
        "& .MuiTabPanel-root": {
            padding: 0,
            height: "100%",
            position: "relative",
        }
    },
    appContentWithBottomPanel: {
        height: "calc(100% - 30px)",
        minHeight: "auto",
        overflowY: "auto",
    },
    appContentWithBottomPanel2: {
        height: "100%",
        minHeight: "auto",
        overflowY: "auto",
    },

    scrollBox: {
        overflow: "auto",
        width: "100%",
        height: "calc(100% - 56px)",
        padding: "0 16px",
        background: "#ebe9e9",
        maxWidth: 720,
        margin: "auto",
        "& .infinite-scroll-component ": {
            paddingBottom: 54,
        }
    },

    aoInput: {
        "& .base-Input-input": {
            borderRadius: 8,
            border: "1px solid rgba(63, 85, 106, 0.28)",
            padding: 12,
            color: "#002033",
            fontSize: 15*onePx+"rem",
            fontWeight: 400,
            width: "100%",
        },
        "& .MuiInput-input": {
            borderRadius: 8,
            border: "1px solid rgba(63, 85, 106, 0.28)",
            padding: 12,
            color: "#002033",
            fontSize: 15*onePx+"rem",
            fontWeight: 400,
            width: "100%",
        },
        //"&::focus-visible": {
        //    color: "rgba(16, 32, 47, 0.35)",
        //},
        "&::placeholder": {
            color: "rgba(16, 32, 47, 0.35)",
        },
    },
    drawerWrapper: {
        height: "100%",
    },
    drawerFrame: {
        width: "100%",
        maxWidth: 720,
        margin: "0 auto",
        height: "100%",
        background: "#fff",
    },
    fullPageDrawer: {
        "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
        },
    },

    drawer: {
        "& .MuiDrawer-paper": {
            borderRadius: "15px 15px 0 0",
            paddingTop: 25,
            background: "#fff",
            "&::before": {
                transition: "opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                content: '""',
                position: "absolute",
                width: 36,
                height: 3,
                top: "10px",
                opacity: 1,
                left: "calc(50% - 18px)",
                background: "#d1d1d1",
                zIndex: 2,
            },
        },
    },
    drawerNotSw: {
        "& .MuiDrawer-paper": {
            borderRadius: "15px 15px 0 0",
            paddingTop: 25,
            background: "#fff",
        },
    },
    drawerNotSwCloseBtn: {
        position: "absolute",
        top: 7,
        right: 16,
        "color": "#006BE9",
        fontSize: "0.8rem",
    },
    drawerH30: {
        "& .MuiDrawer-paper": {
            height: "30%",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerH50: {
        "& .MuiDrawer-paper": {
            height: "50%",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerH75: {
        "& .MuiDrawer-paper": {
            height: "75%",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerSt: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerFs: {
        "& .MuiDrawer-paper": {
            height: "100%",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerFsGallery: {
        "& .MuiDrawer-paper": {
            height: "100%",
            borderRadius: 0,
            background: "#292929",
            overflow: "hidden",
        },
    },
    drawerNotAnim: {
        "& .MuiDrawer-paper": {
            height: "100%",
            overflow: "hidden",
        },
    },

    drawerUnderActive2Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            transform: "scale(0.92, 1.0)!important",
            overflow: "hidden",
            "&::before": {
                opacity: "0!important",
            }
        },
    },

    drawerUnderActive3Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 47px)",
            transform: "scale(0.92, 1.0) translate(0px, -17px)!important",
            overflow: "hidden",
            "&::before": {
                opacity: "0!important",
            }
        },
    },

    drawerNotVisible: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            overflow: "hidden",
            transform: "scale(0.86, 1.0) translate(0px, 17px)!important",
        },
    },

    drawerSt2Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 47px)",
            overflow: "hidden",
            maxWidth: 700,
            margin: "auto",
        },
    },
    drawerCatalogReCardHeader: {
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
        boxShadow: "0px 2px 6px rgba(166, 166, 166, 0.25)",
        background: "#fff",
        position: "relative",
        zIndex: 10,
    },
    drawerCatalogRemovedReHeader: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        paddingRight: 40,
        fontSize: 17,
        fontWeight: 600,
        color: "#2F2E36",
    },
    drawerCloseBtn: {
        color: "#657780",
    },

    bottomDrawerBtnsWrapper: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 2,
        padding: "16px 15px",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
        backdropFilter: "blur(10px)",
        display: "flex",
        justifyContent: "center",
        "& .MuiButton-root": {
            width: "100%",
            maxWidth: 500,
        }
    },
    drawerActionButtons: {
        "& .MuiButtonBase-root": {
            marginLeft: 8,
        }
    },
    bottomDrawerBody: {
        height: "calc(100% - 48px)",
        overflowY: "auto",
    },
    bottomDrawerBodyWithBottomBtns: {
        height: "calc(100% - 37px)",
        overflowY: "auto",
        paddingBottom: 77,
    },

    drawerFSContentWrapper: {
        width: "100%",
        height: "calc(100% - 48px)",
        //overflowY: "auto",
    },

    pageScrollWrapper: {
        width: "100%",
        height: "calc(100% - 48px)",
        overflowY: "auto",
    },

    ///


    circularWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    //
    contentWrapper: {
        height: "calc(100% - 48px)",
        //marginTop: 48,
        width: "100%",
        overflow: "hidden",
        position: "relative",
        //overflowY: "auto",
    },
    contentScrollWrapper: {
        height: "calc(100% - 48px)",
        //marginTop: 48,
        width: "100%",
        overflow: "hidden",
        position: "relative",
        overflowY: "auto",
    },
    fullSizeScrollWrapper: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        overflowY: "auto",
    },
    contentWrapper__text: {
        padding: 15,
        lineHeight: "1.2em",
        color: '#2F2E36',
        fontSize: 16*onePx+"rem",
        fontFamily: 'Montserrat',
        fontWeight: '400',
        "& p": {
          marginBottom: 10,
        },
        "& b": {
            fontWeight: 600,
        },
        "& h1": {
            marginTop: 0,
            marginBottom: 27,
            fontSize: 17*onePx+"rem",
            lineHeight: "1.2em",
        },
        "& h2": {
            marginTop: 0,
            marginBottom: 20,
            fontSize: 15*onePx+"rem",
            lineHeight: "1.2em",
        }
    },
    circularProgressWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        bottom: 20,
        left: 0
    },

    // Drawer

    bottomDrawerHeader: {
        position: "relative",
        fontSize: 17*onePx+"rem",
        fontWeight: 600,
        height: 37,
        "& p": {
            textAlign: "center",
            margin: 0,
        }
    },

    bottomDrawerRemoveBtn: {
        position: "absolute",
        top: -1,
        left: 16,
        color: "#EB3333",
        textTransform: "inherit",
        padding: 0,
    },

    bottomDrawerCloseBtn: {
        position: "absolute",
        top: -1,
        right: 16,
        background: "rgba(49, 67, 83, 0.07)",
        color: "rgba(39, 53, 67, 0.8)",
    },

    bottomDrawerBottomPanel: {
        "left": "0",
        "right": "0",
        "bottom": "0",
        "zIndex": "10",
        "position": "absolute",
        "borderTop": "1px solid #f2f2f2",
        "background": "#fff",
        padding: "16px 15px",
    },

    galleryDrawerCloseBtn: {
        position: "absolute",
        top: 16,
        right: 16,
        background: "#000",
        color: "#fff",
        zIndex: 2,
    },

    rangeCalendarDatesFilterWrapper: {
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100% - 93px)",
    },

    rangeCalendarDatesFilterBottomPanel: {
        "left": "0",
        "right": "0",
        "bottom": "0",
        "zIndex": "10",
        "position": "absolute",
        "borderTop": "1px solid #f2f2f2",
        "background": "#fff",
        padding: "16px 15px",
    },


    mainCoverWrapper: {
        width: "100%",
        "& img": {
            width: "100%",
        }
    },

    filterBadge: {
        "& .MuiBadge-badge": {
            background: "none",
            right: -4,
            color: "#00B2FF",
            top: 3,
        },
        "& .MuiSvgIcon-root": {
            //fontSize: "1rem",
            color: "rgba(16, 32, 47, 0.35)",
        }
    },

    imageGalleryWrapper: {
        "& .image-gallery-thumbnails-wrapper": {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            background: "#292929",
        },
        "& .image-gallery-slide-wrapper": {
            position: "fixed",
            top: 0,
            height: "calc(100% - 87px)",
            display: "flex",
            alignItems: "center",
        }
    },

    numberGuestsWrapper: {
        marginBottom: 16,
        width: "100%",
        "& > span": {
            color: '#2F2E36',
            fontSize: 15,
            fontWeight: 400,
            marginRight: 13,
        },
        "& .MuiButtonBase-root": {
            background: 'rgba(49.38, 66.52, 82.87, 0.07)',
            borderRadius: 8,
            width: 32,
            height: 32,
        }
    },
    numberGuestsValue: {
        color: '#2F2E36',
        fontSize: 17,
        fontWeight: 600,
        margin: "0 16px",
    },
    datesFilterBtn: {
        minWidth: 45,
        padding: "2px 4px",
        marginLeft: 8,
        marginRight: 8,
        "& .MuiButton-startIcon": {
            marginRight: 2,
        },
        "& i": {
            width: 14,
            height: 14,
            background: "#4db050",
            borderRadius: "50%",
            fontSize: 10,
            color: "#fff",
            fontStyle: "normal",
            lineHeight: "14px",
            fontWeight: 600,
            marginLeft: 5,
        },
    },
    reCategorySwitchWrapper: {
        width: "100%",
        padding: "0 16px 11px",
        maxWidth: 500,
        position: "relative",
    },
    segmentControl: {
        width: "100%",
        fontWeight: 400,
        "& .MuiButtonBase-root": {
            borderRadius: 8,
            height: 32,
            color: "rgba(0, 32, 51, 1)",
            flex: "1 1 auto",
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: 400,
        },
        "& .Mui-selected": {
            color: "#fff!important",
            background: "rgba(76, 175, 80, 1)!important",
            borderRadius: "8px!important",
        },
        "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            marginLeft: "-7px!important",
        },

    },

    infiniteScrollLoading: {
        width: "100%",
        textAlign: "center",
        padding: 16,
    },
    squareBtn: {
        width: 32,
        height: 32,
        background: "#fff",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    greenOutlinedBtn: {
        color: "#4CAF50",
        borderRadius: 8,
        fontWeight: 600,
    },
    microInputSelect: {
        margin: "15px 0",
        "& .MuiInputBase-root": {
            color: "#657780",
            fontSize: 13*onePx+"rem",
        },
        "& .MuiSelect-select": {
            padding: "0 20px 0 0!important",
        },
        "& fieldset": {
            display: "none",
        },
        "& .MuiSvgIcon-root": {
            marginRight: -9,
        }
    },
    actionSheetShareWrapper: {
        "width": "100%",
        "padding": "20px",
        "background": "#fff!important",
        "fontWeight": "600",
        "borderRadius": "10px",
        "marginBottom": "5px"
    },
    actionSheet: {
        "& .MuiDrawer-paper": {
            borderRadius: "15px 15px 0 0",
            paddingTop: 25,
            width: "calc(100% - 10px)",
            margin: "auto",
            background: "none",
        },
        "& .MuiButtonGroup-root": {
            width: "100%",
            background: "#fff",
            borderRadius: 10,
        }
    },

    actionSheetLoading: {
        "width": "100%",
        "background": "#fff",
        "borderRadius": "8px",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "padding": "15px"
    },

    actionSheetWrapperCancelBtn: {
        width: "100%!important",
        background: "#fff!important",
        marginTop: "5px!important",
        marginBottom: "10px!important",
        borderRadius: "10px!important",
        fontWeight: 600,
        padding: "12px 11px!important",
    },

    actionSheetWrapper: {
        height: "100%",
        "& .MuiButtonBase-root": {
            borderColor: "#ebe9e9b3!important",
            padding: "12px 11px",
        },
        "& > div": {
            background: "none",
        }
    },
    lightbox: {
        "& .imageModal": {
            display: "flex",
            justifyContent: "flex-end"
        }
    },

    findBtn: {
        background: "linear-gradient(90deg, #E6A300 0%, #FF5722 100%)",
        fontFamily: "Inter, sans-serif",
        textTransform: "none",
    },

    mainTopBanner: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 209,
        "& .slick-dots": {
            bottom: 27,
        },
        "& .slick-active button:before": {
            fontSize: 14,
            color: "#fff!important",
            opacity: "1!important",
        },
        "& li button:before": {
            color: "#fff",
            opacity: "1!important",
        },
    },

    mainTopBanner__slider: {
        width: "100%",
        height: 209,

    },

    mainTopBanner__slide: {
        fontFamily: "Montserrat, sans-serif",
        width: "100%",
        height: 209,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 27*onePx+"rem",
        fontWeight: 700,
        color: "#fff",
        textAlign: "center",
        textTransform: "uppercase",
        lineHeight: "1.2em",
        "& h1": {
            fontSize: 27*onePx+"rem",
        },
        "& h2": {
            fontSize: 27*onePx+"rem",
        },
    },
    drawerDistrictsLineCheckbox: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: 17,
        margin: 0,
        width: "100%",
        borderBottom: "1px solid #3F556A33",
        color: "#002033",
    },
    largeBtn: {
        fontWeight: 600,
        fontSize: 17,
        height: 48,
        borderRadius: 8,
        textTransform: "none",
    },
    mediumBtn: {
        fontWeight: 600,
        fontSize: 15,
        height: 32,
        borderRadius: 8,
        textTransform: "none",
        letterSpacing: "-0.5px",
    },
    ///

    houseMiniItemWrapper: {

    },

    houseMiniItemImage: {

    },

    houseMiniItemNotImage: {

    },

    houseMiniItemContent: {

    },

    houseMiniItemContentWithOutContact: {

    },

    houseMiniItemContentHeader: {

    },

    textBlock: {
        "& p": {
            marginBottom: 24,
        },
        "& b": {
            fontWeight: 600,
        }
    },

    ///

    changeGuestsCount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "15px"
    },

    changeGuestsCount__label: {
        color: '#2F2E36',
        fontSize: 15,
        fontFamily: 'Inter',
        fontWeight: '400',
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            marginRight: 9,
            color: "#00B2FF",
        }
    },

    changeGuestsCount__guests: {
        display: "flex",
        alignItems: "center",
        "& .MuiButtonBase-root": {
            color: "#2F2E36",
            background: '#31435312',
            borderRadius: 8,
            width: 40,
            height: 40,
        }
    },

    changeGuestsCount__guestsLabel: {
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            marginRight: 9,
        }
    },

    changeGuestsCount__guestsCount: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: 17*onePx+"rem",
        color: "#2F2E36",
        width: 60,
        textAlign: "center",
    },

}));
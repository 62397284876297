import moment from "moment";
import CatalogService from "../../services/catalog.service";
import React, {useContext, useEffect, useState} from "react";
import InfiniteScrollH from "../Catalog/InfiniteScrollH";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import RowTpl from "./HouseMicroRow.jsx";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import {UserContext} from "../../providers/UserProvider";
import {useNavigate} from "react-router";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import HouseNamesService from "../../services/house-names.service.js";



const NUM_ROWS = 10;

const PopularHouses = (props) => {

    const {
        backUrl,
        filter,
        onViewHouse,
    } = props;

    const classes = useStyles();
    const gClasses = useGStyles();
    const {user, setUser} = useContext(UserContext);


    //глобальный state
    const {
        pubConfig,
        backdrop, setBackdrop,
        toast, setToast,
    } = useContext(GlobalStateContext);

    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState( []);

    const fetchMoreDataH = async ({hasMore, setHasMore, data, setData, reload, limit}) => {

        let hasMoreTmp = hasMore;
        let skip;

        if(reload){
            setHasMore(true);
            hasMoreTmp = true;
            setData([]);
            skip = 0;
        }else{
            skip = data.length;
        }

        //console.log("fetchMoreDataH Start! skip",skip);

        if (!hasMoreTmp) return false;

        //console.log("fetchMoreDataH houseId: "+houseId+" загружаю элементы skip="+skip);
        //console.log("fetchMoreDataH dateRequest", moment(dateRequest).format("DD.MM.YY HH:mm:ss"));

        //добавляем глобальный фильтр
        //сортировка обязательно в конце, чтобы фильтр не затирал ее
        //dateRequest - на основании fetchMoreData (при загрузке страницы)
        //const result = await useMethod("methods.runPubAPIMethod", "catalog/get-house-re-items", user, {houseId, skip, limit: (limit || NUM_ROWS), ...filter, sort: "publishedAt", dateRequest});
        //TODO - требует проверки
        const result = await HouseNamesService.getPopular({skip, limit: (limit || NUM_ROWS), filter});

        if (!(result && result.status) ) {
            //console.log("fetchMoreDataH Ошибка загрузки данных");
            setHasMore(false);
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка загрузки данных!"});
            return true;
        }

        if( !(result.houses && result.houses.length) ){
            setHasMore(false);
            return true;
        }

        if(reload){
            setData(result.houses);
        }else{
            //если необходимо использовать другую data
            setData( data.concat(result.houses) );
        }

        return true;
    }

    useEffect(() => {

        fetchMoreDataH({hasMore, setHasMore, data, setData, limit: 5, reload: true});

    },[]);

    return (
        <>

            <InfiniteScrollH
                wrapperClass={classes.popularHousesWrapper}
                next={() => fetchMoreDataH({hasMore, setHasMore, data, setData, limit: 5})}
                hasMore={hasMore}
                //onScroll={() => {console.log("scroll")}}
                loader={<div className={classes.houseMiniItemLoading}><CircularProgress/></div>}
                endMessage={<div className={classes.houseMiniItemLoading}><span>Все объекты загружены</span></div>}
            >
                {data && data.map((item, index) => (
                    <RowTpl
                        backUrl={backUrl}
                        classes={classes}
                        key={item._id}
                        user={user}
                        onViewHouse={onViewHouse}
                        house={item}
                        pubConfig={pubConfig}
                        filter={filter}
                    />
                ))}
            </InfiniteScrollH>

        </>
    )

}

export default React.memo((props) => {
    return (
        <PopularHouses {...props} />
    );
},(b, a) => {
    return true;
});
import React, {Component, Fragment, useState, useEffect, useRef, useCallback} from 'react';
import clsx from "clsx";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Input } from '@mui/base';
import useStyles from "./styles";
import {FormControlLabel} from "@mui/material";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Popper from '@mui/material/Popper';
import HouseNamesService from "../../services/house-names.service.js"
import useGStyles from "../../assets/global-styles";
import {ArrowDownIcon, PinIcon} from "../../assets/svg-icons";
import {getTextDistricts} from "../../utils/functions.js";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/material/SvgIcon/SvgIcon";
import {replObjInArr} from "../../utils/functions";
import DrawerDistricts from "../Main/DrawerDistricts";

export default (props) => {

    const {
        filterState,
        onFilter,
        forbidden = {},
        popHouses = [],
        user,
        onOpenDistrictFilterClick,
    } = props;

    const [houseNamesData, setHouseNamesData] = useState([]);

    const classes = useStyles();
    const gClasses = useGStyles();

    const onChangeChipMultiselect = (fieldName, value, values, setFieldValue) => {
        setFieldValue(fieldName, (values[fieldName].indexOf(value) > -1?values[fieldName].filter(v => v != value):values[fieldName].concat(value)));
    };

    const onSearchFilterHouseName = async (event, value, reason) => {

        //если это не ввод текста - выходим
        if(!event || event && event.type != "change"){
            setHouseNamesData([]);
            return;
        }

        const searchText = event.target.value;
        if(!searchText) {
            setHouseNamesData([]);
            return;
        }

        //const result = await runAPIMethod("house-names/find", user, {searchText: searchText, limit: 3});
        //TODO - требует проверки
        const result = await HouseNamesService.find({searchText: searchText, limit: 3});

        const data = (!(searchText && result && result.status && result.houseNames ) ) ? [] : result.houseNames;
        setHouseNamesData(data);

    };

    const onChangePopHouseName = (house, setFieldValue) => {
        setFieldValue("houseName", house);
    }

    const popperWidth = window.innerWidth - 30;

    return (
        <div className={classes.bottomDrawerBody}>
            <div className={classes.drawerFilterWrapper}>
                <Formik
                    initialValues={{
                        houseName: filterState.houseName,
                        type: filterState.type,
                        priceFrom: filterState.priceFrom || "",
                        priceTo: filterState.priceTo || "",
                        areaFrom: filterState.areaFrom || "",
                        areaTo: filterState.areaTo || "",
                        floorFrom: filterState.floorFrom || "",
                        floorTo: filterState.floorTo || "",
                        viewRemoved: filterState.viewRemoved || false,
                        publishedInCatalog: filterState.publishedInCatalog || false,
                        furnish: filterState.furnish || [],
                        paymentOptions: filterState.paymentOptions || [],
                        view: filterState.view || [],
                        contract: filterState.contract || [],
                        rooms: filterState.rooms || [],
                        platform: filterState.platform || [],
                        sort: (!forbidden.sort && (filterState.sort || "sort_by_date") ) || "",
                        status: filterState.status || [],
                        bookServiceApartaments: filterState.bookServiceApartaments || false,
                        districts: filterState?.dates?.districts || [],
                    }}
                    validate={async (values) => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => onFilter(values, setSubmitting)}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off" style={{overflow: "hidden"}}>

                            {!forbidden.districts &&

                                <div className={clsx(classes.filterItem, (values.districts?.length) ? classes.filterItemActive : null)}>
                                    <p>Районы</p>
                                    <div className={gClasses.districtsPseudoSelect} onClick={() => onOpenDistrictFilterClick(values.districts, setFieldValue)}>

                                        <PinIcon style={{fontSize: 19, color: "#56B9F2"}}/>

                                        <div className={clsx(gClasses.districtsPseudoSelect__status, (values.districts.length ? gClasses.districtsPseudoSelect__checked : null))}>
                                            {getTextDistricts(values.districts)}
                                        </div>

                                        <ArrowDownIcon style={{fontSize: 16}}/>

                                    </div>
                                </div>
                            }

                            {!forbidden.type &&
                                <div className={clsx(classes.filterItem, classes.filterItemActive)}>
                                    <p>Тип объекта</p>
                                    <div className={classes.filterItemStack}>
                                        <Chip onClick={() => setFieldValue("type", "apartments")} className={clsx(classes.filterChip, (values.type == "apartments"?classes.filterChipActive:null))} label="Квартиры" />
                                        <Chip onClick={() => setFieldValue("type", "house")} className={clsx(classes.filterChip, (values.type == "house"?classes.filterChipActive:null))} label="Дома" />
                                        <Chip onClick={() => setFieldValue("type", "commerce")} className={clsx(classes.filterChip, (values.type == "commerce"?classes.filterChipActive:null))} label="Коммерция" />
                                    </div>
                                </div>
                            }

                            {!forbidden.houseName &&

                            <div className={clsx(classes.filterItem, (values.houseName) ? classes.filterItemActive : null)}>

                                <Autocomplete
                                    //при вводе получаем с сервера значения в формате [{title: "..", value: "..", ...}]
                                    onInputChange={(e) => onSearchFilterHouseName(e)}
                                    onChange={(event, options, reason) => {
                                        setHouseNamesData([]);
                                        setFieldValue("houseName", options || "");
                                    }}
                                    size="small"
                                    fullWidth
                                    className={clsx(classes.formAutocompleate, null)}
                                    //style={{width: "calc(100% - 115px)", marginRight: 5, marginBottom: 0}}
                                    //при загрузке получаем с сервера значения в формате {title: "..", value: "..", ...}
                                    //выбранное значения храним в state values в формате: {title: "..", value: "..", ...}
                                    value={values.houseName || null}
                                    onBlur={handleBlur}
                                    options={houseNamesData || []}
                                    name="houseName"
                                    noOptionsText="Введите ЖК"
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Жилой комплекс" margin="normal" autoComplete="none"/>
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper {...props} className={clsx(props.className)} style={{...props.style, width: popperWidth}}>
                                            {props.children}
                                        </Popper>
                                    )}
                                />

                                <div className={clsx(classes.popHouseNameWrapper, classes.mb0)}>
                                    {
                                        !!popHouses?.length &&
                                        <>
                                            <p>Выбрать среди популярных:</p>
                                            {!!(popHouses?.length) && popHouses.map(h => (
                                                <Chip onClick={() => onChangePopHouseName(h, setFieldValue)} variant={(values.houseName?.value && values.houseName.value == h.id?"filled":"outlined")} className={classes.chipMini} key={h.id} label={h.title} />
                                            ))}
                                        </>
                                    }

                                </div>

                            </div>

                            }

                            {!forbidden.price &&
                            <div className={clsx(classes.filterItem, ((values.priceFrom != "" || values.priceTo != "") ? classes.filterItemActive : null))}>
                                <p>Цена</p>
                                <div style={{display: "flex"}}>
                                    <Input
                                        name="priceFrom"
                                        size="small"
                                        placeholder="От"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.priceFrom}
                                        //helperText={touched.priceFrom && errors.priceFrom}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)", marginRight: 16}}
                                    />

                                    <Input
                                        name="priceTo"
                                        placeholder="До"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.priceTo}
                                        //helperText={touched.priceTo && errors.priceTo}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)"}}
                                    />

                                </div>
                            </div>
                            }

                            <div className={clsx(classes.filterItem, ( (values.areaFrom != "" || values.areaTo != "")?classes.filterItemActive:null))}>
                                <p>Площадь</p>
                                <div style={{display: "flex"}}>
                                    <Input
                                        name="areaFrom"
                                        size="small"
                                        placeholder="От"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.areaFrom}
                                        //helperText={touched.priceFrom && errors.priceFrom}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)", marginRight: 16}}
                                    />

                                    <Input
                                        name="areaTo"
                                        placeholder="До"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.areaTo}
                                        //helperText={touched.priceTo && errors.priceTo}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)"}}
                                    />

                                </div>
                            </div>

                            <div className={clsx(classes.filterItem, ( (values.floorFrom != "" || values.floorTo != "")?classes.filterItemActive:null))}>
                                <p>Этаж</p>
                                <div style={{display: "flex"}}>
                                    <Input
                                        name="floorFrom"
                                        size="small"
                                        placeholder="От"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.floorFrom}
                                        //helperText={touched.priceFrom && errors.priceFrom}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)", marginRight: 16}}
                                    />

                                    <Input
                                        name="floorTo"
                                        placeholder="До"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.floorTo}
                                        //helperText={touched.priceTo && errors.priceTo}
                                        className={gClasses.aoInput}
                                        style={{width: "calc(50% - 8px)"}}
                                    />

                                </div>
                            </div>

                            <div className={clsx(classes.filterItem, (values.rooms.length?classes.filterItemActive:null) )}>
                                <p>Планировка</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip onClick={() => onChangeChipMultiselect("rooms", "fa_reoptions_1", values, setFieldValue)} className={clsx(classes.filterChip, (values.rooms.indexOf("fa_reoptions_1") > -1?classes.filterChipActive:null))} label="Студия" />
                                    <Chip onClick={() => onChangeChipMultiselect("rooms", "fa_reoptions_2", values, setFieldValue)} className={clsx(classes.filterChip, (values.rooms.indexOf("fa_reoptions_2") > -1?classes.filterChipActive:null))} label="1-к" />
                                    <Chip onClick={() => onChangeChipMultiselect("rooms", "fa_reoptions_4", values, setFieldValue)} className={clsx(classes.filterChip, (values.rooms.indexOf("fa_reoptions_4") > -1?classes.filterChipActive:null))} label="2-к" />
                                    <Chip onClick={() => onChangeChipMultiselect("rooms", "fa_reoptions_5", values, setFieldValue)} className={clsx(classes.filterChip, (values.rooms.indexOf("fa_reoptions_5") > -1?classes.filterChipActive:null))} label="3-к" />
                                    <Chip onClick={() => onChangeChipMultiselect("rooms", "fa_reoptions_6", values, setFieldValue)} className={clsx(classes.filterChip, (values.rooms.indexOf("fa_reoptions_6") > -1?classes.filterChipActive:null))} label="4-к" />
                                </Stack>
                            </div>

                            {!forbidden.furnish &&
                            <div
                                className={clsx(classes.filterItem, (values.furnish.length ? classes.filterItemActive : null))}>
                                <p>Отделка</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("furnish", "renovated", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.furnish.indexOf("renovated") > -1 ? classes.filterChipActive : null))}
                                        label="Ремонт"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("furnish", "fine_finish", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.furnish.indexOf("fine_finish") > -1 ? classes.filterChipActive : null))}
                                        label="Чистовая"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("furnish", "without_renovation", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.furnish.indexOf("without_renovation") > -1 ? classes.filterChipActive : null))}
                                        label="Черновая"/>
                                </Stack>
                            </div>
                            }

                            {!forbidden.paymentOptions &&
                            <div
                                className={clsx(classes.filterItem, (values.paymentOptions.length ? classes.filterItemActive : null))}>
                                <p>Варианты оплаты</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("paymentOptions", "mortgage", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.paymentOptions.indexOf("mortgage") > -1 ? classes.filterChipActive : null))}
                                        label="Ипотека"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("paymentOptions", "full_amount", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.paymentOptions.indexOf("full_amount") > -1 ? classes.filterChipActive : null))}
                                        label="Полная сумма"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("paymentOptions", "cash", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.paymentOptions.indexOf("cash") > -1 ? classes.filterChipActive : null))}
                                        label="Наличные"/>
                                </Stack>
                            </div>
                            }

                            <div className={clsx(classes.filterItem, (values.view.length?classes.filterItemActive:null))}>
                                <p>Вид</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip onClick={() => onChangeChipMultiselect("view", "to_the_yard", values, setFieldValue)} className={clsx(classes.filterChip, (values.view.indexOf("to_the_yard") > -1?classes.filterChipActive:null))} label="Во двор" />
                                    <Chip onClick={() => onChangeChipMultiselect("view", "on_the_sea", values, setFieldValue)} className={clsx(classes.filterChip, (values.view.indexOf("on_the_sea") > -1?classes.filterChipActive:null))} label="На море" />
                                    <Chip onClick={() => onChangeChipMultiselect("view", "to_the_park", values, setFieldValue)} className={clsx(classes.filterChip, (values.view.indexOf("to_the_park") > -1?classes.filterChipActive:null))} label="На парк" />
                                    <Chip onClick={() => onChangeChipMultiselect("view", "to_the_mountains", values, setFieldValue)} className={clsx(classes.filterChip, (values.view.indexOf("to_the_mountains") > -1?classes.filterChipActive:null))} label="На горы" />
                                </Stack>
                            </div>

                            {!forbidden.contract &&
                            <div
                                className={clsx(classes.filterItem, (values.contract.length ? classes.filterItemActive : null))}>
                                <p>Оформление</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("contract", "contract_of_sale", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.contract.indexOf("contract_of_sale") > -1 ? classes.filterChipActive : null))}
                                        label="ДКП"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("contract", "assignment_of_rights", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.contract.indexOf("assignment_of_rights") > -1 ? classes.filterChipActive : null))}
                                        label="Переуступка ФЗ-214"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("contract", "preliminary_agreement", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.contract.indexOf("preliminary_agreement") > -1 ? classes.filterChipActive : null))}
                                        label="Пред. договор"/>
                                    <Chip
                                        onClick={() => onChangeChipMultiselect("contract", "other", values, setFieldValue)}
                                        className={clsx(classes.filterChip, (values.contract.indexOf("other") > -1 ? classes.filterChipActive : null))}
                                        label="Другое"/>
                                </Stack>
                            </div>
                            }

                            {!forbidden.platform &&
                            <div className={clsx(classes.filterItem, (values.paymentOptions.length?classes.filterItemActive:null))}>
                                <p>Площадка</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip onClick={() => onChangeChipMultiselect("platform", "Avito", values, setFieldValue)} className={clsx(classes.filterChip, (values.platform.indexOf("Avito") > -1?classes.filterChipActive:null))} label="Авито" />
                                    <Chip onClick={() => onChangeChipMultiselect("platform", "Cian", values, setFieldValue)} className={clsx(classes.filterChip, (values.platform.indexOf("Cian") > -1?classes.filterChipActive:null))} label="Циан" />
                                    <Chip onClick={() => onChangeChipMultiselect("platform", "DomClick", values, setFieldValue)} className={clsx(classes.filterChip, (values.platform.indexOf("DomClick") > -1?classes.filterChipActive:null))} label="Дом.Клик" />
                                    <Chip onClick={() => onChangeChipMultiselect("platform", "Other", values, setFieldValue)} className={clsx(classes.filterChip, (values.platform.indexOf("Other") > -1?classes.filterChipActive:null))} label="Другая" />
                                </Stack>
                            </div>
                            }

                            {!forbidden.status &&
                            <div className={clsx(classes.filterItem, (values.paymentOptions.length?classes.filterItemActive:null))}>
                                <p>Статус объявления</p>
                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                    <Chip onClick={() => onChangeChipMultiselect("status", "published", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("published") > -1?classes.filterChipActive:null))} label="Опубликовано" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "not_published", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("not_published") > -1?classes.filterChipActive:null))} label="Снято" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "check", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("check") > -1?classes.filterChipActive:null))} label="На доработке" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "moderation", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("moderation") > -1?classes.filterChipActive:null))} label="Модерация" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "rejected", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("rejected") > -1?classes.filterChipActive:null))} label="Отклонено" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "blocked", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("blocked") > -1?classes.filterChipActive:null))} label="Заблокировано" />
                                    <Chip onClick={() => onChangeChipMultiselect("status", "loading", values, setFieldValue)} className={clsx(classes.filterChip, (values.status.indexOf("loading") > -1?classes.filterChipActive:null))} label="Публикация" />
                                </Stack>
                            </div>
                            }

                            {!forbidden.bookServiceApartaments &&
                                <div className={clsx(classes.filterItem, (values.bookServiceApartaments?classes.filterItemActive:null))}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={values.bookServiceApartaments}
                                                value={values.bookServiceApartaments}
                                                name="bookServiceApartaments"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        }
                                        color="primary"
                                        label="Только объекты размещения сервиса Бронирую!"
                                    />
                                </div>
                            }


                            {!forbidden.publishedInCatalog &&
                            <div className={clsx(classes.filterItem, (values.publishedInCatalog?classes.filterItemActive:null))}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={values.publishedInCatalog}
                                            value={values.publishedInCatalog}
                                            name="publishedInCatalog"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    }
                                    color="primary"
                                    label="Опубликованы в каталоге «агент»"
                                />
                            </div>
                            }

                            {!forbidden.viewRemoved &&
                            <div className={clsx(classes.filterItem, (values.viewRemoved?classes.filterItemActive:null))}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={values.viewRemoved}
                                            value={values.viewRemoved}
                                            name="viewRemoved"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    }
                                    color="primary"
                                    label="Показать объекты в архиве"
                                />
                            </div>
                            }

                            <div className={classes.drawerBtnsWrapper}>
                                <Button
                                    color="blue"
                                    fullWidth
                                    size="large"
                                    className={gClasses.largeBtn}
                                    onClick={handleSubmit}
                                    variant="contained"
                                >
                                    Применить
                                </Button>
                            </div>

                        </form>
                    )}

                </Formik>
            </div>
        </div>
    )

}
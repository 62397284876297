import React, {useCallback, useContext, useEffect, useState} from "react";
import useStyles from "./styles";
import {priceToTriads, getTextDays, getTextUserRole} from "../../utils/functions";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {NoPhotoIcon, AOFullIcon} from "../../assets/svg-icons.js";
import clsx from "clsx";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import SendIcon from '@mui/icons-material/Send';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Button from '@mui/material/Button';

import Fade from '@mui/material/Fade';
import _ from "lodash";
import moment from 'moment';
import {getReHeader} from "../../utils/functions";
import Comments from "./Comments.jsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";


const phoneToTriads = (phone) => {
    return phone.toString().replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d+)/, (...match)=> match[1]+"("+match[2]+")"+match[3]+"-"+match[4]+"-"+match[5])
}

const ImageWithProgressWithMemo = React.memo(({src, classes, uid, onViewImageGallery}) => {
    const [loading, setLoading] = useState(true);
    //console.log("re-render ImageWithProgressWithMemo");
    return (
        <div className={clsx(classes.imageReCardWithSpinnerWrapper, "imageReCardWithSpinnerWrapper")}>
            <img
                src={src}
                onLoad={() => {setLoading(false)}}
                style={{display: (loading ? "none" : "block")}}
                onClick={() => onViewImageGallery(uid)}
            />
            <Fade
                in={loading}
                style={{transitionDelay: loading ? '800ms' : '0ms'}}
                unmountOnExit
            >
                <CircularProgress/>
            </Fade>
        </div>
    );
},(b, a) => {return true;});

const getTitleByValue = (values, value) => {
    return values.find(v => v.value == value)?.title || "?";
}

const defaultPlatforms = [
    {key: "Catalog", name: "Каталог"},
    {key: "Avito", name: "Авито"},
    {key: "Cian", name: "ЦИАН"},
    {key: "DomClick", name: "Дом.Клик"},
    {key: "Other", name: "Другая"}
];

const defaultFurnish = [
    {value: "renovated", title: "Ремонт"},
    {value: "fine_finish", title: "Чистовая"},
    {value: "without_renovation", title: "Черновая"},
];

const defaultPaymentOptions = [
    {value: "mortgage", title: "Ипотека"},
    {value: "cash", title: "Наличные"},
    {value: "full_amount", title: "Полная сумма"},
];

const defaultContract = [
    {value: "contract_of_sale", title: "ДКП"},
    {value: "assignment_of_rights", title: "Переуступка ФЗ-214"},
    {value: "preliminary_agreement", title: "Пред. договор"},
    {value: "other", title: "Другое"},
];

const defaultView = [
    {value: "to_the_yard", title: "Во двор"},
    {value: "on_the_sea", title: "На море"},
    {value: "to_the_park", title: "На парк"},
    {value: "to_the_mountains", title: "На горы"},
];


export default React.memo((props) => {

    const {
        activeRe,
        user,
        showEditReBtn,
        viewHouseInMap,
        //если просмотр объекта из подборки
        collectionId,
        onViewImageGallery,
        filterState,
        styles,
        showBreadCrumbs,
    } = props;

    //глобальный state
    const {
        pubConfig,
        toast, setToast,
    } = useContext(GlobalStateContext);

    //console.log("re-render view-re-card-tpl (withMemo)");

    const viewAccess = !!(user && activeRe.userId != user._id && activeRe.viewAccess?.find(u => u.value == user._id));

    const classes = useStyles();

    if(!activeRe) return <p>Объект не найден!</p>;


    const {
        houseName,
        files,
        name,
        price,
        //minPrice,
        text,
        collections,
        lettersName,
        pricePeriod,
        //pricePeriodAv,
        pricePeriodCount,
        reContact,
        rejectAutoPublish,
    } = activeRe;

    const pricePeriodAv = Math.ceil(pricePeriod/pricePeriodCount);
    const reUser = activeRe.user;

    const address = activeRe.address || houseName?.address;

    const fullAccess = !!(user && activeRe.userId != user._id && activeRe.fullAccess?.find(u => u == user._id));
    const isMyRe = (reUser && user?._id == reUser.value) || fullAccess;

    const collectionPrice = collectionId && collections && collections.find(c => c.id == collectionId)?.price  || null;


    return (
        <div className={classes.catalogReCard} style={styles || {}}>

            {
                showBreadCrumbs && <BreadCrumbs stack={[{name: getReHeader(activeRe, true)}]} style={{marginBottom: 20}}/>
            }

            <div className={clsx(classes.catalogReCardImagesWrapper, (files && files.length == 1?classes.imageReCardOnePhoto:null), (activeRe.removed?classes.imageReCardRemoved:null))}>
                {
                    (files && files.length) ?
                        files.map(thumb => (<ImageWithProgressWithMemo classes={classes} onViewImageGallery={onViewImageGallery} uid={thumb.uid} key={thumb.uid} src={pubConfig.image_url+"/"+(thumb?.images && (thumb.images.thumbS?.url || thumb.images.s?.url || thumb.images.m?.url))}/>))
                        :
                        <div className={classes.noReCardPhoto}><NoPhotoIcon className={classes.noReCardPhotoIcon}/></div>
                }
            </div>

            <div className={classes.catalogReCardMainInfo}>

                <div className={classes.catalogReCardHeader}>
                    {name? name : getReHeader(activeRe, true)}
                </div>

                {!!((collectionPrice || price) && !pricePeriod) &&
                <div className={classes.catalogReCardPriceItem}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span className={clsx(classes.catalogReCardPrice, classes.catalogReCardPriceValue)}>{"от "+priceToTriads(price)+" ₽ / сутки"}</span>
                    </div>
                </div>
                }

                {!!pricePeriod &&
                <>
                    <div className={classes.catalogReCardPriceItem}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span className={clsx(classes.catalogReCardPrice, classes.catalogReCardPriceValue)}>{priceToTriads(pricePeriod)} ₽ за {getTextDays(pricePeriodCount, "дни")+", "+filterState?.dates?.guestsCount+" гостей"}</span>
                        </div>
                    </div>
                    <div className={classes.catalogReCardPriceItem}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span className={clsx(classes.catalogReCardAddPrice, classes.catalogReCardPriceValue)}>{priceToTriads( Math.round(+pricePeriodAv))} ₽ / сутки</span>
                        </div>
                    </div>
                </>
                }

            </div>

            {!!(houseName || address) &&
            <div className={classes.catalogReCardHouseName}>

                <div className={clsx(classes.catalogReCardHouseNameImage)}>
                    {activeRe.houseCover ? <img src={pubConfig.image_url + "/" + activeRe.houseCover.images.thumb.url}/> : <div className={classes.noPhotoHouseName}><NoPhotoIcon className={classes.noPhotoIcon}/></div>}
                </div>

                <div className={classes.catalogReCardHouseNameContent}>

                    {!!houseName &&
                    <div className={classes.catalogReCardHouseNameHeader}>
                        <p>{houseName && houseName.title || "?"}{lettersName?", корп. "+lettersName:""}</p>
                        <ExpandMoreIcon fontSize="small"/>
                    </div>
                    }

                    {!!address &&
                    <div className={classes.catalogReCardHouseNameAddress}>
                        <LocationOnIcon fontSize="small"/>
                        <p>{address}</p>
                    </div>
                    }

                    <p className={classes.catalogReCardHouseNameViewInMap} onClick={() => viewHouseInMap()}>Показать на карте</p>
                </div>

            </div>
            }

            {!!(activeRe.furnish || activeRe.contract || activeRe.guestsCount || activeRe.paymentOptions && activeRe.paymentOptions.length || activeRe.view && activeRe.view.length) &&
            <div className={classes.catalogReCardInfoOptions}>

                {!!(activeRe.view && activeRe.view.length) &&
                <div className={classes.houseInfoOptionsRow}>
                    <div><span>Вид</span></div>
                    <div>{activeRe.view.map(i => getTitleByValue(defaultView, i)).join(", ")}</div>
                </div>
                }

                {!!activeRe.guestsCount &&
                <div className={classes.houseInfoOptionsRow}>
                    <div><span>Вместимость</span></div>
                    <div>До {activeRe.guestsCount} гостей</div>
                </div>
                }


            </div>
            }

            {!!text &&
            <div className={classes.catalogReCardText}>
                <p className={classes.catalogReCardTextHeader}>Описание</p>
                <div className={classes.catalogReCardTextContent}>
                    {text}
                </div>
            </div>
            }

            {!!(isMyRe) &&
                <div className={classes.catalogReCardBtnsWrapper}>
                    <Button
                        className={classes.openInAOBtn}
                        style={{width: "100%", marginRight: 8}}
                        color="secondary"
                        endIcon={<AOFullIcon/>}
                        variant="contained"
                        onClick={() => window.open(pubConfig.ao_url+"/re/"+activeRe.numberId, "_blank")}
                    >
                        Открыть объект в CRM
                    </Button>
                </div>
            }

            {!!(reUser && reContact && showEditReBtn) &&
            <div className={classes.reContactMiniCard}>

                <div className={classes.reContactMiniCardContentWrapper}>
                    {(reContact && reContact.avatar?.xs?.url)?
                        <Avatar sx={{ width: 64, height: 64 }} src={pubConfig.image_url+"/"+reUser.avatar.xs.url}></Avatar>
                        :
                        <Avatar sx={{ width: 64, height: 64 }}>{reContact.name && reContact.name[0] || "?"}</Avatar>
                    }

                    <div className={classes.agentMiniCardContent}>
                        <p className={classes.agentMiniCardName}>{reContact.name}</p>
                        <p className={classes.agentMiniCardPhone}>+{phoneToTriads(reContact.phone)}</p>
                        <p className={classes.agentMiniCardReContactName}>Собственник недвижимости</p>
                    </div>
                </div>

                <div className={classes.reContactMiniCardContacts}>
                    <Button startIcon={<SendIcon />} color="secondary" variant="contained" onClick={() =>  window.open("https://wa.me/"+reContact.phone, "_blank")}>Написать</Button>
                    <Button startIcon={<LocalPhoneIcon />} variant="contained" onClick={() =>  window.open("tel:+"+reContact.phone, '_self')}>Позвонить</Button>
                </div>

            </div>
            }


            {!!(reUser && !showEditReBtn) &&
            <div className={classes.agentMiniCardBox}>

                <div className={classes.agentMiniCardContentWrapper}>
                    {(reUser && reUser.avatar?.xs?.url)?
                        <Avatar
                            sx={{ width: 64, height: 64 }}
                            src={pubConfig.image_url+"/"+reUser.avatar.xs.url}
                        >
                        </Avatar>
                        :
                        <Avatar
                            sx={{ width: 64, height: 64 }}
                        >
                            {reUser.title && reUser.title[0] || "?"}
                        </Avatar>
                    }

                    <div className={classes.agentMiniCardContent}>
                        <p className={classes.agentMiniCardName}>{reUser.title}</p>
                        <p className={classes.agentMiniCardAgency}>{getTextUserRole(reUser.roles || [])}</p>
                    </div>
                </div>

                <div className={classes.agentMiniCardContactsBox}>
                    <Button startIcon={<SendIcon />} color="secondary" variant="contained" onClick={() =>  window.open("https://wa.me/"+reUser.phone, "_blank")}>Написать</Button>
                    <Button startIcon={<LocalPhoneIcon />} variant="contained" onClick={() =>  window.open("tel:+"+reUser.phone, '_self')}>Позвонить</Button>
                </div>

            </div>
            }

            {
                !!(user) &&
                <Comments
                    user={props.user}
                    classes={classes}
                    activeRe={activeRe}
                    setToast={setToast}
                />
            }

        </div>
    )

},(b, a) => {
    if(a.activeRe != b.activeRe) return false;
    return true;
});
import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import { removeToken } from "../../api/tokens.api";
import { UserContext } from "../../providers/UserProvider";
import { useUserRedirect } from "../../utils/useUserRedirect";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {useNavigate} from "react-router";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/material/SvgIcon/SvgIcon";
import Grid from "@mui/material/Grid";
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';

const User = () => {

    const classes = useStyles();
    const gClasses = useGStyles();
    const { user, setUser } = useContext(UserContext);

    //глобальный state
    const {
    toast, setToast,
    } = useContext(GlobalStateContext);

    const nav = useNavigate();

    //если пользователь не авторизован редирект в "/auth/login"
    useUserRedirect();
    if(!user) return null;


    const onClickLogout = () => {
    setUser(null);
    removeToken();
    };


    return (
        <>

          <AppBar position="static" className={clsx(gClasses.appBar)}>

              <Toolbar className={gClasses.mainToolbarWrapper}>

                  <div className={gClasses.mainToolbar} style={{justifyContent: "center"}}>

                      <div/>

                      <div className={gClasses.toolbarLogo}>
                          <img src="/svg/logo.svg" onClick={() => {nav("/")}}/>
                      </div>

                      {
                          /*
                            <Button  className={gClasses.toolbarBtn} size="small"  variant="text" endIcon={<LogoutIcon />}>
                            Выход
                            </Button>
                           */
                      }

                      <IconButton
                          className={clsx(gClasses.toolbarAccountLogo, gClasses.toolbarAccountLogoActive)}
                          size="large"
                          onClick={() => onClickLogout()}
                      >
                          <LogoutIcon />
                      </IconButton>

                  </div>

              </Toolbar>

          </AppBar>

          <div className={gClasses.contentWrapper}>

              <Grid
                  style={{height: "100%"}}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
              >
                  Привет, {user?.name}!

                  <div style={{padding: 15}}>
                      <Button
                          className={gClasses.mediumBtn}
                          fullWidth
                          variant="contained"
                          onClick={() => {nav("/map/?guestscount=2");}}
                          startIcon={<SearchIcon/>}
                          color="red"
                      >
                          К поиску жилья
                      </Button>
                  </div>


              </Grid>

          </div>

        </>
    );
};

export default User;

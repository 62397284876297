import React, {Component, Fragment, useState, useEffect, useRef} from 'react';
import useStyles from "./styles.js";
import clsx from "clsx";
import moment from 'moment';
import _ from "lodash";
import useGStyles from "../../assets/global-styles.js";
import {randomStr} from "../../utils/functions.js";

let scrollTop = 0;

const getMonthName = (month) => {
    let monthName;
    switch (+month) {
        case 1:
            monthName = "январь";
            break;
        case 2:
            monthName = "февраль";
            break;
        case 3:
            monthName = "март";
            break;
        case 4:
            monthName = "апрель";
            break;
        case 5:
            monthName = "май";
            break;
        case 6:
            monthName = "июнь";
            break;
        case 7:
            monthName = "июль";
            break;
        case 8:
            monthName = "август";
            break;
        case 9:
            monthName = "сентябрь";
            break;
        case 10:
            monthName = "октябрь";
            break;
        case 11:
            monthName = "ноябрь";
            break;
        case 12:
            monthName = "декабрь";
            break;
    }

    return monthName;
}

export const CalendarBuffer = (props) => {

    const classes = useStyles();

    const dataCount = [];

    for (let i = 0; i < props.buffer; i++) {
        dataCount.push(i);
    }

    return (
        dataCount.map(index => (
            <div
                className={(index+1==props.buffer?clsx(classes.calendarBufferWrapper,classes.calendarBufferWrapper__last):classes.calendarBufferWrapper)}
                key={index}
            ></div>
        ))
    )


}

//показывает только свободные и занятые дни
export const CalendarItem = (props) => {

    const classes = useStyles();

    const {day, activeDay, disabledDay, bookingDay, isLastMonthDay, cellId, dayPrice, styles = {}, inlineStyle, isPastDay} = props;

    const first = bookingDay.pm && bookingDay.pm.first;
    const last = bookingDay.am && bookingDay.am.last;
    const oneDay = bookingDay.oneDay;
    const penult = bookingDay.penult;
    //let am = bookingDay && bookingDay.date && bookingDay.am && bookingDay.am.id && !(bookingDay.pm && bookingDay.pm.id);
    let pm = bookingDay && bookingDay.date && bookingDay.pm && bookingDay.pm.id && !(bookingDay.am && bookingDay.am.id);
    let allDay = bookingDay && bookingDay.date && bookingDay.pm && bookingDay.pm.id && bookingDay.am && bookingDay.am.id && bookingDay.am.id == bookingDay.pm.id;

    const freeDay = !bookingDay && !disabledDay;
    const disabledPMRange = bookingDay.pm && bookingDay.pm.type && bookingDay.pm.type == "disabled-range";

    let styleTextDay = {};

    //определяем занытые дни
    if( ( allDay && !bookingDay.pm.pastBooking || pm && !bookingDay.pm.pastBooking || oneDay && !bookingDay.pm.pastBooking || last && first && !bookingDay.pm.pastBooking) || disabledPMRange || isPastDay ){
        styleTextDay =  { textDecoration: "line-through", color: "#ff8989", fontWeight: 100};
    }

    return (
        <div
            style={styles}
            className={
                clsx(
                    "calendarItem",
                    classes.calendarItem,
                    (inlineStyle?classes.calendarItem__inline:null),
                    (disabledDay?classes.calendarItem__disabled:null),
                    (isLastMonthDay?classes.calendarItem__lastMonthDay:null),
                    (first && !oneDay?classes.calendarItem__first:null),
                    (penult && !oneDay && !isLastMonthDay?classes.calendarItem__penult:null),
                    (last?classes.calendarItem__last:null),
                    (oneDay ?classes.calendarItem__oneDay:null),
                    (freeDay?classes.calendarItem__freeDay:null),
                )}
        >

            {!!day &&
                <span className="calendarItemTextDay" style={styleTextDay}>
                    {day}
                </span>
            }

            {!!(!bookingDay.pm?.id && dayPrice && dayPrice.price && !isPastDay) &&
            <span className="calendarItemTextPrice">
                    <i>{(dayPrice.customDate?"•":"")}</i>{dayPrice.price} ₽
                </span>
            }

            {
                activeDay?
                    <span className="calendarItemLayerActiveDay"/>
                    :
                    null
            }

        </div>

    )

}

export const MonthCalendar = (props) => {

    //console.log("re-render MonthCalendar");

    const classes = useStyles();

    const {
        month,
        days,
        year,
        buffer,
        bufferAfter,
        activeMonth,
        bookingDays,
        selectedBookingDays,
        dayPrices,
        top,
        monthId,
        calendarHeight,
    } = props;


    const daysArr = [];

    for (let i = 0; i < days; i++) {
        daysArr.push(i+1);
    }

    const monthName = getMonthName(month);

    const activeDay = activeMonth && +moment().format("DD");
    const startOfDay = moment().startOf("day").toDate();

    return (

        <div id={"monthId_"+monthId} className={classes.calendarMonthWrapper} style={{top: top}}>
            {
                activeMonth?
                    <div id="activeMonth" className={classes.calendarActiveMonth} />
                    :
                    null
            }

            <div className={classes.calendarMonth}>
                <span>{monthName+" "+props.year+" г."}</span>
            </div>

            <div className={classes.calendarHeader}>
                {
                    ["П", "В", "С", "Ч", "П", "С", "В"].map(day=>{
                        return (
                            <div key={randomStr(8)}>{day}</div>
                        )
                    })
                }
            </div>

            <div className={classes.calendarDaysWrapper} style={{height: calendarHeight}}>

                <CalendarBuffer buffer={buffer}/>

                {
                    daysArr.map(day => {

                        const isActiveDay = activeDay && activeDay == day;
                        const cellId = day+"-"+month+"-"+year;
                        const bookingDay = {
                            ...bookingDays[cellId] || null,
                            ...selectedBookingDays[cellId] || null,
                        };
                        const isPastDay = moment(cellId, "D-MM-YYYY").isBefore(startOfDay);

                        return (
                            <CalendarItem
                                bookingDay={bookingDay}
                                disabledDay={!isActiveDay}
                                activeDay={isActiveDay}
                                key={cellId+"_"+randomStr(8)}
                                cellId={cellId}
                                day={day}
                                isLastMonthDay={(daysArr.length == day)?true:false}
                                dayPrice={dayPrices && dayPrices[cellId] || null}
                                isPastDay={isPastDay}
                            />
                        )
                    })
                }

                <CalendarBuffer buffer={bufferAfter}/>

            </div>

        </div>

    )

}

const generateMonths = (beforeMonths, afterMonths) => {

    let startCalendarDate = moment().subtract(beforeMonths,'months').startOf('month');

    let monthsTmp = [];
    let heightPosition = 0;
    const clientWidth = document.documentElement.clientWidth > 720?720:document.documentElement.clientWidth;
    const dayWidth = clientWidth / 7;

    // первоначальное заполнение массива месяцев календаря

    //console.log("generateMonths: [beforeMonths, afterMonths]", [beforeMonths, afterMonths]);

    //console.log("заполнение массива месяцев от >", moment(startCalendarDate).format("MM.YY"));
    //console.log("заполнение массива месяцев до >", moment(startCalendarDate).add((beforeMonths+afterMonths),'month').format("MM.YY"));

    for (let i = 0; i < (beforeMonths+1+afterMonths); i++) {

        const date = moment(startCalendarDate).add(i,'month');
        const monthId = moment(date).format("MM.YY");
        const month = moment(date).format("MM");
        const year = moment(date).format("YYYY");

        let buffer = moment(date).isoWeekday();
        buffer--;

        const days = moment(date).daysInMonth();
        const cells = Math.ceil((buffer+days)/7)*7;
        const calendarHeight = (cells / 7) * dayWidth;
        const top = heightPosition;
        //101px - корректировка высоты заголовка
        const bottom = heightPosition + calendarHeight + 101;
        const rowsCount = cells / 7;
        //корректировка для скроллинга активного месяца
        // +50 - высота с учетом высоты месяцев beforeMonths и высоты кнопки загрузить еще
        if(i == (beforeMonths - 1)) scrollTop = bottom + 50;
        //когда 0 месяцев
        if(beforeMonths == 0 && i == 0 ) scrollTop = 0;

        console.log("scrollTop="+scrollTop);

        const visible = (scrollTop < top)?true:false;

        monthsTmp.push({
            monthId: monthId,
            date: date.toDate(),
            month: month,
            year: year,
            top: top,
            bottom: bottom,
            calendarHeight: calendarHeight,
            visible: visible,
            buffer: buffer,
            cells: cells,
            rowsCount: rowsCount,
            bufferAfter: cells-(buffer+days),
            days: days,
        });

        heightPosition = bottom;

    }

    return monthsTmp;

}

const getMonthsInView = (months, scroll) => {

    const scrollBottom = scroll + document.documentElement.clientHeight;
    return  months.filter(m => m.bottom > (scroll - 400) && m.top < (scrollBottom + 400) );

}

export default (props) => {

    const classes = useStyles();
    const gClasses = useGStyles();

    const {
        afterMonths,
        focusActiveMonth,
        bookingDays,
        dayPrices,
        selectedBookingDays,
        clearSelectedBookingDays,
        busyDaysMode,
    } = props;

    let nowMonthId = moment().format("MM.YY");

    //console.log("re-render Calendar dayPrices=", dayPrices);

    const [months, setMonths] = useState([]);
    const [monthsInView, setMonthsInView] = useState([]);
    const [loading, setLoading] = useState(true);
    const bookingRef = useRef();

    //выполняется при загрузке страницы 1 рвз потом удаляется
    useEffect(() => {

        const monthsTmp = generateMonths(props.beforeMonths, afterMonths);
        setMonths(monthsTmp);
        setMonthsInView(getMonthsInView(monthsTmp, 0));

        //очистка слоя selected
        clearSelectedBookingDays();

        setLoading(false);

    }, []);

    //скролл до активного месяца
    useEffect(() => {
        if(bookingRef && bookingRef.current) bookingRef.current.scrollTop = scrollTop;
    },[loading]);


    const onScrollBookingBox = (event) => {

        const st = event.currentTarget.scrollTop;

        //console.log("onScrollBookingBox scrollTop:", st);
        //console.log("onScrollBookingBox months.length:", months.length);

        const scroll = Math.round((st || 0)/300)*300;

        if(scroll != scrollTop){
            scrollTop = scroll;
            setMonthsInView(getMonthsInView(months, scroll));
        }

    }

    if(loading) return null;

    return (
        <div
            id="booking"
            ref={bookingRef}
            className={clsx(gClasses.height100, gClasses.overflowYAuto, gClasses.overflowXHidden)}
            onScroll={onScrollBookingBox}
        >

            <div className={clsx(classes.calendar, classes.overflowY)} style={{height: months[months.length-1].bottom}}>
                {
                    monthsInView.map(month => {
                        return (
                            <MonthCalendar
                                id={month.monthId}
                                monthId={month.monthId}
                                calendarHeight={month.calendarHeight}
                                top={month.top}
                                bookingDays={bookingDays}
                                dayPrices={dayPrices}
                                selectedBookingDays={selectedBookingDays}
                                activeMonth={(focusActiveMonth && nowMonthId == month.monthId?true:false)}
                                key={month.monthId}
                                rowsCount={month.rowsCount}
                                month={month.month}
                                buffer={month.buffer}
                                bufferAfter={month.bufferAfter}
                                days={month.days}
                                year={month.year}
                                busyDaysMode={busyDaysMode}
                            />
                        )
                    })
                }
            </div>
        </div>
    )

}
import React, {Component, Fragment, useState, useEffect, useRef, useCallback, useContext} from 'react';
import ReactDOM from 'react-dom';
import useStyles from "./styles";
import clsx from "clsx";
import {HomeIcon, NoPhotoIcon} from "../../assets/svg-icons.js";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";


const getMarkerHouseWidth = (length) => {

    if(length <= 10) return 170;
    if(length > 10 && length <= 15) return 180;
    if(length > 15 && length <= 20) return 190;
    if(length > 20 && length <= 25) return 200;
    if(length > 25 && length <= 30) return 210;
    if(length > 30 && length <= 35) return 220;
    if(length > 35 && length < 40) return 230;
    return 250;

}

export default ({mapRef, house, zoom}) => {

    const classes = useStyles();
    const coordinates = [house.geometry.coordinates[1], house.geometry.coordinates[0]];
    const location = {center: coordinates, zoom: +zoom || 14};

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    main();

    async function main() {
        const [ymaps3React] = await Promise.all([
            window.ymaps3.import("@yandex/ymaps3-reactify"),
            window.ymaps3.ready,
        ]);

        const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);

        const {
            YMap,
            YMapDefaultSchemeLayer,
            YMapDefaultFeaturesLayer,
            YMapMarker,
            //YMapListener,
        } = reactify.module(window.ymaps3);

        //рисует локацию 1 элемента с фото
        const YMapElementMarker = (props) => (
            <YMapMarker
                coordinates={coordinates}
            >

                <div className={clsx(classes.mapMarkerHouse)} style={{width: props.w, marginLeft: -(props.w / 2)}}>

                    <div style={{width: "fit-content"}}>
                        <div
                            className={clsx(classes.mapMarkerHouseImage, (!house.cover && classes.mapMarkerHouseImageNotImage || null))}>
                            {house.cover && house.cover?.images?.thumb?.url ? <img src={pubConfig.image_url + "/" + (house.cover.images.thumb.url)}/> : <NoPhotoIcon className={classes.noPhotoIcon}/>}
                        </div>
                    </div>

                    <div className={classes.mapMarkerHouseContent}>
                        <div className={classes.mapMarkerHouseName} lang="ru">{house.name}</div>
                    </div>
                </div>

            </YMapMarker>
        );

        const YandexMap = () => {

            console.log("re-render YMap-view-point");

            const w = getMarkerHouseWidth(house?.name?.length || 0);

            return (

                <YMap location={location} ref={mapRef} className={classes.yandexMap}>

                    <YMapDefaultSchemeLayer />
                    <YMapDefaultFeaturesLayer/>

                    {!!house.housing?.length ?
                        house.housing.map(h => (
                            <YMapMarker
                                key={h.id}
                                coordinates={[h.address?.point?.coordinates[1], h.address?.point?.coordinates[0]]}
                            >
                                <div className={clsx(classes.mapMarkerLetters)}>
                                    {h.name.slice(-5) || "?"}
                                </div>
                            </YMapMarker>
                        ))
                        :
                        <YMapElementMarker w={w}/>
                    }

                </YMap>
            )
        };

        ReactDOM.render(
            <YandexMap />,
            document.getElementById("yandexMapViewPointContainer"),
        );
    }

    return (
        <div
            id="yandexMapViewPointContainer"
            style={{width: "100%", height: "100%"}}
        >
        </div>
    );

}
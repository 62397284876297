import React, {useEffect, useRef, useState} from "react";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import useStyles from "./styles";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import NoteTpl from "../../components/NotesTpl/NotesTpl.jsx";
import ReService from "../../services/re.service.js";


export default (props) => {

    const {
        activeRe,
        user,
        setToast,
    } = props;

    const classes = useStyles();

    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    const commentTextRef = useRef();

    useEffect(() => {

        setLoading(true);

        //TODO - требуется тестирование
        ReService.getComments({reId: activeRe._id}).then((res, err) => {
            const error = (err || !(res && res.status && res.comments) && (res?.error || "Неизвестная ошибка!"));

            setLoading(false);
            if(error){
                setComments([]);
            }else{
                setComments(res.comments);
            }
        });

    },[]);

    const onSendCommentDialog = () => {

        const text = commentTextRef.current.value;

        if(!text) {
            setToast({open: true, severity: "error", text: "Пустое сообщение!"});
            return;
        }

        //TODO - требуется тестирование
        ReService.addComment({reId: activeRe._id, text}).then((res, err) => {
            const error = (err || !(res && res.status && res.comments) && (res?.error || "Неизвестная ошибка!"));
            if(error) return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});

            setComments(res.comments);
            commentTextRef.current.value = "";

        });

    };

    if(loading) return (
        <div className={classes.reCommentsLoading}>
            <CircularProgress/>
        </div>
    );

    return (

        <>

            <div className={classes.comments}>

                <div className={classes.commentsHeader}>
                    <span>Комментарии {!!comments.length && <span className={classes.commentsCount}>({comments.length})</span>}</span>
                </div>

                {
                    comments.map(note => <NoteTpl key={note.id} note={note} isMyComment={note.userId == user._id}/>)
                }

                <TextField
                    inputRef={commentTextRef}
                    className={classes.commentsInput}
                    label="Новый комментарий"
                    type="text"
                    multiline
                    maxRows={3}
                    fullWidth
                    InputProps={{
                        endAdornment:
                            <IconButton onClick={() => onSendCommentDialog()}>
                                <SendIcon/>
                            </IconButton>
                    }}
                />

            </div>

        </>
    )

}
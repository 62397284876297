import React from "react";
import clsx from "clsx";
import {Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import {CheckBoxOutlineIcon, CheckBoxCheckedIcon} from "../../assets/svg-icons.js";

const DrawerDistricts = (props) => {

    const {
        reload,
        defaultDistricts,
        pubConfig,
        onResetDistrictsFilter,
        onCloseDistrictsFilter,
        onSaveDistricts,
    } = props;


    const classes = useStyles();
    const gClasses = useGStyles();

    console.log("re-render DrawerDistricts reload="+reload+", districts="+defaultDistricts.length);

    //получаем все районы из конфига и добавляем checked в зависимости от того какие районы отмечены сейчас
    const districtsTmp = pubConfig.districts.sochi.map(d => ({...d, checked: defaultDistricts?.map(d => d.code).indexOf(d.code) > -1 }));

    const [districts, setDistricts] = React.useState(districtsTmp);

    const onChangeDistrict = (event, code) => {

        let tmpDistricts;
        //если отмечен район
        if(event.target.checked){
            tmpDistricts = districts.map(d => ({...d, checked: (d.code == code?true:d.checked) }));
        }else{
            tmpDistricts = districts.map(d => ({...d, checked: (d.code == code?false:d.checked) }));
        }

        setDistricts(tmpDistricts);

    }

    return (
        <>

            <div className={clsx(gClasses.bottomDrawerHeader)}>

                <Button variant="text" className={gClasses.bottomDrawerRemoveBtn} onClick={onResetDistrictsFilter}>Сброс</Button>

                <p>Выбрать районы</p>

                <IconButton size="small" onClick={onCloseDistrictsFilter} className={gClasses.bottomDrawerCloseBtn}>
                    <CloseIcon fontSize="inherit"/>
                </IconButton>

            </div>

            <div>
                {districts.map(d => (
                    <FormControlLabel
                        className={gClasses.drawerDistrictsLineCheckbox}
                        key={d.code}
                        control={
                            <Checkbox
                                icon={<CheckBoxOutlineIcon/>}
                                checkedIcon={<CheckBoxCheckedIcon/>}
                                checked={d.checked}
                                onChange={(event) => onChangeDistrict(event, d.code)}
                            />
                        }
                        label={d.name}
                    />
                ))}
            </div>

            <div className={gClasses.bottomDrawerBottomPanel}>
                <Button
                    color="blue"
                    fullWidth
                    size="large"
                    className={gClasses.largeBtn}
                    onClick={() => onSaveDistricts(districts.filter(d => d.checked))}
                    variant="contained"
                >
                    Применить
                </Button>
            </div>

        </>
    )


}

export default React.memo((props) => {
    return (
        <DrawerDistricts {...props} />
    );
},(b, a) => {

    if(
        a.defaultDistricts != b.defaultDistricts ||
        a.reload != b.reload
    ) return false;
    return true;

});
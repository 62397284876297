import moment from "moment";
import CatalogService from "../../services/catalog.service";
import React, {useContext, useEffect, useState} from "react";
import InfiniteScrollH from "../Catalog/InfiniteScrollH";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import RowTpl from "../Catalog/RowReItemMedium.jsx";
import useStyles from "./styles.js";
import useGStyles from "../../assets/global-styles";
import {UserContext} from "../../providers/UserProvider";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";


let dateRequest;
const NUM_ROWS = 10;

const BookServiceRe = (props) => {

    const {
        backUrl,
        filter,
        onViewRe,
    } = props;

    const classes = useStyles();

    const gClasses = useGStyles();
    const {user, setUser} = useContext(UserContext);


    //глобальный state
    const {
        pubConfig,
        backdrop, setBackdrop,
        toast, setToast,
    } = useContext(GlobalStateContext);

    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState( []);

    const fetchMoreDataH = async ({hasMore, setHasMore, data, setData, reload, limit}) => {

        let hasMoreTmp = hasMore;
        let skip;

        if(reload){
            setHasMore(true);
            hasMoreTmp = true;
            setData([]);
            skip = 0;
            dateRequest = new Date();
        }else{
            skip = data.length;
        }

        if (!hasMoreTmp) return false;

        const result = await CatalogService.getBookServiceReItems({skip, limit: (limit || NUM_ROWS), filter, dateRequest});

        if (!(result && result.status) ) {
            //console.log("fetchMoreDataH Ошибка загрузки данных");
            setHasMore(false);
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка загрузки данных!"});
            return true;
        }

        if( !(result.re && result.re.length) ){
            setHasMore(false);
            return true;
        }

        if(reload){
            setData(result.re);
        }else{
            //если необходимо использовать другую data
            setData( data.concat(result.re) );
        }

        return true;
    }

    useEffect(() => {

        fetchMoreDataH({hasMore, setHasMore, data, setData, limit: 5, reload: true});

    },[]);

    return (
        <>

            <InfiniteScrollH
                wrapperClass={classes.catalogHouseReItemsMediumWrapper}
                next={() => fetchMoreDataH({hasMore, setHasMore, data, setData, limit: 5})}
                hasMore={hasMore}
                //onScroll={() => {console.log("scroll")}}
                loader={<div className={classes.catalogMiniItemLoading}><CircularProgress/></div>}
                endMessage={<div className={classes.catalogMiniItemLoading}><span>Все объекты загружены</span></div>}
            >
                {data && data.map((item, index) => (
                    <RowTpl
                        backUrl={backUrl}
                        classes={classes}
                        key={item._id}
                        user={user}
                        onViewRe={onViewRe}
                        re={item}
                        from={"popular-re"}
                        pubConfig={pubConfig}
                    />
                ))}
            </InfiniteScrollH>

        </>
    )

}

export default React.memo((props) => {
    return (
        <BookServiceRe {...props} />
    );
},(b, a) => {
    return true;
});
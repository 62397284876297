import instance from "../api/axios.api";

const HouseNamesService = {

    async find(options) {
        const { data } = await instance.post("/book/houses/find", {...options});
        return data;
    },

    async getOne(options) {
        const { data } = await instance.post("/book/houses/get-one", {...options});
        return data;
    },

    async save(options) {
        const { data } = await instance.post("/book/houses/save", {...options});
        return data;
    },

    async getPopular(options) {
        const { data } = await instance.post("/book/houses/get-popular", {...options});
        return data;
    },


};
export default HouseNamesService;

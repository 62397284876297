import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

export default makeStyles((theme) => ({

    breadCrumbsWrapper: {
        width: "100%",
        display: "flex",
        //padding: "2px 0 27px 0",
        height: 40,
        alignItems: "center",
        padding: "15px 15px 0 15px",
        "& .MuiSvgIcon-root": {
            fontSize: 16*onePx+"rem",
            color: "#657780",
        }
    },

    breadCrumbs__item: {
        display: "flex",
        alignItems: "center",
    },

    breadCrumbs__itemLabel: {
        color: '#657780',
        fontSize: 15*onePx+"rem",
        fontFamily: 'Inter',
        fontWeight: '400',
        maxWidth: "50vw",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },

    breadCrumbs__delimiter: {
        color: "#657780",
    }

}));
import instance from "../api/axios.api";

const ReService = {

    async getComments(options) {
        const { data } = await instance.post("/book/re/get-comments", {...options});
        return data;
    },

    async addComment(options) {
        const { data } = await instance.post("/book/re/add-comment", {...options});
        return data;
    },


};
export default ReService;

import React, {Component, Fragment, useState, useEffect, useRef, useCallback, useContext} from 'react';
//import ReactDOM from 'react-dom';
import * as ReactDOM from 'react-dom';
import {randomStr, timeout} from "../../utils/functions.js";
import useStyles from "./styles";
import clsx from "clsx";
import {NoPhotoIcon, HomeIcon} from "../../assets/svg-icons.js";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { useGeolocated } from "react-geolocated";
import useGStyles from "../../assets/global-styles";
import YandexMapService from "../../services/yandex-map.service";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";


const getMarkerHouseWidth = (length) => {

    if(length <= 10) return 170;
    if(length > 10 && length <= 15) return 180;
    if(length > 15 && length <= 20) return 190;
    if(length > 20 && length <= 25) return 200;
    if(length > 25 && length <= 30) return 210;
    if(length > 30 && length <= 35) return 220;
    if(length > 35 && length < 40) return 230;
    return 250;

}

const showHideData = {};



export default ({user, onViewHouse, filter, onMapUpdate, defaultLocationData, backUrl}) => {

    const classes = useStyles();
    const gClasses = useGStyles();

    main();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const getBboxFromFilter = () => {

        const activeDistrictsCodes = filter?.dates?.districts.filter(d => d.checked).map(d => d.code);
        let districtsCodes = [];

        //если районы не выбраны
        let districts = [];
        if(activeDistrictsCodes.length){
            //берем районы из фильтра
            districts = pubConfig.districts.sochi.filter(d => activeDistrictsCodes.indexOf(d.code) > -1 );
            //берем коды только выбранных районов
            districtsCodes = districts.map(d => d.code);
        }else{
            //берем все районы
            districts = pubConfig.districts.sochi;
            //districtsCodes - оставляем пустым [] = все районы
        }

        //console.log("YMap districts="+districts);

        //вычисляем экстремумы районов
        let minLat = Math.min.apply(null, districts.map(d => d.extremes.lat[0]) ) ;
        let minLong = Math.min.apply(null, districts.map(d => d.extremes.long[0]) );
        let maxLat = Math.max.apply(null, districts.map(d => d.extremes.lat[1]) );
        let maxLong = Math.max.apply(null, districts.map(d => d.extremes.long[1]) );

        const bbox = {
            bottomLeft: {
                lat: minLat,
                lng: minLong,
            },
            topRight: {
                lat: maxLat,
                lng: maxLong,
            }
        }

        const centerLong = minLong + (maxLong - minLong)/2;
        const centerLat = minLat + (maxLat - minLat)/2;

        const center = [centerLong, centerLat];

        //let zoom = pubConfig.maps.defaultZoom;

        let zoom;

        if(districtsCodes.length == 1) zoom = 11;
        if(districtsCodes.length > 1) zoom = 10;
        if(!districtsCodes.length) zoom = 9;

        //границы прямоугольника
        const bounds = [
            //[y, x] = [lat, long]
            //
            //bottomLeft
            [minLat, minLong],
            //topRight
            [maxLat, maxLong],
            //topLeft
            [maxLat, minLong],
            //bottomRight
            [minLat, maxLong],
        ];

        return {
            bbox,
            center,
            zoom,
            bounds,
            districts: districtsCodes,
        };

    }

    async function main() {

        const [ymaps3React] = await Promise.all([
            window.ymaps3.import("@yandex/ymaps3-reactify"),
            window.ymaps3.ready,
        ]);

        const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);

        const {
            YMap,
            YMapDefaultSchemeLayer,
            YMapDefaultFeaturesLayer,
            YMapMarker,
            YMapListener,
        } = reactify.module(window.ymaps3);

        const YandexMap = () => {

            const mapRef = useCallback((node) => {
                //отслеживание состояния загрузки карты
                if(!node) return;
                onUpdate({location: {bounds: node.bounds, center: node.center, zoom: node.zoom}});
            },[]);

            const mapElement = document.getElementById('yandexMapContainer');

            //const mapWidth = mapElement.offsetWidth;
            //const mapHeight = mapElement.offsetHeight;

            //получаем дефолтные данные исходя из фильтра районов
            const defaultLocationData = getBboxFromFilter();

            //const centerAndZoomData = YMap.bounds.getCenterAndZoom(defaultLocationData.bounds, [mapWidth, mapHeight]);

            const [featuresData, setFeaturesData] = useState([]);
            const [zoom, setZoom] = useState(defaultLocationData.zoom);

            const [markerHouseVisible, setMarkerHouseVisible] = useState(true);

            const [locationData, setLocationData] = useState({center: defaultLocationData.center, zoom: defaultLocationData.zoom});

            const showHideMarkerHouse = async (action) => {

                //время ожидания, должно быть больше чем период срабатывания события скрыть/показать - лучше ставить 500-1000ms
                const t = 250;
                const hash = randomStr(8);

                //добавляем в массив событие
                showHideData[action] = {date: new Date(), hash};

                //если событие "скрыть"
                if(markerHouseVisible && action == "hide"){
                    //console.log("скрываю markerHouse");
                    setMarkerHouseVisible(false);
                    return;
                }

                //если событие "показать"
                await timeout(t);

                //если это последнее событие "show" в стеке и его дата позднее чем дата события "hide" то отображаем блок
                if(showHideData?.show?.hash && showHideData?.hide?.date && hash && hash == showHideData.show.hash && moment(showHideData.show.date).isAfter(showHideData.hide.date) ) {
                    //console.log("ПОКАЗЫВАЮ markerHouse");
                    setMarkerHouseVisible(true);
                }

            }

            const onActionStart = (props) => {

                //setZoom(props.location.zoom);

                if(["scrollZoom", "pinchZoom"].indexOf(props.type) > -1 ){
                    //при старте действия скрываем блок
                    showHideMarkerHouse("hide");
                    //console.log("new zoom=",props.location.zoom);
                    setZoom(props.location.zoom);
                }
            }

            const onClusterClick = () => {
                //console.log("onClusterClick");
                //const zoomTmp = zoom+0.5;
                //setZoom(zoom+1);
                //setLocationData({...locationData, zoom: zoomTmp});
            };

            const onUpdate = (props) => {

                const {location, type} = props;
                const {bounds, center, zoom} = location;

                if(["scrollZoom", "pinchZoom"].indexOf(type) > -1 ){
                    showHideMarkerHouse("show");
                    //console.log("zoom=", zoom);
                    setZoom(zoom);
                }

                const data = {
                    bbox: {
                        bottomLeft: {
                            lat: bounds[0][0],
                            lng: bounds[0][1],
                        },
                        topRight: {
                            lat: bounds[1][0],
                            lng: bounds[1][1],
                        }
                    },
                    z: zoom,
                    c: true,
                    filter: {
                        ...filter,
                        dates: {
                            ...filter.dates,
                            //коды районов из фильтра
                            districts: defaultLocationData.districts,
                            type: "bookServiceDistrict"
                        }
                    }
                };

                YandexMapService.getFeatures(data).then((res, err) => {
                    if (!(res) ) return;
                    setFeaturesData(res);
                });

                onMapUpdate({center, zoom});

            };


            useEffect(() => {

                //очистка данных
                return () => {
                    setFeaturesData([]);
                };
            }, []);

            if(!locationData){
                return <div className={gClasses.circularWrapper}><CircularProgress style={{color: "#4caf50"}}/></div>
            }

            //console.log("re-render YMap");

            return (
                <YMap
                    location={locationData}
                    ref={mapRef}
                    className={classes.yandexMap}
                >
                    <YMapDefaultSchemeLayer />
                    <YMapDefaultFeaturesLayer/>

                    {
                        featuresData.map(f => {

                            const viewCard = zoom > pubConfig.maps.viewCardZoom && markerHouseVisible;
                            const w = viewCard?getMarkerHouseWidth(f?.name?.length || 0):0;

                            return (
                                (
                                    <YMapMarker
                                        key={f.id}
                                        coordinates={f.geometry.coordinates}
                                    >
                                        {f.count?
                                            <div onClick={onClusterClick} className={clsx(classes.mapMarkerCluster, classes["mapMarkerClusterSize"+f.countRe.toString().length])}>
                                                {f.countRe}
                                            </div>
                                            :
                                            (
                                                viewCard?
                                                    <div className={clsx(classes.mapMarkerHouse)} style={{width: w, marginLeft: -(w/2)}} onClick={() => onViewHouse(f.id, filter, new Date(), backUrl)}>

                                                        <div style={{width: "fit-content"}}>
                                                            <div className={clsx(classes.mapMarkerHouseImage, (!f.cover && classes.mapMarkerHouseImageNotImage || null))}>
                                                                {f.cover && f.cover?.images?.thumb?.url? <img src={pubConfig.image_url+"/"+(f.cover.images.thumb.url)}/> : <NoPhotoIcon className={classes.noPhotoIcon}/>}
                                                            </div>
                                                        </div>

                                                        <div className={classes.mapMarkerHouseContent}>
                                                            <div className={classes.mapMarkerHouseName} lang="ru">{f.name}</div>

                                                            <div className={classes.mapMarkerHouseStatusWrapper}>
                                                                <div className={classes.mapMarkerHouseStatus}>
                                                                    <HomeIcon sx={{ fontSize: 10, color: "#4CAF50" }} />
                                                                    <span>{f.countRe}</span>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    :
                                                    <div className={clsx(f.countRe > 99?classes.mapMarkerCircleWithCountBig:classes.mapMarkerCircleWithCount)} onClick={() => onViewHouse(f.id, filter, new Date(), backUrl)}>
                                                        {f.countRe}
                                                    </div>
                                            )
                                        }

                                    </YMapMarker>
                                )
                            )
                        })
                    }

                    <YMapListener layerId="any" onActionStart={onActionStart} onActionEnd={(props) => onUpdate(props)}/>

                </YMap>
            )
        };
        /*
        ReactDOM.render(
            <YandexMap />,
            document.getElementById("yandexMapContainer"),
        );
         */

        ReactDOM.render(
            <React.StrictMode>
                <YandexMap />
            </React.StrictMode>,
            document.getElementById('yandexMapContainer')
        );
    }

    return (
        <div id="yandexMapContainer" style={{width: "100%", height: "100%"}}/>
    );

}
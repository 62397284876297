import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    yandexMap: {

    },

    yandexMapPointHousesWrapper: {
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    yandexMapPointHouses: {
        width: "fit-content",
        marginBottom: 10,
        display: "flex",
        alignItems: "center",

    },

    yandexMapWrapper: {
        width: "100%",
        height: "100%",
    },
    yandexMapPointWrapper: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 56px)",
    },
    yandexMapPoint: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 56px)",
    },

    mapMarkerCluster: {
        "background": "#fff",
        "color": "#000",
        "width": "32px",
        "height": "32px",
        "lineHeight": "24px",
        "textAlign": "center",
        "borderRadius": "50%",
        "border": "4px solid #22bd73",
        "fontWeight": "500",
        "fontSize": "0.9rem",
        "marginLeft": -16,
        "marginTop": -16,
    },
    mapMarkerClusterSize3: {
        "width": "40px",
        "height": "40px",
        "lineHeight": "32px",
        "marginLeft": -20,
        "marginTop": -20,
    },
    mapMarkerClusterSize4: {
        "width": "50px",
        "height": "50px",
        "lineHeight": "42px",
        "marginLeft": -21,
        "marginTop": -21,
    },
    mapMarkerClusterSize5: {
        "width": "60px",
        "height": "60px",
        "lineHeight": "52px",
        "marginLeft": -24,
        "marginTop": -24,
    },
    mapMarkerClusterSize6: {
        "width": "70px",
        "height": "70px",
        "lineHeight": "62px",
        "marginLeft": -27,
        "marginTop": -27,
    },
    mapMarkerCircle: {
        "background": "#22bd73",
        "width": "14px",
        "height": "14px",
        "borderRadius": "50%",
        "marginLeft": -7,
        "marginTop": -7,
    },
    mapMarkerCircleWithCount: {
        "background": "#22bd73",
        "width": "24px",
        "height": "24px",
        "borderRadius": "50%",
        "marginLeft": -12,
        "marginTop": -12,
        textAlign: "center",
        fontSize: 12*onePx+"rem",
        lineHeight: "24px",
        color: "#fff",
        fontWeight: 700,
    },
    mapMarkerCircleWithCountBig: {
        "background": "#22bd73",
        "width": "30px",
        "height": "30px",
        "borderRadius": "50%",
        "marginLeft": -15,
        "marginTop": -15,
        textAlign: "center",
        fontSize: 12*onePx+"rem",
        lineHeight: "30px",
        color: "#fff",
        fontWeight: 700,
    },
    mapMarkerCircleCenterAbs: {
        padding: 4,
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 2000,
    },
    mapMarkerHouse: {
        height: 68,
        marginTop: -78,
        position: "relative",
        display: "flex",
        boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        borderRadius: 8,
        background: "#fff",
        padding: 4,
        "&::before": {
            "display": "block",
            "content": '""',
            "width": 10,
            "height": 10,
            position: "absolute",
            left: "calc(50% - 5px)",
            bottom: -15,
            borderRadius: "50%",
            background: "#4CAF50",
        }
    },
    mapMarkerLetters: {
        boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        borderRadius: 34,
        background: "#fff",
        width: 34,
        height: 34,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: -17,
        marginTop: -17,
        border: "3px solid #4CAF50",
        fontSize: 10,
    },
    mapMarkerHouseShow: {
        //display: "block",
        //visibility: "visible",
        //opacity: 1,
    },
    mapMarkerHouseImage: {
        width: 60,
        height: 60,
        marginRight: 5,
        //borderRadius: 6,
        "& img": {
            width: 60,
            height: 60,
            borderRadius: 6,
        }
    },
    mapMarkerHouseImageNotImage: {
        background: "#f5f6fa",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "& svg": {
            fontSize: "2rem",
        }
    },
    mapMarkerHouseContent: {
        padding: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "calc(100% - 65px)",
    },
    mapMarkerHouseName: {
        //minWidth: 150,
        hyphens: "auto",
        fontSize: 13*onePx+"rem",
        fontWeight: 600,
        lineHeight: "18px",
        letterSpacing: -0.078,
        height: 38,
        overflow: "hidden",
    },
    mapMarkerHouseStatusWrapper: {
        display: "flex",
        height: "calc(100% - 38px)",
    },
    mapMarkerHouseStatus: {
        display: "flex",
        alignItems: "center",
        "& span": {
            marginLeft: 2,
            fontWeight: 400,
            fontSize: 12 * onePx + "rem",
        },
        marginRight: 4,
    },

}));
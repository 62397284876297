import React, {Component, Fragment, useState, useEffect, useRef, useCallback} from 'react';
import clsx from "clsx";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from "moment";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {CalendarIcon, GuestsIcon, PinIcon} from "../../assets/svg-icons.js";

export default (props) => {

    const {
        filter,
        onOpenDateFilterClick,
        FilterIconWithMemo,
        onOpenGuestsCountClick,
        onOpenFilterClick,
    } = props;

    const classes = useStyles();
    const gClasses = useGStyles();

    const activeDates = (filter.dates.startDate && filter.dates.endDate && (moment(filter.dates.startDate).format("DD.MM") + " - " + moment(filter.dates.endDate).format("DD.MM")) ) || false;

    return (
        <div className={classes.searchPanelWrapper}>

            <ButtonGroup  variant="contained">
                <Button onClick={onOpenDateFilterClick} className={!!activeDates?classes.searchPanelActiveBtn:null} style={{width: "calc(50% - 28px)"}} startIcon={<CalendarIcon/>}>{activeDates || "Даты"}</Button>
                <Button onClick={onOpenGuestsCountClick} className={classes.searchPanelActiveBtn} style={{width: "calc(50% - 28px)"}} startIcon={<GuestsIcon/>}>{filter.dates.guestsCount} Гостей</Button>
                <Button onClick={onOpenFilterClick} style={{width: 56}}>
                    <FilterIconWithMemo filter={filter}/>
                </Button>
            </ButtonGroup>

        </div>
    )

}
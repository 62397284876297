import { useContext, useEffect } from "react"
import { UserContext } from "../../providers/UserProvider"
import { useNavigate, useLocation } from "react-router-dom"

//при смене user, nav, url - производит редирект в корень /
export const useAuthRedirect = () => {
	const { user } = useContext(UserContext);
	const nav = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		if(user) nav('/profile');
	}, [user, nav, pathname])
};
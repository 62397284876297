import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

const CrmAgent = () => {

    const gClasses = useGStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (
        <TextPageTpl>

            <BreadCrumbs stack={[{name: "CRM-система «агент»"}]} style={{padding: 0, marginBottom: 20}}/>

            <h1>CRM-система для агентов по недвижимости «агент»</h1>

            <p>Информация в данном разделе находится на стадии наполнения, она появится чуть позже.</p>

        </TextPageTpl>
    );
};

export default CrmAgent;

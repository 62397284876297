import clsx from "clsx";
import {AOFavoriteBorderIcon, AOFavoriteIcon, NoPhotoIcon, WarningIcon} from "../../assets/svg-icons.js";
import moment from 'moment';
import {priceToTriads, getReHeader, getTextDate} from "../../utils/functions";
import React from "react";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import IconButton from "@mui/material/IconButton";
import {PinIcon} from "../../assets/svg-icons";
import Rating from "@mui/material/Rating/Rating";

export default (props) => {

    const {
        classes,
        onViewHouse,
        house,
        pubConfig,
        backUrl,
        filter
    } = props;

    const {
        _id,
        cover,
        numberId,
        name,
        address,
        views,
        rating,
    } = house;

    const dateRequest = new Date();

    return (
        <div className={classes.houseMiniItemWrapper}>

            <div className={clsx(classes.houseMiniItemImage, (!cover && classes.houseMiniItemNotImage || null))} >
                {cover ? <img src={pubConfig.image_url + "/" + (cover?.images && (cover.images.thumbXS?.url || cover.images.xs?.url))} onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}/> : <NoPhotoIcon className={classes.noPhotoIcon} onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}/>}
            </div>

            <div className={classes.houseMiniItemContent} onClick={() => onViewHouse(_id, filter, dateRequest, backUrl)}>

                <div className={classes.houseMiniItemName}>
                    {name}
                </div>

                <div className={classes.houseMiniItemAddress}><PinIcon/>{address}</div>

                {!!(rating && rating > 1) &&
                <div className={classes.houseCardInfoRating}>
                    <span className={classes.houseCardInfoRatingVal}>{rating.toFixed(1)}</span>
                    <Rating value={+rating} readOnly size="small" precision={0.5} max={10}/>
                </div>
                }

            </div>

        </div>
    )

}
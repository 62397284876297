import {makeStyles} from "@mui/styles";
import {
    orange,
    grey,
    red,
    deepPurple,
    green,
} from '@mui/material/colors';

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    drawerIncludedReOptions: {
        padding: 15,
    },
    drawerIncludedReText: {
        fontWeight: 400,
        fontSize: 15*onePx+"rem",
        color: "#2F2E36",
    },
    drawerIncludedReOptionsRadioDesc: {
        margin: "-7px 0 24px 31px",
        color: "#657780",
        fontWeight: 400,
        fontSize: 13*onePx+"rem",
    },
    drawerSmallTextDescription: {
        margin: "0 0 20px 0",
        color: "#657780",
        fontWeight: 400,
        fontSize: 13*onePx+"rem",
    },
    catalogContentWrapper: {
        height: "calc(100% - 48px)",
        marginTop: 48,
    },
    catalogContentOwnerWrapper: {
        height: "calc(100% - 48px)",
        marginTop: 48,
    },
    appBarCatalog: {
        width: "100%",
        background: "#fff",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px rgba(166, 166, 166, 0.25)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1,
    },
    catalogSearch: {
        position: 'relative',
        borderRadius: 8,
        background: "none!important",
        marginLeft: 0,
        display: "flex",
        alignItems: "center",
        "& .Mui-disabled": {
            display: "none",
        },
        width: "100%",
        flex: 1,
    },
    catalogSearchWithBtn: {
        //width: "calc(100% - 100px)",
    },
    catalogCloseSearch: {
        color: "#657780",
        fontWeight: 400,
        paddingLeft: 16,
    },
    catalogToolbar: {
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
    },
    catalogYMapsWrapper: {
        height: "100%",
    },
    yandexMapWrapper: {
        width: "100%",
        height: "100%",
    },
    yandexMapPointWrapper: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 56px)",
    },
    yandexMapPoint: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 56px)",
    },
    createHouseNameContentWrapper: {
        padding: "40px 20px 98px 20px",
        height: "calc(100% - 152px)",
        overflowY: "auto",
    },
    editHouseNameWrapper: {
        padding: "15px 15px 77px 15px",
        height: "100%",
        overflowY: "auto",
    },
    createHouseNameWrapper: {
        width: "100%",
        height: "100%",
    },
    scrollBoxY: {
        overflowY: "auto",
    },
    createHouseNameItem: {
        "marginBottom": "10px",
        "display": "flex",
        "alignItems": "center",
        "flexWrap": "wrap",
        "justifyContent": "space-between",
        "& button": {
            float: "rigth",
            marginLeft: 20,
        }
    },
    createHouseNameName: {
        margin: 0,
        fontWeight: 500,
    },
    createHouseNameAddress: {
        margin: 0,
        fontSize: "0.9rem"
    },
    createHouseDesc: {
        //width: "calc(100% - 108px)",
    },
    createHouseNameMap: {
        position: "relative",
        height: "calc(100% - 103px)",
        width: "100%",
        background: grey[300],
    },
    reGroupAddReHeader: {
        "display": "flex",
        "justifyContent": "center",
        "height": "50px",
        "alignItems": "center",
    },
    reGroupAddReTabHeader: {

    },
    addReGroupItemWrapper: {
        "padding": "0px 20px 70px 20px",
        "height": "calc(100% - 120px)",
        "overflowY": "auto",
    },
    catalogHeaderBtns: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "#2F2E36",
        fontWeight: 600,
        fontSize: 17*onePx+"rem",
        position: "relative",
        height: 55,
    },
    catalogToggleViewBtn: {
        //position: "absolute",
        //right: 0,
        //top: 9,
        textTransform: "inherit",
        fontWeight: 400,
        fontSize: 17*onePx+"rem",
        color: "#4CAF50",
        //paddingLeft: 16,
    },
    catalogHeaderSearch: {
        width: "100%",
        display: "flex",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        height: 36,
    },
    catalogSearchBox: {
        position: "fixed",
        top: 58,
        left: 0,
        width: "100%",
        height: "calc(100% - 58px)",
        background: "#fff",
        zIndex: 100,
        padding: "0 16px",
        overflowY: "auto",
    },
    catalogSearchInputRoot: {
        color: 'inherit',
        width: '100%',
        background: "rgba(0, 32, 51, 0.08)",
        borderRadius: 8,
        paddingLeft: 11,
    },
    catalogSearchInputInput: {
        padding: "0",
        width: '100%',
        height: 36,
    },


    ///

    houseCard: {
        overflowY: "auto",
        height: "calc(100% - 48px)",
    },

    scrollBoxHouseCard: {
        overflowY: "auto",
        width: "100%",
        flex: 1,
        height: "100%",
        //
        //height: "calc(100% - 48px)",
        //background: "#fff",
        //padding: "16px 16px 16px 16px",
    },

    scrollBoxHouseCardReItems: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        flex: 1,
        //height: "100%",
        //height: "calc(100% - 50px)",
        //background: "#fff",
        padding: "10px 0",
    },

    houseCardImagesWrapper: {
        position: "relative",
        width: "100%",
    },
    houseCardImages: {
        //minWidth: "100%",
        position: "relative",
        height: 240,
        overflowX: "auto",
        display: "flex",
        alignItems: "center",
        //margin: "10px 0",
        marginLeft: -2,
        paddingLeft: 17,
        paddingRight: 15,
        "& img": {
            //width: 100,
            height: 240,
            //padding: "0 2px",
            borderRadius: 8,
        },

        "&::-webkit-scrollbar": {
            display: "none",
        }
    },

    houseCardWithSpinnerWrapper: {
        minWidth: 320,
        width: 320,
        height: 240,
        margin: "0 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    noHouseCardPhoto: {
        display: "flex",
        background: "#f5f6fa",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        borderRadius: 8,
        height: 240,
        "& svg": {
            fontSize: "7.5rem",
        }
    },

    houseCardInfo: {
        padding: "15px",
    },
    houseCardBottomInfo: {
        width: "100%",
        padding: "15px",
    },
    houseCardInfoHeader: {
        fontWeight: 700,
        fontSize: 24*onePx+"rem",
        lineHeight: "28px",
        letterSpacing: "0.35px",
        color: "#000000",
        marginBottom: 10,
    },
    houseCardStatusWrapper: {
        display: "flex",
        //height: 28,
        //background: "#F4F6F9",
        borderRadius: 8,
        //padding: "6px 7px",
        justifyContent: "space-between",
    },
    houseCardInfoRating: {
        display: "flex",
        alignItems: "center",
        "& .MuiRating-root": {
            color: "#FFE146",
        }
    },
    houseCardInfoRatingVal: {
        color: "rgba(47, 46, 54, 1)",
        fontWeight: 600,
        fontSize: 13*onePx+"rem",
        marginRight: 4,
    },

    floatingMenu: {
        position: "absolute",
        borderTop: "1px solid rgba(0, 32, 51, 0.08)",
        zIndex: 2,
        display: "none",
        width: "100%",
        height: 37,
        alignItems: "center",
        fontWeight: 600,
        fontSize: 16*onePx+"rem",
        background: "#fff",
        padding: "0 15px",
        color: "#2F2E36",
    },

    houseCardReHeader: {
        //color: "rgba(63, 85, 106, 0.3)",
        color: "rgba(74, 81, 87, 0.50)",
        //fontSize: 15*onePx+"rem",
        fontWeight: 700,
        fontSize: 13*onePx+"rem",
        padding: "0 10px",
        textTransform: "uppercase",
        width: "calc(100% - 10px)",
        margin: "0 5px 5px 5px",
    },
    houseCardReHeaderAcive: {
        color: "#2F2E36"
    },
    houseCardReWrapper : {
        "&:first-child": {
            marginTop: 0,
        },
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        //marginTop: 20,
    },
    houseImageBtnWrapper: {
        position: "absolute",
        top: 6,
        left: 23,
        zIndex: 2,
    },
    noReCardPhotoIcon: {
        "fontSize": "7.5rem",
    },

    houseInfoWrapper: {

    },
    houseInfoContent: {
        padding: "0 15px 16px 15px",
    },

    houseInfoText: {
        color: "#657780",
        fontWeight: 400,
        fontSize: 15*onePx+"rem",
        marginBottom: 10,
    },
    houseInfoTags: {
        margin: "0 -5px 10px -5px",
    },
    houseInfoTag: {
        margin: "5px",
        color: "#657780",
        borderRadius: 6,
    },
    houseInfoOptions: {
        marginBottom: 16,
    },
    houseInfoOptionsRow: {
        display: "flex",
        marginBottom: 10,
        justifyContent: "space-between",
        "& div": {
            fontSize: 15*onePx+"rem",
            letterSpacing: "-0.24px",
        },
        "& div:first-child": {
            width: 175,
            marginRight: 10,
            fontWeight: 400,
            color: "#657780",
            position: "relative",
            "& span": {
                background: "#fff",
                zIndex: 2,
                paddingRight: 10,
                position: "relative",
            },
        },

        "& div:first-child::after": {
            content: '""',
            left: 0,
            top: 11,
            height: 1,
            borderBottom: "1px solid rgba(63, 85, 106, 0.28)",
            width: "100%",
            position: "absolute",
        },
        "& div:nth-child(2)": {
            width: "calc(100% - 185px)",
            fontWeight: 500,
            color: "#2F2E36",
        },
    },

    houseSearchItemWrapper: {
        width: "100%",
        height: 45,
        display: "flex",
        alignItems: "center",
        fontWeight: 400,
        fontSize: 15*onePx+"rem",
        color: "#657780",
        borderBottom: "1px solid rgba(0, 32, 51, 0.08)",
    },
    catalogHouseItemIncludedReOptions: {
        position: "absolute",
        left: 15,
        bottom: 15,
        background: "#6252BE",
        borderRadius: 4,
        padding: "2px 6px",
        fontWeight: 600,
        fontSize: 12*onePx+"rem",
        color: "#FAFAFA",
        textTransform: "uppercase",
        zIndex: 2,
    },
    houseCardIncludedReInfo: {
        background: "#F4F6F9",
        borderRadius: 8,
        padding: "16px 18px",
        marginBottom: 15,
    },
    houseCardIncludedReInfoHeader: {
        color: "#2F2E36",
        marginBottom: 10,
        fontWeight: 600,
        fontSize: 16*onePx+"rem",
        "& .MuiSvgIcon-root": {
            marginBottom: -1,
            color: "rgba(16, 32, 47, 0.35)",
            fontSize: "1rem",
            marginRight: 5,
        },
    },
    houseCardIncludedReShowed: {
        color: "#2F2E36",
        marginBottom: 10,
        fontWeight: 400,
        fontSize: 15*onePx+"rem",
        "& .MuiSvgIcon-root": {
            marginBottom: -1,
            color: "#4CAF50",
            fontSize: "1rem",
            marginRight: 5,
        },
    },
    houseCardAddressWrapper: {
        paddingBottom: 15,
    },
    reCardImageBtnWrapper: {
        position: "absolute",
        top: 21,
        left: 23,
        zIndex: 2,
    },
    catalogReCard: {
        height: "100%",
        //height: "calc(100% - 48px)",
        overflowY: "auto",
        position: "relative",
        background: "#fff",
        paddingBottom: 70,
    },
    catalogReCardMainInfo: {
        padding: "10px 16px",
        //borderTop: "1px solid rgba(0, 32, 51, 0.08)",
        //borderBottom: "1px solid rgba(0, 32, 51, 0.08)",
    },
    catalogReCardHeader: {
        fontSize: 20*onePx+"rem",
        fontWeight: 700,
        color: "#006BE9",
    },
    catalogReCardPrice: {
        fontWeight: 700,
        color: "#2F2E36",
    },
    catalogReCardCommission: {
        fontWeight: 600,
        color: "#657780",
    },
    catalogReCardAddPrice: {
        fontWeight: 100,
        color: "#657780",
    },
    catalogReCardOldPrice: {
        fontWeight: 400,
        textDecoration: "line-through",
    },
    catalogReCardPriceItem: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: 10,
    },
    catalogReCardPriceValue: {
        fontSize: 20*onePx+"rem",
        whiteSpace: "nowrap",
        marginRight: 10,
    },
    catalogReCardPriceInfo: {
        width: "100%",
        fontWeight: 400,
        color: "#969CA3",
        fontSize: 14*onePx+"rem",
    },
    catalogReCardPriceM2: {
        fontWeight: 400,
        color: "#969CA3",
        fontSize: 15*onePx+"rem",
        marginRight: 10,
        marginTop: 4,
    },
    catalogReCardImagesWrapper: {
        position: "relative",
        height: 240,
        overflowX: "auto",
        display: "flex",
        alignItems: "center",
        paddingLeft: 17,
        paddingRight: 15,
        marginTop: 15,
        marginLeft: -2,
        "& img": {
            width: "100%",
            height: 240,
            objectFit: "cover",
            //padding: "0 2px",
            borderRadius: 8,
        },

        "&::-webkit-scrollbar": {
            display: "none",
        }
    },
    catalogReCardRemoved: {
        padding: 16,
        fontSize: 18*onePx+"rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: 70,
        width: "100%",
        left: 0,
        top: "calc(50% - 35px)",
        background: "rgb(255 255 255 / 60%)",
        zIndex: 2,
        color: "#EB3333",
        "& svg": {
            marginRight: 5,
        }
    },
    imageReCardRemoved: {
        opacity: 0.5,
    },
    catalogReCardHouseName: {
        margin: "0 16px",
        paddingBottom: 15,
        paddingTop: 15,
        display: "flex",
        borderTop: "1px solid rgba(0, 32, 51, 0.08)",
        //borderBottom: "1px solid rgba(0, 32, 51, 0.08)",
    },

    catalogReCardHouseNameImage: {
        width: 60,
        height: 60,
        borderRadius: 6,
        overflow: "hidden",
        "& svg": {
            fontSize: "1rem",
        },
        "& img": {
            width: "100%",
        }
    },
    catalogReCardHouseNameContent: {
        width: "calc(100% - 60px)",
        marginLeft: 6,
    },
    catalogReCardHouseNameHeader: {
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            color: "#4CAF50",
        },
        "& p": {
            margin: 0,
            fontWeight: 600,
            color: "#000",
        }
    },
    catalogReCardHouseNameAddress: {
        display: "flex",
        "& svg": {
            color: "rgba(16, 32, 47, 0.35)",
        },
        "& p": {
            margin: 0,
            color: "#657780",
            fontWeight: 400,
            fontSize: (1/16*15)+"rem",
        }
    },
    catalogReCardHouseNameViewInMap: {
        margin: 0,
        color: "#4CAF50!important",
        fontWeight: 400,
    },
    catalogReCardViewHouseInMapWrapper: {
        width: "100%",
        height: "100%",
    },
    adornmentIcon: {
        padding: 0,
    },
    noPhotoIcon: {
        "fontSize": "2rem",
    },
    catalogReCardPlatforms: {
        padding: "20px 15px 10px 15px",
        marginTop: 0,
        borderTop: "1px solid rgba(0, 32, 51, 0.08)",
        borderBottom: "1px solid rgba(0, 32, 51, 0.08)",
    },
    reContactMiniCard: {
        width: "calc(100% - 32px)",
        margin: 16,
        background: "rgb(244, 246, 249)",
        borderRadius: 14,
    },
    reContactMiniCardContacts: {
        width: "100%",
        display: "flex",
        padding: "0 10px 10px",
        "& .MuiButtonBase-root": {
            width: "calc(50% - 5px)",
        },
        "& .MuiButtonBase-root:nth-child(1)": {
            marginRight: 10,
        }
    },

    agentMiniCardBox: {
        width: "calc(100% - 32px)",
        margin: 16,
        background: "rgb(244, 246, 249)",
        borderRadius: 14,
    },
    agentMiniCardContactsBox: {
        width: "100%",
        display: "flex",
        padding: "0 10px 10px",
        "& .MuiButtonBase-root": {
            width: "calc(50% - 5px)",
        },
        "& .MuiButtonBase-root:nth-child(1)": {
            marginRight: 10,
        }
    },

    reContactMiniCardContentWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        padding: 10,
    },
    agentMiniCardContentWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        padding: 10,
        //background: "rgb(244, 246, 249)",
        //borderRadius: 8,
    },
    agentMiniCardContent: {
        marginLeft: 10,
        width: "calc(100% - 74px)",
    },
    agentMiniCardName: {
        fontWeight: 600,
        color: "rgb(47, 46, 54)",
    },
    agentMiniCardPhone: {
        margin: 0,
        fontWeight: 500,
        color: "rgb(47, 46, 54)",
    },
    agentMiniCardAgency: {
        fontWeight: 400,
        color: "rgb(101, 119, 128)",
    },
    agentMiniCardReContactName: {
        fontWeight: 400,
        color: "rgb(101, 119, 128)",
        margin: 0,
    },
    agentMiniCardContacts: {
        display: "flex",
        marginTop: 10,
        width: "100%",
        "& .MuiButtonBase-root": {
            width: "calc(50% - 5px)",
        },
        "& .MuiButton-outlined": {
            marginRight: 10,
        }
    },
    yandexMapViewPointContainer: {

    },
    noPhotoHouseName: {
        display: "flex",
        background: "#f5f6fa",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        "& svg": {
            fontSize: "1.5rem",
        }
    },
    noReCardPhoto: {
        display: "flex",
        background: "#f5f6fa",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 240,
        "& svg": {
            fontSize: "5.5rem",
        },
        borderRadius: 8,
    },
    catalogReCardInfoOptions: {
        padding: "10px 15px 0px 15px",
        marginBottom: -5,
    },
    catalogReCardText: {
        padding: "10px 15px",
    },
    catalogReCardTextHeader: {
        color: "rgb(47, 46, 54)",
        marginBottom: 6,
        fontWeight: 400,
    },
    catalogReCardTextContent: {
        color: "rgb(101, 119, 128)",
        fontWeight: 400,
    },
    houseCardGroupInputsWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    houseCardReWrapperForSquare: {
        width: "calc(100% - 10px)",
        margin: 5,
    },
    processReWrapper: {
        "left": "0",
        "right": "0",
        "bottom": "0",
        "height": "75px",
        "display": "flex",
        "flexWrap": "wrap",
        "padding": "0 32px",
        "zIndex": "10",
        "position": "absolute",
        "fontSize": "1rem",
        "background": "#ebe9e9b3",
        "alignItems": "flex-start",
        "borderBottom": "1px solid #e0e0e0",
        "backdropFilter": "blur(8px)",
        "justifyContent": "center",
        "paddingTop": "5px",
        "& p": {
            width: "100%",
            textAlign: "center",
            margin: 0,
            fontSize: "0.9rem",
        }
    },
    processReBtns: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "350px",
        alignItems: "center",
    },
    imageReCardWithSpinnerWrapper: {
        minWidth: 320,
        width: 320,
        height: 240,
        margin: "0 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    imageReCardOnePhoto: {
        "& .imageReCardWithSpinnerWrapper": {
            //width: "100%!important",
        },
    },

    ///

    comments: {
        padding: "20px 15px",
        background: "#F4F6F9",
        "& .MuiInputBase-root": {
            padding: "12px 14px",
            background: "#fff",
        },
    },
    reCommentsLoading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    commentsHeader: {
        "fontWeight": "700",
        "fontSize": 17*onePx+"rem",
        "color": "#2F2E36",
        marginBottom: 20,
    },
    commentsCount: {
        color: "rgba(74, 81, 87, 0.5)",
        fontWeight: 700,
    },
    commentText: {
        //flex: 1,
        position: "relative",
        //padding: "6px 8px 20px 8px",
        minWidth: "30%",
        border: "1px solid #dad9d9",
        //border: "1px solid #D4D4D4",
        borderRadius: "8px 8px 8px 0px",
        background: "#fff",
        wordBreak: "break-word",
        overflow: "hidden",
        /*
        "& p": {
            margin: 0,
            wordBreak: "break-word",
        }
         */
    },
    quoteHeader: {

    },
    quoteText: {

    },
    commentFooterText: {
        height: 24,
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #dad9d9",
        padding: "0 8px",
        fontSize: 14*onePx+"rem",
        textWrap: "nowrap",
        "& .MuiSvgIcon-root": {
            fontSize: 18*onePx+"rem",
            marginRight: 4,
        }
    },
    myCommentText: {
        borderRadius: "8px 8px 0px 8px",
    },
    commentAudio: {
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
        color: "#4CAF50",
        "& .MuiSvgIcon-root": {
            color: "#4CAF50",
            marginRight: 5,
        }
    },
    comment: {
        textAlign: "left",
        display: "flex",
        marginBottom: 20,
        position: "relative",
        padding: "0 20px 0 0",
        "&:last-child": {
            marginBottom: 22,
        },
    },
    myComment: {
        justifyContent: "flex-end",
        padding: "0 0 0 20px",
    },
    commentAuthor: {
        "fontWeight": "600",
        "fontSize": 13*onePx+"rem",
        "color": "#4CAF50"
    },
    commentCompany: {
        "fontWeight": "400",
        "fontSize": 13*onePx+"rem",
        "color": "#657780",
    },
    commentAvatar: {
        display: "flex",
        alignItems: "flex-end",
        marginRight: 4,
        position: "relative",
        "& .MuiAvatar-root": {
            border: "1px solid #DEE4E8",
        },
    },
    iconAvatar: {
        padding: "10px 0",
        "& .MuiSvgIcon-root": {
            //color: "#4CAF50"
        }
    },
    myCommentAvatar: {
        marginRight: "0!important",
        marginLeft: 4,
    },
    commentDate: {
        left: 48,
        top: -15,
        position: "absolute",
        margin: 0,
        color: "#9E9E9E",
        fontWeight: 400,
        fontSize: 11*onePx+"rem",
    },
    myCommentDate: {
        right: 48,
        left: "auto",
    },
    commentStatusSent: {
        fontSize: 15*onePx+"rem",
        margin: "0 0px -3px 4px",
        color: "#dad9d9",
    },
    commentDateTest: {
        left: 4,
        bottom: 23,
        position: "absolute",
        margin: 0,
        color: "#9E9E9E",
        fontWeight: 400,
        fontSize: 11*onePx+"rem",
    },
    myCommentDateTest: {
        right: 4,
        left: "auto",
    },

    commentContent: {
        color: "#2F2E36",
        fontWeight: 400,
        fontSize: 16*onePx+"rem",
        hyphens: "auto",
        //padding: "8px 10px",
        padding: "7px 10px 14px 10px",
        "& i": {
            textDecoration: "line-through",
            fontStyle: "normal",
            fontSize: 13*onePx+"rem",
        },
        "& span": {
            fontWeight: 600,
            fontSize: 13*onePx+"rem",
        },
    },
    commentContentWithQuote: {
        padding: "4px 10px",
        borderLeft: "1px solid #dad9d9",
        marginLeft: 10,
        marginBottom: 15,
        fontStyle: "italic",
        fontSize: 14*onePx+"rem",
    },
    commentContentHeader: {
        fontWeight: 600,
        padding: "7px 10px 5px 10px",
        color: "rgb(113, 196, 126)",
        "& .MuiSvgIcon-root": {
            marginBottom: -6,
            marginRight: 4,
        }
    },
    commentInfoText: {

    },
    commentsInput: {

    },

    ///

    catalogHouseReItemsWrapper: {
        display: "flex",
        height: 223,
        padding: "10px 0 0 10px",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        }
    },
    catalogHouseReItemsMediumWrapper: {
        display: "flex",
        height: 282,
        padding: "10px 0 0 10px",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        }
    },
    catalogMiniItemWrapper: {
        //width: 160,
        width: 156,
        background: "#F4F6F9",
        borderRadius: 8,
        border: "1px solid rgba(0, 32, 51, 0.05)",
        overflow: "hidden",
        position: "relative",
        //boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        margin: 5,
        flex: "none",
    },
    catalogMiniItemLoading: {
        //width: 160,
        width: 156,
        margin: 5,
        flex: "none",
        "display": "flex",
        "alignItems": "center",
        justifyContent: "center",
        "& span": {
            textAlign: "center"
        }
    },
    catalogMiniItemImage: {
        position: "relative",
        width: "100%",
        height: 115,
        //height: 120,
        "& img": {
            //height: 120,
            height: 115,
            width: "100%",
            objectFit: "cover",
        },
        "& .catalogPublishedAt": {
            height: 30,
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "0 0 4px 6px",
            background: "linear-gradient(360deg, #202020 0%, rgba(32, 32, 32, 0) 100%)",
            display: "flex",
            alignItems: "flex-end",
            "& span": {
                fontWeight: 600,
                fontSize: 12*onePx+"rem",
                color: "#fff",
            },
        },
        "& .catalogNotPublished::before": {
            "display": "block",
            "content": '""',
            "width": 8,
            "height": 8,
            borderRadius: "50%",
            marginRight: 6,
            marginBottom: 5,
            background: "#EB3333",
        },

    },
    //#10202F
    catalogMiniItemContent: {
        height: 100,
        padding: "6px 6px 6px 6px",
    },
    catalogMiniItemContentWithOutContact: {
        height: 86,
    },

    catalogMiniItemContentHeader: {
        "color": "#006BE9",
        "fontSize": 13*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "0.9rem",
        "height": 30,
        "display": "flex",
        //"alignItems": "center",
        "overflow": "hidden",
        margin: 0,
    },
    catalogMiniItemContentPrice: {
        display: "flex",
        alignItems: "center",
        marginTop: 2,
        "fontSize": 13*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "0.9rem",
        margin: 0,
    },
    catalogMiniItemContentAddPrice: {
        "display": "flex",
        "alignItems": "center",
        "marginTop": 2,
        "fontSize": 11*onePx+"rem",
        "fontWeight": "100",
        "lineHeight": "0.9rem",
        "margin": 0,
        "color": "#657780",
        "& b": {
            "fontSize": 13*onePx+"rem",
            "fontWeight": 700,
            color: "#2F2E36",
        }
    },
    catalogMiniItemContentOldPrice: {
        textDecorationLine: "line-through",
        fontWeight: 500,
        marginRight: 8,
    },
    catalogMiniItemContentCommission: {
        marginTop: 2,
        "fontSize": 13*onePx+"rem",
        "lineHeight": "0.9rem",
        color: "rgba(101, 119, 128, 1)",
        margin: 0,
    },
    catalogItemWrapper: {
        background: "#fff",
        borderRadius: 8,
        border: "1px solid #eaeff3",
        overflow: "hidden",
        position: "relative",
        boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
    },

    catalogItemBigWrapper: {
        background: "#fff",
        borderBottom: "1px solid #eaeff3",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        height: 330,
        marginBottom: 25,
    },


    catalogItemBigImagesWrapper: {
        position: "relative",
        height: 240,
        overflowX: "auto",
        display: "flex",
        alignItems: "center",
        paddingLeft: 17,
        paddingRight: 15,
        marginLeft: -2,
        "& img": {
            width: 320,
            height: 240,
            objectFit: "cover",
            borderRadius: 8,
        },

        "&::-webkit-scrollbar": {
            display: "none",
        }
    },
    catalogItemBigImageWithSpinnerWrapper: {
        minWidth: 320,
        width: 320,
        height: 240,
        margin: "0 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    catalogBigItemContentHeader: {
        "color": "#006BE9",
        "fontSize": 17*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "1.125rem",
        "minHeight": 25,
        "display": "flex",
        "alignItems": "center",
        "overflow": "hidden",
    },
    catalogBigItemContentPrice: {
        marginTop: 2,
        "fontSize": 17*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "1.125rem",
        margin: 0,
    },

    catalogBigItemContentAddPrice: {
        marginTop: 2,
        "fontSize": 17*onePx+"rem",
        "fontWeight": "100",
        "lineHeight": "1.125rem",
        margin: 0,
        color: "#657780",
    },



    reCatalogItemWrapper: {
        width: "calc(50% - 20px)",
        "&:nth-child(2n-1)":{
            //margin: "5px 5px 5px 15px",
            margin: "5px 15px 5px 5px",
        },

        "&:nth-child(2n)":{
            //margin: "5px 15px 5px 5px",
            margin: "5px 5px 5px 15px",
        },
    },
    ///

    catalogMediumItemWrapper: {
        width: 240,
        background: "#F4F6F9",
        borderRadius: 12,
        border: "1px solid rgba(0, 32, 51, 0.05)",
        overflow: "hidden",
        position: "relative",
        //boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        margin: 5,
        flex: "none",
    },
    catalogMediumItemLoading: {
        width: 240,
        margin: 5,
        flex: "none",
        "display": "flex",
        "alignItems": "center",
        justifyContent: "center",
        "& span": {
            textAlign: "center"
        }
    },
    catalogMediumItemImage: {
        position: "relative",
        width: "100%",
        height: 180,
        //height: 120,
        "& img": {
            //height: 120,
            height: 180,
            width: "100%",
            objectFit: "cover",
        },
        "& .catalogPublishedAt": {
            height: 30,
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "0 0 4px 6px",
            background: "linear-gradient(360deg, #202020 0%, rgba(32, 32, 32, 0) 100%)",
            display: "flex",
            alignItems: "flex-end",
            "& span": {
                fontWeight: 600,
                fontSize: 12*onePx+"rem",
                color: "#fff",
            },
        },
        "& .catalogNotPublished::before": {
            "display": "block",
            "content": '""',
            "width": 8,
            "height": 8,
            borderRadius: "50%",
            marginRight: 6,
            marginBottom: 5,
            background: "#EB3333",
        },

    },


    catalogMediumItemImage__housename: {
        "& .MuiSvgIcon-root": {
            color: "#fff",
            fontSize: 15,
            marginRight: 3,
            marginBottom: 2,
        },
    },
    catalogMediumItemContent: {
        height: 82,
        padding: 15,
    },

    catalogMediumItemContentHeader: {
        "color": "#006BE9",
        "fontSize": 16*onePx+"rem",
        "fontWeight": 600,
        "lineHeight": "0.9rem",
        "height": 30,
        "display": "flex",
        "overflow": "hidden",
        margin: 0,
    },
    catalogMediumItemContentPrice: {
        display: "flex",
        alignItems: "center",
        marginTop: 2,
        fontSize: 15*onePx+"rem",
        lineHeight: "0.9rem",
        margin: 0,
        color: "#657780",
        "& b": {
            fontSize: 17*onePx+"rem",
            "fontWeight": 700,
            color: "#2F2E36",
        }
    },

    catalogMediumItemContentAddPrice: {
        "display": "flex",
        "alignItems": "center",
        "marginTop": 2,
        "fontSize": 13*onePx+"rem",
        "fontWeight": "100",
        "lineHeight": "0.9rem",
        "margin": 0,
        color: "#657780",
    },

    ///
    houseCatalogItemWrapper: {
        width: "calc(50% - 20px)",
        "&:nth-child(2n-1 of div)":{
            margin: "5px 5px 5px 15px",
        },
        "&:nth-child(2n of div)":{
            margin: "5px 15px 5px 5px",
        },
    },
    reListCatalogItemWrapper: {
        width: "calc(50% - 15px)",
        "&:nth-child(2n of div)":{
            margin: "5px 5px 5px 10px",
        },
        "&:nth-child(2n-1 of div)":{
            margin: "5px 10px 5px 5px",
        },
    },

    noShadow: {
        boxShadow: "none!important"
    },
    catalogItemImage: {
        position: "relative",
        width: "100%",
        height: 109,
        "& img": {
            height: 109,
            width: "100%",
            objectFit: "cover",
        },
        "& .catalogPublishedAt": {
            height: 30,
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "0 0 4px 6px",
            background: "linear-gradient(360deg, #202020 0%, rgba(32, 32, 32, 0) 100%)",
            display: "flex",
            whiteSpace: "nowrap",
            alignItems: "flex-end",
            "& span": {
                fontWeight: 600,
                fontSize: 12*onePx+"rem",
                color: "#fff",
            },
        },
        "& .catalogNotPublished::before": {
            "display": "block",
            "content": '""',
            "width": 8,
            "height": 8,
            borderRadius: "50%",
            marginRight: 6,
            marginBottom: 4,
            background: "#EB3333",
        },
    },
    catalogItemNotImage: {
        background: "#F4F6F9",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "& .catalogPublishedAt": {
            background: "linear-gradient(360deg, rgba(32, 32, 32, 0.1) 0%, rgba(32, 32, 32, 0) 100%)",            "& span": {
                color: "rgba(24, 43, 61, 0.52)!important",
            },
        }
    },

    catalogItemContent: {
        height: 100,
        padding: "6px 6px 6px 6px",
    },
    catalogItemContentWithOutContact: {
        height: 93,
    },
    catalogBigItemContent: {
        height: 75,
        padding: "0 15px",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",
    },
    catalogItemContentWithRentBtn: {
        height: 70,
    },
    catalogItemContentWithBtn: {

    },
    catalogItemContentHeader: {
        "color": "#006BE9",
        "fontSize": 15*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "1.125rem",
        "height": 36,
        "display": "flex",
        "alignItems": "center",
        "overflow": "hidden",
    },
    catalogItemContentPrice: {
        marginTop: 2,
        "fontSize": 15*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "1.125rem",
        margin: 0,
    },
    catalogItemContentAddPrice: {
        marginTop: 2,
        "fontSize": 15*onePx+"rem",
        "fontWeight": "100",
        "lineHeight": "1.125rem",
        margin: 0,
        color: "#657780",
    },
    catalogItemContentOldPrice: {
        textDecorationLine: "line-through",
        fontWeight: 500,
        marginRight: 8,
    },
    catalogItemContentCommission: {
        marginTop: 2,
        "fontSize": 15*onePx+"rem",
        "lineHeight": "1.125rem",
        color: "rgba(101, 119, 128, 1)",
        margin: 0,
    },
    catalogItemContentOptions: {

    },
    catalogItemContact: {
        height: 36,
    },
    catalogItemBtnWrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: 4,
        "& .MuiButtonBase-root": {
            textTransform: "none",
            fontWeight: 600,
            flex: 1,
        },
        "& .MuiButtonBase-root:nth-child(1)": {
            color: "#4CAF50",
        },
        "& .MuiButtonBase-root:nth-child(2)": {
            color: "#000",
            textAlign: "rigth",
        },
    },
    catalogItemPlatformStatuses: {
        "scrollbarWidth": "none",

        "&::-webkit-scrollbar":{
            display: "none",
        },
        "width": "100%",
        "overflowX": "auto",
    },
    catalogItemPlatformStatusesScrollBox: {
        "width": "fit-content",
        "margin": "7px 7px 0 7px",
        "display": "flex",

        "& .MuiAvatar-root": {
            background: "#fff",
        },
        "& .MuiChip-root": {
            height: 30,
        },
        "& .MuiChip-label": {
            paddingLeft: 10,
            paddingRight: 7,
        },
        "& .published": {
            color: "#fff",
            background: green[500],
        },
        "& .not_published": {
            color: "#fff",
            background: orange[700],
        },
        "& .check": {
            color: "#fff",
            background: grey[500],
        },
        "& .no_status": {
            color: "#fff",
            background: grey[500],
        },
        "& .moderation": {
            color: "#fff",
            background: deepPurple[500],
        },
        "& .rejected": {
            color: "#fff",
            background: red[500],
        },
        "& .blocked": {
            color: "#fff",
            background: red[500],
        },
        "& .loading": {
            color: "#fff",
            background: green[500],
        },
        "& .for_publication": {
            color: "#fff",
            background: grey[500],
        },


    },

    catalogItemPlatformStatus: {
        marginRight: 4,
    },
    catalogItemPlatformStatusPosition: {
        "& .MuiAvatar-root": {
            marginLeft: "3px!important",
        },
    },
    catalogItemPlatformIconWithPosition: {
        "width": "64px",
        "height": "40px",
        "display": "flex",
        "background": "#4db050",
        "alignItems": "center",
        "marginRight": "4px",
        "borderRadius": "10px",
        "border": "3px solid #4db050",
    },
    catalogItemPlatformIconWithPositionBox: {
        "width": "34px",
        "height": "34px",
        "background": "#fff",
        "borderRadius": "8px",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
    },
    catalogItemPlatformIconWithPositionCount: {
        "color": "#fff",
        "width": "calc(100% - 34px)",
        "display": "flex",
        "fontSize": 13*onePx+"rem",
        "alignItems": "center",
        "fontWeight": "600",
        "justifyContent": "center",
    },
    catalogItemPlatformStatusNoPosition: {
        width: 34,
        height: 34,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "3px",
        borderRadius: "50%",
        "& .MuiAvatar-root": {
            background: "#fff",
            width: 24,
            height: 24,
        },
    },
    catalogItemPlatformStatusDisabled: {
        background: "rgba(244, 246, 249, 1)",
        "& .MuiAvatar-root": {
            background: "none!important",
        },
        "& svg": {
            filter: "grayscale(100%)",
            opacity: 0.4,
        }
    },
    catalogItemCheckboxWrapper: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1,
        background: "rgb(255 255 255 / 90%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0 0 0 15px",
    },

    catalogItemCheckbox: {
        padding: 5,
    },
    catalogItemCheckboxNotChecked: {
        color: "#817b7b",
        '& .MuiSvgIcon-root': {
            //fontSize: 28
        }
    },
    catalogItemCheckboxChecked: {
        color: "#00B25D!important",
        '& .MuiSvgIcon-root': {
            //fontSize: 28
        }
    },

    catalogItemBookmark: {
        position: "absolute",
        top: 0,
        left: 6,
        width: 36,
        height: 45,
        //width: 30,
        //height: 38,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "url(/svg/bookmarkCollection.svg) no-repeat",
        color: "#fff",
        paddingBottom: 7,
        backgroundSize: "cover",
        zIndex: 2,
    },
    catalogItemImageBtnWrapper: {
        position: "absolute",
        top: 6,
        right: 6,
        zIndex: 2,
        display: "flex",
        flexDirection: "row-reverse",
    },
    reCardToFavoriteBtnWrapper: {
        "& .MuiIconButton-root": {
            color: "#000",
        },
        "& .MuiSvgIcon-root": {
            fontSize: 14,
            color: "#10202F",
        }
    },
    reCardBottomPanel: {
        "left": "0",
        "right": "0",
        "bottom": "0",
        "zIndex": "10",
        "position": "fixed",
        "background": "#fff",
        "display": "flex",
        "alignItems": "center",
        "padding": 15,
        "borderTop": "1px solid #e0e0e0",
        "height": 70,
        "& .MuiButton-root": {
            flex: 1,
            maxWidth: 720,
            margin: "auto",
        }
    },
    catalogItemImageToFavoriteBtnWrapperChecked: {
        "& .MuiIconButton-root": {
            color: "#4CAF50",
        },
    },
    catalogHouseItemImageWrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        flex: "none",
        "& img": {
            width: "100%",
        }
    },
    catalogHouseItemWrapper: {
        //borderRadius: 14,
        /*


        overflow: "hidden",
        margin: "0 5px 16px 5px",
        border: "1px solid rgba(0, 32, 51, 0.08)",
         */
        //boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        width: "100%",
        borderBottom: "1px solid rgba(63, 85, 106, 0.28)",
        boxShadow: "0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12)",
        //borderRadius: 14,
        //width: "100%",
        overflow: "hidden",
        //margin: "15px 15px 16px 15px",
        //margin: "15px",
        background: "#fff",
        marginBottom: 20,
    },

    catalogHouseItemHeader: {
        padding: "0 10px",
        display: "flex",
        alignItems: "center",
        fontWeight: 700,
        fontSize: 17*onePx+"rem",
        height: 54,
        color: "#2F2E36",
        width: "100%",
        justifyContent: "space-between",
        position: "relative",
        //paddingRight: 53,
    },

    catalogHouseItemName: {
        width: "calc(100% - 50px)",
        overflow: "hidden",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& span": {
            letterSpacing: "-0.25px",
            lineHeight: "1.2rem",
        }
    },
    catalogHouseItemHeaderNoCover: {
        borderBottom: "1px solid rgba(63, 85, 106, 0.2)",
    },

    catalogHouseItemRating: {
        display: "flex",
        alignItems: "center",
        width: 50,
        justifyContent: "flex-end",
    },

    catalogHouseItemRatingVal: {
        color: "#fff",
        fontWeight: 600,
        fontSize: 13*onePx+"rem",
        marginLeft: 4,
    },

    catalogHouseItemCover: {
        height: 106,
        position: "relative",
        //marginBottom: 10,
    },
    catalogItemSquareWrapper: {
        position: "relative",
        width: "calc(25% - 10px)",
        borderRadius: 8,
        border: "1px solid rgba(0, 32, 51, 0.05)",
        background: "#F4F6F9",
        overflow: "hidden",
        margin: 5,
        "&::before": {
            content: '""',
            display: "block",
            paddingTop: "100%",
        },
    },
    catalogItemSquareContent: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: "8px 0",
        "&.catalogItemSquareContentNotPublished:before": {
            content: '""',
            display: "block",
            position: "absolute",
            left: -10,
            top: -10,
            width: 20,
            height: 20,
            transform: "rotate(45deg)",
            background: "#EB3333"
        }
    },
    catalogItemSquareHeader: {
        width: "100%",
        textAlign: "center",
        color: "#006BE9",
        fontSize: 12*onePx+"rem",
        marginBottom: 6,
        fontWeight: 600,
    },
    catalogItemSquarePrice: {
        width: "100%",
        textAlign: "center",
        color: "#2F2E36",
        fontSize: 12*onePx+"rem",
        fontWeight: 600,
    },
    catalogItemSquarePricePeriodCount: {
        borderRadius: 6,
        border: "1px solid #2F2E36",
        color: "#2F2E36",
        fontSize: 9*onePx+"rem",
        fontWeight: 100,
    },
    catalogItemSquareOptions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 7,
        "& .MuiSvgIcon-root": {
            color: "rgba(16, 32, 47, 0.35)",
            margin: "0 3px",
        }
    },
    catalogReCardBtnsWrapper: {
        padding: 15,
        //display: "flex",
        //justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 32, 51, 0.08)",
    },
    catalogItemSquareOptionsTextIcon: {
        fontSize: 12,
        color: "rgba(16, 32, 47, 0.35)",
        margin: "0 3px",
        fontWeight: 600,
    },
    noHouseItemPhoto: {
        width: "100%",
        height: "100%",
        display: "flex",
        background: "#f5f6fa",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            fontSize: "3rem",
        }
    },
    noHouseItemPhotoIcon: {
        //"color": "#f3f7f9",
        //"background": "#e8ecef",
    },
    catalogReItemshouseCardStatusWrapper: {
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 15px 10px 15px",
    },
    catalogHouseItemBtns: {
        padding: "0 10px 10px 10px",
    },
    collectionHouseItemRating: {
        display: "flex",
        alignItems: "center",
        width: 50,
        justifyContent: "flex-end",
    },

    collectionHouseItemRatingVal: {
        //color: "#2F2E36",
        color: "#2F2E36",
        fontWeight: 600,
        fontSize: 13*onePx+"rem",
        marginLeft: 4,
    },


    ///
    formChipGroup: {
        width: "100%",
        marginBottom: "16px",
        "& p": {
            color: "#657780",
            margin: "0 0 2px 4px",
            fontSize: "1rem",
        },
        "& .MuiChip-root": {
            height: 32,
            borderRadius: 6,
            background: "#DEE4E8",
            margin: "4px!important",
        },

        "& .MuiChip-label": {
            paddingRight: 10,
            paddingLeft: 10,
            fontWeight: 600,
            color: "#657780",
            fontSize: 14*onePx+"rem",
        },
    },
    chip: {
        height: "32px!important",
        borderRadius: "6px!important",
        background: "#DEE4E8!important",
        margin: "4px!important",
        "& .MuiChip-label": {
            paddingRight: "10px!important",
            paddingLeft: "10px!important",
            fontWeight: "600!important",
            color: "#657780!important",
            fontSize: 14*onePx+"rem!important",
            letterSpacing: "-0.8px",
        },
    },

    chipMini: {
        height: "24px!important",
        borderRadius: "4px!important",
        textTransform: 'uppercase',
        maxWidth: "calc(50% - 8px)",
        //background: "#DEE4E8!important",
        margin: "4px!important",
        "& .MuiChip-label": {
            paddingRight: "10px!important",
            paddingLeft: "10px!important",
            fontWeight: "600!important",
            fontSize: 12*onePx+"rem!important",
        },
        "&.MuiChip-outlined": {
            color: '#657780',
            borderColor: "#DEE4E8",
        },
        "&.MuiChip-filled": {
            background: "#DEE4E8",
            color: "#657780",
        },
    },

    chipWithDeleteBtn: {
        "& .MuiChip-label": {
            //paddingRight: "20px!important",
            minWidth: "50px!important",
        }
    },

    chipActive: {
        "&:hover": {
            background: "#4CAF50!important",
        },
        background: "#4CAF50!important",
        "& .MuiChip-label": {
            color: "#FAFAFA!important",
        }
    },

    formChipActive: {
        "&:hover": {
            background: "#4CAF50!important",
        },
        background: "#4CAF50!important",
        "& .MuiChip-label": {
            color: "#FAFAFA!important",
        }
    },

    microChip: {
        height: 20,
        borderRadius: 4,
        background: "#DEE4E8",
        "& .MuiChip-label": {
            paddingRight: 5,
            paddingLeft: 5,
            fontWeight: 500,
            color: "#657780",
            fontSize: 12*onePx+"rem",
        },
        margin: "2px!important",
    },
    filterItemStack: {
        display: "flex",
        flexWrap: "wrap",
        margin: -4,
    },
    filterChip: {
        height: 32,
        borderRadius: 6,
        background: "#DEE4E8",
        "& .MuiChip-label": {
            paddingRight: 10,
            paddingLeft: 10,
            fontWeight: 600,
            color: "#657780",
            fontSize: 14*onePx+"rem",
        },
        margin: "4px!important",
    },
    filterChipActive: {
        "&:hover": {
            background: "#4CAF50",
        },
        background: "#4CAF50",
        "& .MuiChip-label": {
            color: "#FAFAFA",
        }
    },
    filterItem: {
        padding: "12px 16px",
        background: "#F4F6F9",
        borderBottom: "1px solid rgba(0, 32, 51, 0.05)",
        "& p": {
            margin: "0 0 12px 0",
            color: "#657780",
            fontSize: 16*onePx+"rem",
        }
    },
    filterItemActive: {
        //borderBottom: "none",
        background: "rgba(255, 255, 255, 0.9)",
    },

    ///

    formSubmitWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    drawerBtnsWrapper: {
        flexWrap: "wrap",
        "width": "100%",
        "zIndex": 2,
        background: "#fff",
        borderTop: "1px solid #f2f2f2",
        //"background": "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%)",
        "position": "absolute",
        "bottom": "0px",
        "left": "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: 20,
    },
    formAutocompleate: {
        width: "100%",
        marginBottom: 16,
        "& .MuiFormControl-root": {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    popHouseNameWrapper: {
        margin: "-7px -4px 0 -4px",
        "& > p": {
            color: "#657780",
            fontSize: 13*onePx+"rem",
            margin: "0 0 1px 4px",
        },
        marginBottom: 16,
    },
    bottomDrawerBody: {
        height: "calc(100% - 48px)",
        //height: "calc(100% - 37px)",
        overflowY: "auto",
    },
    bottomDrawerBodyWithBottomBtns: {
        height: "calc(100% - 37px)",
        overflowY: "auto",
        paddingBottom: 77,
    },
    drawerFilterWrapper: {
        //height: "calc(100% - 50px)",
        padding: "0 0 100px 0",
        //overflowY: "auto",
    },
    scrollBoxCatalogReItems: {
        overflow: "auto",
        width: "100%",
        flex: 1,
        height: "100%",
        background: "#ECEEF1",
        paddingTop: 20,
        //background: "#D9D9D9",
    },
    mapMarkerHouseStatusWrapper: {
        display: "flex",
        height: "calc(100% - 38px)",
    },
    mapMarkerHouseStatus: {
        display: "flex",
        alignItems: "center",
        "& span": {
            marginLeft: 2,
            fontWeight: 400,
            fontSize: 12 * onePx + "rem",
        },
        marginRight: 4,
    },
    infiniteScrollLoading: {
        width: "100%",
        textAlign: "center",
        padding: 16,
    },

    ///

    imageGalleryWrapper: {
        "& .image-gallery-thumbnails-wrapper": {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            //height: "100vh",
            background: "#292929",
        },
        /*
        "& .image-gallery-swipe": {
            position: "fixed",
            top: 0,
            height: "calc(100% - 87px)",
            display: "flex",
            alignItems: "center",
        },

         */
        "& .image-gallery-slide-wrapper": {
            position: "fixed",
            top: 0,
            height: "calc(100% - 87px)",
            display: "flex",
            alignItems: "center",
        }
    },

    ///

    openInAOBtn: {
        "& .MuiSvgIcon-root": {
            height: "auto",
            fontSize: 50*onePx+"rem",
        }
    },

    ///

    searchPanelWrapper: {
        position: "fixed",
        top: 58,
        left: 10,
        right: 10,
        height: 54,
        background: "#fff",
        zIndex: 2,
        boxShadow: '0px 8px 24px rgba(0, 32, 51, 0.12)',
        fontFamily: 'Inter',
        borderRadius: 12,
        maxWidth: 500,
        margin: "auto",
        "& .MuiButtonGroup-root": {
            width: "100%",
            height: "100%",
            borderRadius: 12,
            overflow: "hidden",
        },
        "& .MuiButton-root": {
            background: "#fff",
            color: "#2F2E36",
            fontSize: 15*onePx+"rem",
            fontWeight: 400,
            borderColor: "#3F556A33",
            textTransform: "none",
            borderRadius: 0,
            padding: 6,
        },

        "& .MuiSvgIcon-root": {
            color: "#B3BEC2"
        },


    },

    searchPanelActiveBtn: {
        fontWeight: "600!important",
        "& .MuiSvgIcon-root": {
            color: "#00B2FF",
        },
    }



}));
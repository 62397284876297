import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import { UserContext } from "../../providers/UserProvider";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {useNavigate} from "react-router";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const TextPageTpl = ({ children }) => {

    const gClasses = useGStyles();
    const { user, setUser } = useContext(UserContext);

    //глобальный state
    const {
        toast, setToast,
    } = useContext(GlobalStateContext);

    const nav = useNavigate();

    const onOpenMain = () => {
        nav("/");
    }

    return (
        <>

            <AppBar position="static" className={clsx(gClasses.appBar)}>

                <Toolbar className={gClasses.mainToolbarWrapper}>

                    <div className={gClasses.mainToolbar}>

                        <IconButton
                            style={{display: "none"}}
                            className={gClasses.toolbarSearchLogo}
                            size="large"
                            onClick={() => {}}
                        >
                            <SearchIcon/>
                        </IconButton>


                        <div className={gClasses.toolbarLogo}>
                            <img src="/svg/logo.svg" onClick={onOpenMain}/>
                        </div>

                        <IconButton
                            className={clsx(gClasses.toolbarAccountLogo, (user?gClasses.toolbarAccountLogoActive:null))}
                            size="large"
                            onClick={() => {nav("/profile")}}
                        >
                            {user?<AccountCircleIcon />:<ExitToAppIcon />}
                        </IconButton>


                    </div>

                </Toolbar>

            </AppBar>

            <div className={clsx(gClasses.contentWrapper, gClasses.contentWrapper__text)}>
                {children}
            </div>

        </>
    );
};

export default TextPageTpl;

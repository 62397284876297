import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./UserProvider";
import AuthProvider from "./AuthProvider";
import GlobalStateProvider from "./GlobalStateProvider"
import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";

const onePx = 0.0625;
const theme = createTheme({

  shadows: Array(25).fill('none'),

  //цвета
  palette: {
    primary: {
      light: '#70bf73',
      main: '#4CAF50',
      dark: '#3d8c40',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ebedf1',
      main: '#e6e9ed',
      dark: '#b8babe',
      contrastText: '#000',
    },
    violet: {
      light: '#8963E3',
      main: '#7546E6',
      dark: '#4923A3',
      contrastText: '#fff',
    },
    blue: {
      light: '#40C5FF',
      main: '#00B2FF',
      dark: '#0085BF',
      contrastText: '#fff',
    },
    green: {
      light: '#89CD40',
      main: '#62BC00',
      dark: '#4A8D00',
      contrastText: '#fff',
    },
    yellow: {
      light: '#FFC740',
      main: '#FFB500',
      dark: '#BF8800',
      contrastText: '#fff',
    },
    red: {
      light: '#FF8159',
      main: '#FF5722',
      dark: '#BF4119',
      contrastText: '#fff',
    },
    black: {
      light: '#6060609',
      main: '#323232',
      dark: '#000',
      contrastText: '#fff',
    },
  },

  components: {
    MuiList: {
      styleOverrides: {
        root: {
          background: "#fff",
          boxShadow: '0px 8px 24px rgba(0, 32, 51, 0.12)',
          borderRadius: 8,
          border: "1px solid rgba(62.86, 85.42, 106.25, 0.20)",
          color: '#2F2E36',
          "& .MuiButtonBase-root": {
            color: '#2F2E36',
            fontSize: 15*onePx+"rem",
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          background: "#fff",
          padding: 1,
          boxShadow: '0px 8px 24px rgba(0, 32, 51, 0.12)',
          borderRadius: 8,
          border: "1px solid rgba(62.86, 85.42, 106.25, 0.20)",
          color: '#2F2E36',
          fontSize: 15*onePx+"rem",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            borderRadius: 8,
          },
          "&.MuiButton-containedSecondary": {
            color: "#4d5965",
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#ECEEF1",
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: 1,
          right: -2,
          height: 16,
          minWidth: 16,
          padding: "0 3px",
          fontSize: "0.7rem",
        }
      }
    }

  },

  overrides: {

    /*
    MuiSnackbar: {
        variants: [
            {
                props: { variant: "filled" },
                style: {
                    zIndex: 1999,
                }
            },

        ],
    },

     */

    /*
    MuiButton: {
        sizeSmall: {

        },
        sizeLarge: {
            "fontSize": "18px",
            "height": "47px",
            "lineHeight": "47px",
            "width": "100%",
            "padding": "0",
        },
        textPrimary: {
            "backgroundColor": "#FFDB94",
            "color": "#000000",
            "textTransform": "none",
        },
        textSecondary: {
            "color": "#fff",
            "textTransform": "none",
        }
    },
    MuiIconButton:{
        colorPrimary: {
            "color": "#FFDB94",
        },
    },
    MuiCheckbox: {
        colorPrimary: {
            "color": "#FFDB94",
            '&$checked': {
                "color": "#FFDB94",
            },
        }
    },

    MuiFormControlLabel: {
        label: {
            "color": "#FFDB94"
        }
    },

    MuiTab: {
        root: {
            "boxSizing":"border-box",
            "textTransform":"none",
            "color": "#BDBDBD",
        },

        textColorPrimary: {
            "color": "#fff",
            '&$selected': {
                "color": "#FFDB94",
            },
        },
        "& .Mui-selected": {
            "color": "#fff",
        },
    },
    MuiTabs: {
        indicator: {
            "backgroundColor": "#FFDB94",
        },
        flexContainer: {
            "borderBottom":"2px solid #4F4F4F"
        }
    },

    MuiBadge: {
        root: {
            "paddingRight": "25px",
        },
        colorPrimary: {
            "color": "#000",
            "backgroundColor": "#FFDB94",
        },
        anchorOriginTopRightRectangle:{
            "top": "13px",
            "right": "10px",
        }
    },
    MuiInput: {
        underline: {
            "&::after": {
                "borderBottom": "2px solid rgba(0,0,0,0.87)",
            }
        }
    },

    MuiFormLabel: {
        "root": {
            "&$focused": {
                "color": "#000"
            }
        }
    },
    MuiDrawer: {
        paper: {
            "background": "#272426",
            "width": "100%"
        }
    },
    MuiAppBar:{
        root: {
            "height": "58px",
        },
        colorDefault: {
            "background-color": "#000",
        },
    }

     */

  },

  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '1.5rem',
    },
    h2: {
      fontSize: '1.4rem',
    },
    h3: {
      fontSize: '1.3rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
  },
});

const MainProvider = ({ children }) => {
  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>

            <CssBaseline />

            <GlobalStateProvider>
              <UserProvider>
                <AuthProvider>
                  {children}
                </AuthProvider>
              </UserProvider>
            </GlobalStateProvider>

          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
  );
};

export default MainProvider;

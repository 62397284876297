import instance from "../api/axios.api";
import { getTokens } from "../api/tokens.api";

const UserService = {

  async getProfile() {

    const { accessToken } = getTokens();

    if (accessToken) {
      try{
        const { data } = await instance.post("/book/user/get-profile");
        return {
          status: true,
          user: data?.user || null,
        };
      }catch (e) {
        return {
          status: false,
          error: "Не удалось получить accessToken пользователя"
        };
      }

    }

    return {
      status: false,
      error: "Не удалось получить accessToken пользователя"
    };

  },

  async getData(options) {
    const { data } = await instance.post("/book/user/get-data", {...options});
    return data;
  },

  async setData(options) {
    const { data } = await instance.post("/book/user/set-data", {...options});
    return data;
  },

  async pushData(options) {
    const { data } = await instance.post("/book/user/push-data", {...options});
    return data;
  },

  async getArrayData(options) {
    const { data } = await instance.post("/book/user/get-array-data", {...options});
    return data;
  },



};

export default UserService;

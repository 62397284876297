import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    drawerShareWrapper: {
        padding: 15,
        "& p": {
            color: "#657780",
            marginBottom: "15px",
        }
    },

    drawerHeader: {
        color: "#2F2E36!important",
        fontSize: 20*onePx+"rem",
        fontWeight: 700,
        margin: "0 0 16px 0!important",
    },

    formShareInput: {
        width: "100%",
        marginBottom: "16px!important",
        "& .MuiInputBase-input": {
            textAlign: "left",
        }
    },
    shareLogoIcon: {
        marginLeft: -14,
        marginRight: 7,
        fontSize: 20,
        marginBottom: -2,
        "& .MuiSvgIcon-root": {

        }
    }

}));
import clsx from "clsx";
import {AOFavoriteBorderIcon, AOFavoriteIcon, NoPhotoIcon, WarningIcon} from "../../assets/svg-icons.js";
import moment from 'moment';
import {priceToTriads, getReHeader, getTextDate} from "../../utils/functions";
import React from "react";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import IconButton from "@mui/material/IconButton";
import {BookServiceSignIcon} from "../../assets/svg-icons";


const afterDate = moment().subtract(30, "days").toDate();

export default (props) => {

    const {
        classes,
        onViewRe,
        re,
        includedReOptions,
        collectionFavorites,
        isMyCollection,
        addReToCollectionFavorites,
        houseId,
        from,
        pubConfig,
        backUrl,
    } = props;

    const {
        cover,
        price,
        minPrice,
        commission,
        _id,
        numberId,
        name,
        publishedInCatalog,
        changedPrice,
        highlightedCollections,
        highlightedCollFavorites,
        userId,
        //
        pricePeriod,
        //pricePeriodAv,
        pricePeriodCount,
        companyId,
    } = re;

    const pricePeriodAv = Math.ceil(pricePeriod/pricePeriodCount);

    console.log("companyId="+companyId);

    const publishedInCatalogStatus = publishedInCatalog && moment(publishedInCatalog).isAfter(afterDate) || false;

    //показывает подсвечивать ли объект
    const showHighlightedCollections = highlightedCollections && includedReOptions && includedReOptions.options && ["my", "all"].indexOf(includedReOptions.options) > -1 || false;

    return (
        <div className={classes.catalogMiniItemWrapper}>

            <div className={clsx(classes.catalogMiniItemImage, (!cover && classes.catalogItemNotImage || null))} >

                {!!(companyId && companyId == pubConfig.bookServiceCompanyId) &&
                    <div className={classes.catalogItemBookmark}>
                        <BookServiceSignIcon sx={{ fontSize: 17 }}/>
                    </div>
                }

                {!!(highlightedCollFavorites && collectionFavorites) &&
                <div className={classes.catalogItemBookmark}>
                    <AOFavoriteIcon sx={{ fontSize: 14 }}/>
                </div>
                }

                <div className={classes.catalogItemImageBtnWrapper}>
                    {!!(!isMyCollection && from && from == "collections") &&
                    <div className={clsx(classes.squareBtn, classes.reCardToFavoriteBtnWrapper, (highlightedCollFavorites?classes.catalogItemImageToFavoriteBtnWrapperChecked:null))}>
                        <IconButton size="small" color="primary" component="label" onClick={() => addReToCollectionFavorites(houseId, re._id, !highlightedCollFavorites)}>{highlightedCollFavorites?<AOFavoriteIcon style={{color: "#EB3333"}}/>:<AOFavoriteBorderIcon/>}</IconButton>
                    </div>
                    }
                </div>

                {cover ? <img src={pubConfig.image_url + "/" + (cover?.images && (cover.images.thumbXS?.url || cover.images.xs?.url))} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/> : <NoPhotoIcon className={classes.noPhotoIcon} onClick={() => onViewRe(_id, numberId, userId, backUrl)}/>}

                <div className={clsx((from && from == "collections" && !publishedInCatalogStatus?"catalogNotPublished":null), "catalogPublishedAt")}><span>{publishedInCatalogStatus?getTextDate(publishedInCatalog, 7):"снято"}</span></div>

            </div>

            <div className={clsx(classes.catalogMiniItemContent, classes.catalogMiniItemContentWithOutContact)} onClick={() => onViewRe(_id, numberId, userId, backUrl)}>
                <p className={classes.catalogMiniItemContentHeader}>{name || getReHeader(re, true)}</p>

                {!!( (price || minPrice) && !pricePeriod) &&
                    <div className={classes.catalogMiniItemContentPrice}>
                        <span><b>{minPrice?"от":""} {priceToTriads(minPrice || price)} ₽</b> за сутки</span>
                        {!!changedPrice && <WarningIcon style={{fontSize: "0.8rem", marginLeft: 5, color: "#FFA10A"}}/>}
                    </div>
                }

                {!!pricePeriod &&
                    <>
                        <div className={classes.catalogMiniItemContentPrice}>
                            <span><b>{priceToTriads(pricePeriod)} ₽</b> за {pricePeriodCount} дн.</span>
                            {!!changedPrice && <WarningIcon style={{fontSize: "0.8rem", marginLeft: 5, color: "#FFA10A"}}/>}
                        </div>
                        <div className={classes.catalogMiniItemContentAddPrice}>
                            <span><b>{priceToTriads(pricePeriodAv)} ₽</b> за сутки</span>
                        </div>
                    </>
                }

            </div>

        </div>
    )

}
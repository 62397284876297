import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import clsx from "clsx";
import useStyles from "./styles";
import Avatar from "@mui/material/Avatar";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import htmlToReact from 'html-react-parser';
import BlockIcon from '@mui/icons-material/Block';
import {ToWorkIcon, PostponeIcon} from "../../assets/svg-icons.js";
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Person2Icon from '@mui/icons-material/Person2';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {GlobalStateContext} from "../../providers/GlobalStateProvider";



export default React.memo((props) => {

    const classes = useStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    let {
        onShowAudioPlayer,
        note,
        isMyComment,
        groups,
        tags,
    } = props;

    let {
        id,
        createdAt,
        date,
        type,
        avatar,
        text,
        lead,
        //если событие смены воронки
        newPipeline,
        //если клиент был отложен
        dateReminder,
        groupId,
        whatsAppStatusMessage,
        //отправка отложенного сообщения в WA
        waitDelayedWAMessageSent,
        //отметка, что задача исполнена
        reminderSent
    } = note;

    //console.log("re-render NoteTpl");

    //обработка текста (чтобы не отображались зачеркнутые теги)
    if (text && tags?.length) {
        tags.map(t => {
            text = text.replace(new RegExp("\(<i\>\#(" + t.name + ")\<\/i\>)", 'gi'), "<span>#$2</span>")
        });
    }

    const getStatusSentIcon = (status) => {
        switch (status) {
            case "waiting_to_sent":
                return <AccessTimeIcon className={classes.commentStatusSent}/>;
            case "delivered":
                return <DoneAllIcon className={classes.commentStatusSent}/>;
            case "sent":
                return <CheckIcon className={classes.commentStatusSent}/>;
            case "read":
                return <DoneAllIcon style={{color: "#53bdeb"}} className={classes.commentStatusSent}/>;
        }
    };

    const NoteTpl = (props) => {

        const {
            rightPosition,
            text,
            //footer = {color, bgColor, text, icon, callRecording}
            footer,
            //quote = {color, header, text, icon}
            quote,
            avatarUrl,
            avatarIcon,
            userName,
            readMsgStatus,
            style = {},
        } = props;

        let {color, borderColor} = style;

        if(!color) color = "#000";
        if(!borderColor) borderColor = "#D4D4D4";

        borderColor = "none";
        if(footer?.bgColor) footer.bgColor = "#fff";

        const PrintDate = () => {
            if(!readMsgStatus){
                return moment(createdAt || date).format("DD.MM.YY");
            }

            return (rightPosition?<>{moment(createdAt || date).format("DD.MM.YY")}{readMsgStatus}</>:<>{readMsgStatus}{moment(createdAt || date).format("DD.MM.YY")}</>)
        }

        const AvatarTpl = () => (
            <div className={clsx(classes.commentAvatar, (rightPosition?classes.myCommentAvatar:null))}>
                {avatarUrl?
                    <Avatar sx={{ width: 44, height: 44 }} src={avatarUrl && pubConfig.image_url+"/"+avatarUrl || null}>{userName && userName[0] || "?"}</Avatar>
                    :
                    <Avatar sx={{ width: 44, height: 44 }}>{avatarIcon || <SmartToyIcon/>}</Avatar>
                }
            </div>
        );

        const TextWithFooter = () => (
            <div className={clsx(classes.commentText, (rightPosition?classes.myCommentText:null))} style={{color, borderColor}}>
                <div className={classes.commentContent}>{htmlToReact(text)}</div>
                <span className={clsx(classes.commentDateTest, rightPosition?classes.myCommentDateTest:null)}><PrintDate/></span>
                <div className={classes.commentFooterText} style={{background: footer.bgColor, color: footer.color, borderColor}}>
                    {footer.icon} {footer.text}
                </div>
            </div>
        );

        const TextWithDelayedWAMessageWithFooter = () => {
            return (
                <div className={clsx(classes.commentText, (rightPosition?classes.myCommentText:null))} style={{color, borderColor}}>
                    <div className={classes.commentContentHeader}><WhatsAppIcon/>Запланирована отправка сообщения клиенту в WA</div>
                    <div className={clsx(classes.commentContent,classes.commentContentWithQuote)}>{htmlToReact(text)}</div>
                    <span className={clsx(classes.commentDateTest, rightPosition?classes.myCommentDateTest:null)}><PrintDate/></span>
                    <div className={classes.commentFooterText} style={{background: footer.bgColor, color: footer.color, borderColor}}>
                        {footer.icon} {footer.text}
                    </div>
                </div>
            )
        }

        const OnlyFooter = () => (
            <div className={clsx(classes.commentText, (rightPosition?classes.myCommentText:null))} style={{color, borderColor}}>
                <div className={classes.commentFooterText} style={{background: footer.bgColor, color: footer.color, border: "none", height: 44, paddingBottom: 7, fontSize: "0.875rem"}}>
                    {footer.callRecording?
                        <div className={classes.commentAudio} onClick={() => onShowAudioPlayer(pubConfig.records_url + "/" + footer.callRecording, "Запись звонка от " + moment(date).format("DD.MM.YY HH:mm"))}>
                            <PlayCircleOutlineIcon/> <span>{footer.text}</span>
                        </div>
                        :
                        <>
                            {footer.icon} {footer.text}
                        </>
                    }
                    <span className={clsx(classes.commentDateTest, rightPosition?classes.myCommentDateTest:null)} style={{bottom: 0}}><PrintDate/></span>

                </div>
            </div>
        );

        const OnlyText = () => (
            <div className={clsx(classes.commentText, (rightPosition?classes.myCommentText:null))} style={{color, borderColor}}>
                <div className={classes.commentContent}>{htmlToReact(text)}</div>
                <span className={clsx(classes.commentDateTest, rightPosition?classes.myCommentDateTest:null)} style={{bottom: 0}}><PrintDate/></span>
            </div>
        );

        const QuoteWithFooter = () => (
            <div className={clsx(classes.commentText, (rightPosition?classes.myCommentText:null))} style={{color, borderColor}}>
                <p className={classes.quoteHeader} style={{color: quote.color}}>{quote.icon} {quote.header}</p>
                <div className={classes.quoteText}>{quote.text}</div>
                <div style={{background: footer.bgColor, color: footer.color, borderColor}}>
                    {footer.icon || null} {footer.text}
                </div>
            </div>
        );

        return (
            <div className={clsx(classes.comment, (rightPosition?classes.myComment:null))}>

                {!rightPosition && <AvatarTpl/>}

                {!!(text && !waitDelayedWAMessageSent && footer) && <TextWithFooter/>}

                {!!(text && !!waitDelayedWAMessageSent && footer) && <TextWithDelayedWAMessageWithFooter/>}

                {!!(quote && footer) && <QuoteWithFooter/>}

                {!!(text && !footer) && <OnlyText/>}

                {!!(!text && footer) && <OnlyFooter/>}

                {rightPosition && <AvatarTpl/>}

            </div>
        )
    }

    let reText;
    if(lead?.re?.length) reText = "Объекты: " + lead.re.map(l => l.text).join("; ");

    let footer = null;
    let style = {};
    let readMsgStatus;
    switch (type) {

        case "call":
            return <NoteTpl
                text={reText}
                footer={{color: "#31944C", bgColor: "#E9FEEF", text: "Входящий звонок", icon: <PhoneIcon/>, callRecording: lead?.callRecording || null}}
                style={{borderColor: "#A8D6B5"}}
                rightPosition={false}
                //avatarUrl={avatar?.xs?.url || null}
            />;

        case "lead":
            return <NoteTpl
                text={reText}
                footer={{color: "#A8D6B5", bgColor: "#E9FEEF", text: "Новая заявка", icon: <Person2Icon/>}}
                style={{borderColor: "#A8D6B5"}}
                rightPosition={false}
                //avatarUrl={avatar?.xs?.url || null}
            />;

        case "site":
            return <NoteTpl
                text={reText}
                footer={{color: "#A8D6B5", bgColor: "#E9FEEF", text: "Новая заявка с сайта", icon: <LanguageIcon/>}}
                style={{borderColor: "#A8D6B5"}}
                rightPosition={false}
                //avatarUrl={avatar?.xs?.url || null}
            />;

        case "pipeline_change":

            switch (newPipeline) {
                case 20:

                    if(/#УСПЕШНАЯ_СДЕЛКА/.test(text)){
                        //Закрыт - успешная сделка
                        footer = {
                            text: "Клиент закрыт",
                            color: "#31944C",
                            bgColor: "#E9FEEF",
                            icon: <EmojiEventsIcon/>
                        };

                        style.borderColor = "#A8D6B5";
                    }else{
                        //Закрыт - провален
                        footer = {
                            text: "Клиент закрыт",
                            color: "#e76060",
                            bgColor: "#FFEDED",
                            icon: <CancelIcon/>
                        };

                        style.borderColor = "#E1B5B5";
                    }


                    break;
                case 30:
                    //Отложен

                    switch (reminderSent) {
                        case "sent":
                            //если отложенное напоминание (отправлено без wa сообщений)
                            footer = {
                                text: "Напоминание от " + moment(dateReminder).format("DD.MM.YY HH:mm"),
                                color: "#D7A33E",
                                bgColor: "#FFF8EC",
                                icon: <PostponeIcon/>
                            };
                            style.borderColor = "#E1D2B5";
                            break;
                        case "sent_with_wa_msg":
                            //если отложенное напоминание (отправлено с wa сообщением)
                            footer = {
                                text: "Отлож. сообщ. клиенту в WA",
                                color: "#71c47e",
                                bgColor: "#fff",
                                //color: "#D7A33E",
                                //bgColor: "#FFF8EC",
                                icon: <WhatsAppIcon/>
                                //icon: <PostponeIcon/>
                            };
                            break;
                        default:
                            //если отложенное напоминание (не исполнено)
                            footer = {
                                text: "Отложен до " + moment(dateReminder).format("DD.MM.YY HH:mm"),
                                color: "#D7A33E",
                                bgColor: "#FFF8EC",
                                icon: <PostponeIcon/>
                            };
                            style.borderColor = "#E1D2B5";
                            break;
                    }

                    break;
                case 40:
                    //SPAM
                    footer = {
                        text: "Заблокирован SPAM",
                        color: "#3f3e3e",
                        bgColor: "#E3E3E3",
                        icon: <BlockIcon/>
                    };
                    style.borderColor = "#9c9c9c";
                    break;
                case 50:
                    //В работе
                    footer = {
                        text: "Клиент взят в работу",
                        color: "#259ADC",
                        bgColor: "#E3F5F",
                        icon: <ToWorkIcon/>
                    };
                    style.borderColor = "#A3C9DE";
                    break;
            }

            //если это сообщение в WA
            if(whatsAppStatusMessage){
                footer = {
                    text: "Сообщение клиенту в WA",
                    color: "#71c47e",
                    bgColor: "#fff",
                    icon: <WhatsAppIcon/>
                };
                readMsgStatus = getStatusSentIcon(whatsAppStatusMessage.status);

            }

            return <NoteTpl
                text={text}
                footer={footer}
                style={style}
                readMsgStatus={readMsgStatus}
                rightPosition={true}
                avatarUrl={avatar?.xs?.url || null}
            />;

        case "pub_group":

            const group = groupId && groups?.find(g => g.id == groupId) || null;

            footer = {
                text: (group?.value || "Общая группа"),
                color: "#6D48D8",
                bgColor: "#fff",
                icon: <GroupIcon/>
            };

            style.borderColor = "#6D48D8";

            return <NoteTpl
                text={text}
                footer={footer}
                style={style}
                rightPosition={isMyComment}
                avatarUrl={avatar?.xs?.url || null}
            />;

        default:

            if(!text) return <div/>;

            footer = null;
            //если сообщение отправленное в whatsApp
            if(whatsAppStatusMessage){
                footer = {
                    text: "Сообщение клиенту в WA",
                    color: "#71c47e",
                    bgColor: "#fff",
                    //bgColor: "#EEF5EF",
                    icon: <WhatsAppIcon/>
                };
                readMsgStatus = getStatusSentIcon(whatsAppStatusMessage.status);
            }

            return <NoteTpl
                text={text}
                footer={footer || null}
                readMsgStatus={readMsgStatus}
                rightPosition={isMyComment}
                avatarUrl={avatar?.xs?.url || null}
            />;
    }


},(b, a) => {
    return !(
        a.note._id != b.note._id ||
        a.tags != b.tags ||
        a.groups != b.groups ||
        a.note.whatsAppStatusMessage != b.note.whatsAppStatusMessage
    );
});
import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";
import MainProvider from "./providers/MainProvider";
import './assets/image-gallery.css';
import './assets/main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//отключение touch-zoom
//Disable Pinch Zoom
document.addEventListener('gesturestart', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

document.addEventListener('gesturechange', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

document.addEventListener('gestureend', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

ReactDOM.render(
    <React.StrictMode>
        <MainProvider>
            <App />
        </MainProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
import React from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;
export default makeStyles((theme) => ({

    contentWrapper: {
        height: "calc(100% - 48px)",
        marginTop: 48,
    },

}));
import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    calendarItem__lastMonthDay: {
        "& .calendarItemBookingLayer": {
            right: "0!important",
        },
    },

    calendarItem__selected: {
        "& .calendarItemBookingSelectedLayer": {
            position: "absolute",
            top: -1,
            left: 0,
            right: -1,
            bottom: 0,
            zIndex: 6,
            background: "repeating-linear-gradient( 0deg, #0f85ebe6 0 10%, #0f85eb87 10% 20%)",
        },
        "& .calendarItemBookingSelectedLayer.calendarItemBookingSelectedLayer__selectedFirst": {
            left: "22%",
        },
        "& .calendarItemBookingSelectedLayer.calendarItemBookingSelectedLayer__selectedLast": {
            right: "83%",
        },
    },

    calendarItem__range_selected: {
        "& .calendarItemBookingSelectedLayer": {
            position: "absolute",
            top: -1,
            left: 0,
            right: -1,
            bottom: 0,
            zIndex: 6,
            background: "#f0f2f3",
        },
        "& .calendarItemBookingSelectedLayer.calendarItemBookingSelectedLayer__selectedFirst": {
            left: "50%",
        },
        "& .calendarItemBookingSelectedLayer.calendarItemBookingSelectedLayer__selectedLast": {
            right: "50%",
        },
        "& .calendarItemBookingSelectedLayer.calendarItemBookingSelectedLayer__not_bg": {
            background: "none",
        }
    },

    calendarItem__range_start_day: {
        "&::before": {
            "display": "block",
            "content": '""',
            "width": "100%",
            "height": "100%",
            "background": colorGreen,
            "borderRadius": "50%",
            "position": "absolute",
            "zIndex": 7,
            "left": 0,
            "top": 0,
        },
        "& .calendarItemBookingSelectedLayer": {

        }
    },

    calendarItem__range_end_day: {
        "&::before": {
            "display": "block",
            "content": '""',
            "width": "100%",
            "height": "100%",
            "background": colorGreen,
            "borderRadius": "50%",
            "position": "absolute",
            "zIndex": 7,
            "left": 0,
            "top": 0,
        },
    },

    calendarItem__rent_paid: {
        "& span.calendarItemTextDay::after": {
            content: '""',
            "top": "-2px",
            "right": "-10px",
            "width": "6px",
            "height": "6px",
            "borderRadius": "50%",
            "position": "absolute",
            "background": "#00b25d",
            "border": "1px solid rgb(255 255 255 / 30%)",
            "boxSizing": "content-box"
        }
    },

    calendarItem__rent_not_paid: {
        "& span.calendarItemTextDay::after": {
            content: '""',
            "top": "-2px",
            "right": "-10px",
            "width": "6px",
            "height": "6px",
            "borderRadius": "50%",
            "position": "absolute",
            "background": "#ff5151",
            "border": "1px solid rgb(255 255 255 / 30%)",
            "boxSizing": "content-box"
        }
    },

    calendarItem__colorFutureBooking: {
        "& .calendarItemTextDay": {
            color: "#2F2E36",
        },
    },

    calendarItem__colorNotConfirmed: {
        "& .calendarItemTextDay": {
            color: "#2F2E36!important",
        },
    },

    calendarItem__colorForDeletion: {
        "& .calendarItemTextDay": {
            color: "#2F2E36!important",
        },
    },

    calendarItem__first: {

        "& .calendarItemBookingLayer": {
            borderRadius: "50% 0 0 50%",
            left: "22%!important",
            right: "-7.48%!important",
        },

    },

    calendarItem__oneDay: {
        //calendarItem__penult: {

        "& .calendarItemBookingLayer": {
            borderRadius: "50%",
            left: "22%!important",
            right: "-16%!important",
        },

    },

    calendarItem__fullFillPast: {
        background: "linear-gradient(to bottom, #ffffff 8%, #dbdbdb 8%, #dbdbdb 92%, #ffffff 92%)",
    },

    calendarItem__fullFillActive: {
        background: "linear-gradient(to bottom, #ffffff 8%, #398287 8%, #398287 92%, #ffffff 92%)",
    },

    calendarItem__penult: {

        "& .calendarItemBookingLayer": {
            right: "30%!important",
        },

        "& .calendarItemBookingLayer2Penult": {
            position: "absolute",
            borderRadius: "50%",
            left: "22%!important",
            right: "-17%!important",
            top: "8%",
            bottom: "8%",
            zIndex: 2,
        }

    },

    calendarItem__inline: {
        borderRight: "1px solid #e9e9e9!important"
    },

    calendarItem__last: {

        "& .calendarItemBookingLayerLast": {
            position: "absolute",
            borderRadius: "50%",
            top: "8%",
            left: "-78%",
            right: "85%",
            bottom: "8%",
            zIndex: 2,
        },

    },

    calendarDaysWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    calendarItem: {
        width: "14.285%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        fontSize: "1rem",
        boxSizing: "border-box",
        borderBottom: "1px solid #e9e9e9",
        borderRight: "1px solid #e9e9e9",
        overflow: "hidden",
        "&:nth-child(7)": {
            borderRight: "none",
        },

        "& .calendarItemTextPrice":{
            position: "absolute",
            left: 0,
            bottom: 1,
            width: "100%",
            fontSize: 10*onePx+"rem",
            textAlign: "center",
            zIndex: 7,
            color: "#cdcbcb",
        },

        "& i": {
            fontWeight: 900,
            marginRight: 2,
        },

        "& .calendarItemTextDay":{
            position: "relative",
            zIndex: 7,
        },

        "& .calendarItemBookingLayer": {
            position: "absolute",
            top: "8%",
            left: "-1px",
            right: "-1px",
            bottom: "8%",
            zIndex: 2,
        },

        "& .calendarItemLayerActiveDay": {
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "1px solid #484848",
            boxSizing: "border-box",
            zIndex: 8,
        },
    },

    calendarItem__not_border: {
        border: "none",
    },

    calendarItem__disabled: {
        color: "#919191",
    },

    calendarItem__booked: {
        color: "#fff",
    },
    calendarItem__bookedOld: {
        color: "#a7a7a7",
    },


    calendarItem__freeDay: {
        color: "#484848!important",
    },

    calendarBufferWrapper: {
        width: "14.285%",
        position: "relative",
        borderBottom: "1px solid #e9e9e9",
        zIndex: 5,
        background: "#fff",
    },

    calendarBufferWrapperNotBorder: {
        width: "14.285%",
        position: "relative",
        zIndex: 5,
        background: "#fff",
    },

    calendarBufferWrapper__last: {
        borderRight: "1px solid #e9e9e9",
    },
    calendarMonth: {
        color: "#565656",
        fontSize: "1rem",
        paddingLeft: "7.14%",
        fontWeight: 500,
        marginTop: "1.5rem",
    },
    calendarHeader: {
        width: "100%",
        height: "3rem",
        display: "flex",
        boxSizing: "border-box",
        borderBottom: "3px solid #dcdcdc",
        "& div": {
            width: "14.285%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#a1a1a1",
            fontSize: "1rem",
        }
    },
    calendarLiteHeader: {
        borderBottom: "1px solid #dcdcdc",
    },
    calendarRowBufferWrapper__1:{
        width: "14.285%",
    },
    calendarRowBufferWrapper__2:{
        width: "28.571%",
    },
    calendarRowBufferWrapper__3:{
        width: "42.857%",
    },
    calendarRowBufferWrapper__4:{
        width: "57.142%",
    },
    calendarRowBufferWrapper__5:{
        width: "71.428%",
    },
    calendarRowBufferWrapper__6:{
        width: "85.714%",
    },
    calendarActiveMonth: {
        width: "100%",
        height: "1px",
    },

    calendar: {
        position: "relative"
    },
    calendarMonthWrapper: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
    },

}));
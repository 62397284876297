import React, { createContext, useState } from "react";

export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loggingIn, setLoggingIn] = useState(true);

  return (
    <UserContext.Provider
        value={{ user, setUser, loggingIn, setLoggingIn }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

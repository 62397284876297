import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

const Support = () => {

    const gClasses = useGStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (
        <TextPageTpl>

            <BreadCrumbs stack={[{name: "Поддержка сервиса"}]} style={{padding: 0, marginBottom: 20}}/>

            <h1>Поддержка сервиса</h1>

            <p>Если у вас возникли вопросы по работе сервиса, вы можете связаться со службой поддержки через Telegram <b onClick={() => window.open("https://t.me/"+pubConfig.supportTg, "_blank")}>@{pubConfig.supportTg}</b></p>

        </TextPageTpl>
    );
};

export default Support;

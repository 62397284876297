import React, {Component, Fragment, useState, useEffect, useRef, useCallback} from 'react';
import clsx from "clsx";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {getTextDays} from "../../utils/functions";
import moment from "moment";
import RangeCalendar from "../../components/RangeCalendar/RangeCalendar.jsx";

const RangeCalendarWithMemo = React.memo((props) => {
    return (
        <RangeCalendar {...props} />
    );
},(b, a) => {
    if(b.selectedData != a.selectedData) return false;
    return true;
});

export default (props) => {

    const {
        onSaveDatesFilter,
        onResetDateFilter,
        drawer,
        setDrawer,
        filter,
    } = props;

    //фильтр по датам для аренды
    const [datesFilterData, setDatesFilterData] = React.useState(filter.dates);

    const classes = useStyles();
    const gClasses = useGStyles();

    const notCheckedDates = !(datesFilterData && datesFilterData.startDate && datesFilterData.endDate);
    const rangeCount = moment(datesFilterData.endDate).diff(datesFilterData.startDate, "days");

    return (
        <>

            <div className={clsx(gClasses.bottomDrawerHeader)}>

                <Button variant="text" className={gClasses.bottomDrawerRemoveBtn} onClick={onResetDateFilter}>Сброс</Button>

                <p>Период проживания</p>

                <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={gClasses.bottomDrawerCloseBtn}>
                    <CloseIcon fontSize="inherit"/>
                </IconButton>

            </div>

            <RangeCalendarWithMemo
                selectedData={datesFilterData}
                afterMonths={12}
                afterChange={(dates) => setDatesFilterData({...datesFilterData, ...dates})}
                noPastSates={true}
                calendarClassName={gClasses.rangeCalendarDatesFilterWrapper}
            />

            <div className={gClasses.rangeCalendarDatesFilterBottomPanel}>
                <div>
                    <Button
                        className={gClasses.largeBtn}
                        fullWidth
                        onClick={() => onSaveDatesFilter(datesFilterData)}
                        variant="contained"
                        disabled={notCheckedDates}
                        color="red"
                    >
                        {notCheckedDates ? "Выбрать даты" : "Выбрать " + getTextDays(rangeCount, "дни")}
                    </Button>
                </div>
            </div>

        </>
    )

}
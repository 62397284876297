import React, {useContext} from "react";
import useStyles from "./styles";
import {PageErrorIcon} from "../../assets/svg-icons.js";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";


export default (props) => {

    const {error, description} = props;

    const classes = useStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (

        <div className={classes.pageErrorWrapper}>
            <div className={classes.pageErrorContent}>

                <PageErrorIcon/>

                <p>{error || "Неизвестная ошибка"}</p>

                {!!description && <p className={classes.pageErrorTitle}>{description}</p>}

                <p>Если вы считаете, что это техническая ошибка CRM или сбой в работе сервиса, напишите в tg поддержки: <span className={classes.fw500} onClick={() => window.open("https://t.me/"+pubConfig.support_tg, "_blank")}>@{pubConfig.support_tg}</span></p>

            </div>

        </div>
    )

}
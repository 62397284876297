import React, {Component, useState, useEffect} from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    comments: {
        padding: "20px 15px",
        background: "#F4F6F9",
        "& .MuiInputBase-root": {
            padding: "12px 14px",
            background: "#fff",
        },
    },
    reCommentsLoading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    commentsHeader: {
        "fontWeight": "700",
        "fontSize": 17*onePx+"rem",
        "color": "#2F2E36",
        marginBottom: 20,
    },
    commentsCount: {
        color: "rgba(74, 81, 87, 0.5)",
        fontWeight: 700,
    },
    commentText: {
        //flex: 1,
        position: "relative",
        //padding: "6px 8px 20px 8px",
        minWidth: "30%",
        border: "1px solid #dad9d9",
        //border: "1px solid #D4D4D4",
        borderRadius: "8px 8px 8px 0px",
        background: "#fff",
        wordBreak: "break-word",
        overflow: "hidden",
        /*
        "& p": {
            margin: 0,
            wordBreak: "break-word",
        }
         */
    },
    quoteHeader: {

    },
    quoteText: {

    },
    commentFooterText: {
        height: 24,
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #dad9d9",
        padding: "0 8px",
        fontSize: 14*onePx+"rem",
        textWrap: "nowrap",
        "& .MuiSvgIcon-root": {
            fontSize: 18*onePx+"rem",
            marginRight: 4,
        }
    },
    myCommentText: {
        borderRadius: "8px 8px 0px 8px",
    },
    commentAudio: {
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
        color: "#4CAF50",
        "& .MuiSvgIcon-root": {
            color: "#4CAF50",
            marginRight: 5,
        }
    },
    comment: {
        textAlign: "left",
        display: "flex",
        marginBottom: 20,
        position: "relative",
        padding: "0 20px 0 0",
        "&:last-child": {
            marginBottom: 22,
        },
    },
    myComment: {
        justifyContent: "flex-end",
        padding: "0 0 0 20px",
    },
    commentAuthor: {
        "fontWeight": "600",
        "fontSize": 13*onePx+"rem",
        "color": "#4CAF50"
    },
    commentCompany: {
        "fontWeight": "400",
        "fontSize": 13*onePx+"rem",
        "color": "#657780",
    },
    commentAvatar: {
        display: "flex",
        alignItems: "flex-end",
        marginRight: 4,
        position: "relative",
        "& .MuiAvatar-root": {
            border: "1px solid #DEE4E8",
        },
    },
    iconAvatar: {
        padding: "10px 0",
        "& .MuiSvgIcon-root": {
            //color: "#4CAF50"
        }
    },
    myCommentAvatar: {
        marginRight: "0!important",
        marginLeft: 4,
    },
    commentDate: {
        left: 48,
        top: -15,
        position: "absolute",
        margin: 0,
        color: "#9E9E9E",
        fontWeight: 400,
        fontSize: 11*onePx+"rem",
    },
    myCommentDate: {
        right: 48,
        left: "auto",
    },
    commentStatusSent: {
        fontSize: 15*onePx+"rem",
        margin: "0 0px -3px 4px",
        color: "#dad9d9",
    },
    commentDateTest: {
        left: 4,
        bottom: 23,
        position: "absolute",
        margin: 0,
        color: "#9E9E9E",
        fontWeight: 400,
        fontSize: 11*onePx+"rem",
    },
    myCommentDateTest: {
        right: 4,
        left: "auto",
    },

    commentContent: {
        color: "#2F2E36",
        fontWeight: 400,
        fontSize: 16*onePx+"rem",
        hyphens: "auto",
        //padding: "8px 10px",
        padding: "7px 10px 14px 10px",
        "& i": {
            textDecoration: "line-through",
            fontStyle: "normal",
            fontSize: 13*onePx+"rem",
        },
        "& span": {
            fontWeight: 600,
            fontSize: 13*onePx+"rem",
        },
    },
    commentContentWithQuote: {
        padding: "4px 10px",
        borderLeft: "1px solid #dad9d9",
        marginLeft: 10,
        marginBottom: 15,
        fontStyle: "italic",
        fontSize: 14*onePx+"rem",
    },
    commentContentHeader: {
        fontWeight: 600,
        padding: "7px 10px 5px 10px",
        color: "rgb(113, 196, 126)",
        "& .MuiSvgIcon-root": {
            marginBottom: -6,
            marginRight: 4,
        }
    },

}));